import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./dependents.module.scss";

import moment from "moment";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { ReactComponent as SearchIcon } from "../../../assest/images/icons/search.svg";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, {
  CopyBtn,
  EachCustomPolicyNumberDropdown,
  EachCustomProfileDropdown,
} from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect, useLocation } from "react-router-dom";
import ToggleButton from "../../../components/Custom/Toggle";
import { predefinedToast } from "../../../utils/toast";
import flatpickr from "flatpickr";
import NavbarV2 from "../../../components/NavbarV2";
import NavbarV3 from "../../../components/NavbarV3";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutToSameLogin } from "../../../assest/functions";

const fetchLeadSourcesDropdown = (setLeadSourceDropdown) => {
  Axios.get(
    "https://insurancecoverfi.apimachine.com/insurance/lead/getleadsources"
  )
    .then((response) => {
      console.log(response, "lead sources dropdown response");
      if (response?.data?.status) {
        setLeadSourceDropdown(response?.data?.data);
      }
    })
    .catch((error) => {
      console.log(error?.message, "lead sources dropdown error");
    });
};

const Dependents = () => {
  const history = useHistory();
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    allLeadsAPI,
    setAllLeadsAPI,
    updateLoading,
    setUpdateLoading,
    addDependentSidebar,
    setAddDependentSidebar,
  } = useContext(BankContext);

  const location = useLocation();

  const [selectedProfileNav, setSelectedProfileNav] = useState("Dependents");
  const [searchQuery, setSearchQuery] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");
  const [statusDropdown, setStatusDropdown] = useState("");
  const [statesDropdown, setStatesDropdown] = useState("");
  const [appDropdown, setAppDropdown] = useState("");
  const [allStatusDropdown, setAllStatusDropdown] = useState([
    { name: "New Lead", searchValue: "leadInquired" },
    { name: "Contacted", searchValue: "inConversation" },
    { name: "HR Logged In", searchValue: "HRAccountLoggedIn" },
    { name: "Policy Requirements Defined", searchValue: "HRConfiguredForm" },
    {
      name: "Employee Forms Partially Filled",
      searchValue: "formsPartiallyFilled",
    },
    {
      name: "Employee Forms Completely Filled",
      searchValue: "formsCompletelyFilled",
    },
  ]);
  const [allStatusSelected, setAllStatusSelected] = useState("");
  const [leadSourceDropdown, setLeadSourceDropdown] = useState("");
  const [leadSourceSelected, setLeadSourceSelected] = useState("");

  const [leadsTypeSelected, setLeadsTypeSelected] = useState("");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");

  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  useEffect(() => {
    // console.log("API is being called");
    setAllLeads("");
    setAllLeadsAPI("");
    setAllBrokeragesTotalProfile("");
    setAllLeadsLoading(true);

    const fetchAllBrokerages = () => {
      let URL;
      if (selectedProfileNav === "Dependents") {
        URL = `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${email}`;
      }

      if (selectedProfileNav === "Dependents") {
        Axios.get(URL)
          .then((response) => {
            console.log(response, "Dependents leads response");
            if (response?.data?.status) {
              setAllLeads(response?.data?.data);
              setAllLeadsAPI(response?.data?.data);
              setAllBrokeragesTotalProfile(response?.data?.total_profiles);
            }
            setAllLeadsLoading(false);
          })
          .catch((error) => {
            console.log(error?.message, "Dependents leads error");
            setAllLeadsLoading(false);
          });
      }
    };

    fetchAllBrokerages();
    fetchLeadSourcesDropdown(setLeadSourceDropdown);
  }, [selectedProfileNav, email, toggleSelectedTable, updateLoading]);

  useEffect(() => {
    if (allLeadsAPI?.length > 0 && Array.isArray(allLeadsAPI)) {
      setAllBrokeragesTotalProfile("");
      let res = allLeadsAPI?.filter((row) => {
        const { lead_type, lead_source, ...newObject } = row;

        // Creating a new object with the extracted properties
        const separateObject = { lead_type, lead_source };
        // Convert the row object values to an array and check if any value matches the search query
        const values = Object?.values(separateObject);
        const searchQuery = leadsTypeSelected?.toLowerCase();
        const searchQueryOwner = leadSourceSelected?.toLowerCase();
        const searchQueryStatus = allStatusSelected?.toLowerCase();

        // console.log(separateObject, "values sort", searchQueryOwner);

        // Check if the selected country matches the object's country (if selected)
        const countryMatches =
          !searchQuery ||
          values.some(
            (value) =>
              typeof value === "string" &&
              searchQuery.toLowerCase().includes(value?.toLowerCase())
          );

        const brokerageMatches =
          !searchQueryOwner ||
          row?.lead_source?.toLowerCase().includes(searchQueryOwner);

        const statusMatches =
          !searchQueryStatus ||
          row?.follow_up_status?.toLowerCase().includes(searchQueryStatus);

        return countryMatches && brokerageMatches && statusMatches;
      });
      setAllBrokeragesTotalProfile(res.length);
      setAllLeads(res);
    }
  }, [leadsTypeSelected, leadSourceSelected, allStatusSelected]);

  async function downloadSpreadsheetSelectedUser() {
    setUpdateLoading(true);
    const hrId = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?lead_id=${actionBtns}`
    );

    let hrid = hrId?.data?.data?.hrDetails?.hr_id;
    if (hrid) {
      window?.open(
        `https://insurancecoverfi.apimachine.com/insurance/hr/share?hr_id=${hrid}`
      );
    } else {
      predefinedToast("HR ID for selected user not found");
    }
    setUpdateLoading(false);
  }

  async function deleteDependent() {
    setUpdateLoading(true);
    const deleteDependentResponse = await Axios.delete(
      `https://insurancecoverfi.apimachine.com/insurance/dependent/delete?dependent_id=${actionBtns}`
    );

    // console.log(deleteDependentResponse, "deleteDependentResponse");

    // let hrid = hrId?.data?.data?.hrDetails?.hr_id;
    if (deleteDependentResponse?.data?.status) {
      predefinedToast("Dependent deleted successfully");
    } else {
      predefinedToast("Error while deleting dependent");
    }
    setUpdateLoading(false);
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.profile}
      onClick={(event) => {
        if (
          addDependentSidebar &&
          !event.target.closest("#addDependentSidebar")
        ) {
          console.log(event.target, ": clicked id");
          setAddDependentSidebar(false);
        }
      }}
    >
      <NavbarV3
        dropdownItems={["Dependents"]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setSearchQuery}
      />
      <div className={classNames.brokerageDetails}>
        <div className={classNames.title}>{`You Have ${
          allBrokeragesTotalProfile ? allBrokeragesTotalProfile : "0"
        } Dependents`}</div>
        <div className={classNames.dropdownItems}>
          <div
            className={classNames.downloadListBtn}
            onClick={() => setAddDependentSidebar(true)}
          >
            Add Dependent
          </div>
        </div>
        <div className={classNames.allLeads}>
          <div className={classNames.nav}>
            <div className={classNames.searchDiv}>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search.."
                onChange={(event) =>
                  setAllBrokeragesSearch(event?.target?.value)
                }
              />
            </div>
            {/* <ToggleButton
              stateValue={toggleSelectedTable}
              stateFunc={setToggleSelectedTable}
              loading={allLeadsLoading}
            /> */}
            <div
              className={classNames.actionBtn}
              style={{
                display: popularAction
                  ? "none"
                  : actionBtns?.length > 0
                  ? ""
                  : "none",
              }}
            >
              <div
                onClick={downloadSpreadsheetSelectedUser}
                style={{ visibility: "hidden", pointerEvents: "none" }}
              >
                Download
              </div>
              {/* <div
                onClick={() => {
                  setPopularAction("editlead");
                }}
              >
                Edit
              </div> */}
              <div
                onClick={() => {
                  deleteDependent();
                }}
              >
                Delete
              </div>
            </div>
          </div>
          <TableDependentsSimplified
            allLeads={allLeads}
            allBrokeragesSearch={allBrokeragesSearch}
            actionBtns={actionBtns}
            setActionBtns={setActionBtns}
            allLeadsLoading={allLeadsLoading}
          />
        </div>
        <AddDependentsSidebar from="dependent_user_company_email" />
      </div>
    </div>
  );
};

export default Dependents;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div className={classNames.eachCustomDropdown}>
      <div className={classNames.title}>{title}</div>
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "2.4rem"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "Lead Source"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Insurance company"
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.displayName);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.insuranceCompany_id,
                          });
                        }}
                        key={eachitem?.displayName + index}
                      >
                        {eachitem?.displayName}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        {eachitem}
                      </li>
                    );
                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birthday") ||
        placeholder?.toLowerCase()?.includes("birthday")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();

      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      // Calculate minDate for a 80-year-old person
      minDate.setFullYear(currentDate.getFullYear() - 80);

      // Calculate maxDate for a 25-year-old person
      maxDate.setFullYear(currentDate.getFullYear() - 25);

      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(dateStr);
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          setState(dateStr);
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      <div className={classNames.title}>{title}</div>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={stateValue}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
    </div>
  );
};

const EachInputSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <div className={classNames.eachInputPickOne}>
      {title && <div className={classNames.title}>{title}</div>}
      <div
        classNames={classNames.selectOneAllOptions}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          fontSize: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {options?.length > 0
          ? options?.map((eachOption, index) => {
              return (
                <div
                  key={eachOption + index}
                  onClick={() => {
                    setSelectedValue(eachOption);
                    setState({ ...stateValue, [name]: eachOption });
                  }}
                  style={{
                    textAlign: "center",
                    width: "max-content",
                    padding: "1rem 2rem",
                    cursor: "pointer",
                    fontSize: "0.9rem",
                    border:
                      selectedValue == eachOption
                        ? "1px solid var(--main-color)"
                        : "1px solid #e5e5e5",
                    borderRadius: "35px",
                    transition: "all linear 0.3s",
                    marginTop: "1rem",
                  }}
                >
                  {eachOption}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const TableDependentsSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Contact</th>
            <th>Relation</th>
            <th>Birthdate</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.dependent_id}
                      name={row?.dependent_id}
                      type="single"
                    />
                  </td>
                  <td>
                    {row?.dependent_name ? row?.dependent_name : ""}
                    <CopyBtn
                      copyText={row?.dependent_name ? row?.dependent_name : ""}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.primary_phone_number
                            ? row?.primary_phone_number
                            : ""}
                          <CopyBtn
                            copyText={
                              row?.primary_phone_number
                                ? row?.primary_phone_number
                                : ""
                            }
                          />
                        </div>
                        <div>
                          {row?.email ? row?.email : ""}
                          {row?.email && (
                            <CopyBtn copyText={row?.email ? row?.email : ""} />
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.relationship ? row?.relationship : ""}
                    <CopyBtn
                      copyText={row?.relationship ? row?.relationship : ""}
                    />
                  </td>
                  <td>
                    {row?.date_of_birth ? row?.date_of_birth : ""}
                    <CopyBtn
                      copyText={row?.date_of_birth ? row?.date_of_birth : ""}
                    />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

const TableEmployeesSimplified = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Contact</th>
            <th>Company</th>
            <th>Form Status</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
            >
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                >
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.phone_number ? row?.phone_number : ""}
                          <CopyBtn
                            copyText={
                              row?.phone_number ? row?.phone_number : ""
                            }
                          />
                        </div>
                        <div>
                          {row?.lead_email ? row?.lead_email : ""}
                          <CopyBtn
                            copyText={row?.lead_email ? row?.lead_email : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.employee_company_name
                      ? row?.employee_company_name
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.employee_company_name
                          ? row?.employee_company_name
                          : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.follow_up_status == "leadInquired"
                      ? "New Lead"
                      : row?.follow_up_status == "inConversation"
                      ? "Contacted"
                      : row?.follow_up_status == "HRAccountLoggedIn"
                      ? "HR Logged In"
                      : row?.follow_up_status == "HRConfiguredForm"
                      ? "Policy Requirements Defined"
                      : row?.follow_up_status == "formsPartiallyFilled"
                      ? "Employee Forms Partially Filled"
                      : row?.follow_up_status == "formsCompletelyFilled"
                      ? "Employee Forms Completely Filled"
                      : row?.follow_up_status == "quotesUploaded"
                      ? "Quote Uploaded"
                      : row?.follow_up_status == "proposalFrozen"
                      ? "Proposal Frozen"
                      : row?.follow_up_status == "activeClient_T"
                      ? "Policy Activated With FMA Reference Number"
                      : row?.follow_up_status == "activeClient_P"
                      ? "Policy Updated With Permanent Number"
                      : row?.follow_up_status == "activeClient_H"
                      ? "Policy Updated With Employee Health Cards"
                      : row?.follow_up_status == "clientLost"
                      ? "Lead Lost"
                      : ""}
                    <CopyBtn
                      copyText={
                        row?.follow_up_status ? row?.follow_up_status : ""
                      }
                    />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};

export const AddDependentsSidebar = ({ from }) => {
  const {
    email,
    addDependentSidebar: isOpen,
    setAddDependentSidebar: setIsOpen,
    setUpdateLoading,
    lastSelectedEmployee,
  } = useContext(BankContext);
  const [dobDate, setDobDate] = useState("");
  const [dependentsDetails, setDependentsDetails] = useState({
    dependent_user_company_email: email,
    dependent_name: "",
    dependent_email: "",
    relationship: "",
    gender: "",
    date_of_birth: dobDate,
    primary_phone_number: "",
    secondary_phone_numbers: [],
  });

  function addDependent() {
    setUpdateLoading(true);

    dependentsDetails.date_of_birth = dobDate;

    if (from == "dependent_user_company_email") {
      dependentsDetails.dependent_user_company_email = email;
    }

    // console.log(dependentsDetails, "add dependent dependentsDetails");

    Axios.post(
      "https://insurancecoverfi.apimachine.com/insurance/dependent/create",
      dependentsDetails
    )
      .then((response) => {
        if (response?.data?.data?.status) {
          setIsOpen(false);
          predefinedToast("Added new dependent");
        }
        // console.log(response?.data?.data?.status, "add dependent response");
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Add dependent error");
        setUpdateLoading(false);
        predefinedToast("Error while adding new dependent");
      });
  }

  return (
    <div
      className={classNames.addDependents}
      style={{ right: isOpen ? "" : "-300%" }}
      id="addDependentSidebar"
    >
      <div className={classNames.title}>Add New Dependent</div>
      <div className={classNames.allInputsParent}>
        <EachInput
          title="What is the name of your dependent?"
          placeholder="Enter name.."
          name="dependent_name"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <EachCustomDropdown
          dropdown={[
            "Spouse",
            "Children",
            "Father",
            "Mother",
            "Father-in-Law",
            "Mother-in-Law",
          ]}
          title="What is your relation to the dependent?"
          name="relationship"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <EachInputDatePicker
          title="What is the dependents birthday?"
          placeholder="Click To Select"
          stateValue={dobDate}
          setState={setDobDate}
        />
        <EachInput
          title="What is the dependents phone number?"
          placeholder="Enter number..."
          name="primary_phone_number"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <EachInput
          title="What is the dependents email?"
          placeholder="Enter email..."
          name="dependent_email"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
        />
        <EachInputSelectOne
          title="What is the dependents gender?"
          name="gender"
          stateValue={dependentsDetails}
          setState={setDependentsDetails}
          options={["Male", "Female"]}
        />
      </div>
      <div className={classNames.submitButton} onClick={addDependent}>
        Add Dependent
      </div>
    </div>
  );
};

const Table = ({
  allLeads,
  allBrokeragesSearch,
  actionBtns,
  setActionBtns,
  allLeadsLoading,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table>
        <thead>
          <tr>
            <th>
              {/* <CustomCheckbox
                actionBtns={actionBtns}
                setActionBtns={setActionBtns}
              /> */}
            </th>
            <th>Name</th>
            <th>Email</th>
            <th>Sales Status</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Lead Source</th>
            <th>Lead Type</th>
            <th>Company Name</th>
            <th>Company Size</th>
            <th>Lead Created</th>
            <th>Status</th>
            <th>Lead ID</th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr key={"loading"}>
              <td>
                <CustomCheckbox />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr key={row?.brokerage_name + index}>
                  <td>
                    <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.lead_id}
                      name={row?.first_name + " " + row?.last_name}
                    />
                  </td>
                  <td>
                    <div className={classNames.detailedItem}>
                      <div style={{ display: "none" }}>
                        <img src={row?.agent_image} alt="agent_image" />
                      </div>
                      <div>
                        <div>
                          {row?.first_name ? row?.first_name : ""}
                          <CopyBtn
                            copyText={row?.first_name ? row?.first_name : ""}
                          />
                        </div>
                        <div>
                          {row?.last_name ? row?.last_name : ""}
                          <CopyBtn
                            copyText={row?.last_name ? row?.last_name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row?.lead_email ? row?.lead_email : ""}
                    <CopyBtn
                      copyText={row?.lead_email ? row?.lead_email : ""}
                    />
                  </td>
                  <td>
                    {row?.follow_up_status == "leadInquired"
                      ? "New Lead"
                      : row?.follow_up_status == "inConversation"
                      ? "Contacted"
                      : row?.follow_up_status == "HRAccountLoggedIn"
                      ? "HR Logged In"
                      : row?.follow_up_status == "HRConfiguredForm"
                      ? "Policy Requirements Defined"
                      : row?.follow_up_status == "formsPartiallyFilled"
                      ? "Employee Forms Partially Filled"
                      : row?.follow_up_status == "formsCompletelyFilled"
                      ? "Employee Forms Completely Filled"
                      : row?.follow_up_status == "quotesUploaded"
                      ? "Quote Uploaded"
                      : row?.follow_up_status == "proposalFrozen"
                      ? "Proposal Frozen"
                      : row?.follow_up_status == "activeClient_T"
                      ? "Policy Activated With FMA Reference Number"
                      : row?.follow_up_status == "activeClient_P"
                      ? "Policy Updated With Permanent Number"
                      : row?.follow_up_status == "activeClient_H"
                      ? "Policy Updated With Employee Health Cards"
                      : row?.follow_up_status == "clientLost"
                      ? "Lead Lost"
                      : ""}
                    {/* "leadInquired", //Default "inConversation", //Default
                    "HRAccountLoggedIn", //API "HRConfiguredForm", //automatic
                    "formsPartiallyFilled", //automatic "formsCompletelyFilled",
                    //automatic "quotesUploaded", //automatic "proposalFrozen",
                    //API "activeClient_T", //API "activeClient_P", //API
                    "activeClient_H", "clientLost" */}
                    <CopyBtn
                      copyText={
                        row?.follow_up_status ? row?.follow_up_status : ""
                      }
                    />
                  </td>
                  <td>
                    {row?.phone_number ? row?.phone_number : ""}
                    <CopyBtn
                      copyText={row?.phone_number ? row?.phone_number : ""}
                    />
                  </td>
                  <td>
                    {row?.address ? row?.address : ""}
                    <CopyBtn copyText={row?.address ? row?.address : ""} />
                  </td>
                  <td>
                    {row?.lead_source ? row?.lead_source : ""}
                    <CopyBtn
                      copyText={row?.lead_source ? row?.lead_source : ""}
                    />
                  </td>
                  <td>{row?.lead_type ? row?.lead_type : ""}</td>
                  <td>
                    {row?.company_name ? row?.company_name : ""}
                    <CopyBtn
                      copyText={row?.company_name ? row?.company_name : ""}
                    />
                  </td>
                  <td>
                    {row?.company_size ? row?.company_size : ""}
                    <CopyBtn
                      copyText={row?.company_size ? row?.company_size : ""}
                    />
                  </td>
                  <td>
                    {row?.date ? row?.date : ""}
                    <CopyBtn copyText={row?.date ? row?.date : ""} />
                  </td>
                  <td>
                    {row?.status ? row?.status : ""}
                    <CopyBtn copyText={row?.status ? row?.status : ""} />
                  </td>
                  <td>
                    {row?.lead_id ? row?.lead_id : ""}
                    <CopyBtn copyText={row?.lead_id ? row?.lead_id : ""} />
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
        </tbody>
      </table>
    </div>
  );
};
