import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/navbar";
import "./terms.css";
import Footer from "../../components/landing/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MobileNavbar from "../../components/navbar/mobile";
import { BankContext } from "../../context/Context";

const Terms = () => {
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  useEffect(() => {
    const homeElement = document.querySelector(".terms");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="terms">
      {window.innerWidth > 768 ? (
        <Navbar isScrolled={isScrolled} />
      ) : (
        <MobileNavbar />
      )}
      <div className="termsContainer">
        <h1>Terms Of Use</h1>
        <p>
          TERMS AND CONDITIONS OF USE OF FMA WEBSITE, FMA ANDROID APP, FMA iOS
          APP AND ALL FMA SERVICES (COLLECTIVELY REFERRED TO AS THE INTERNET
          BASED PLATFORM OR SERVICES) PLEASE READ THESE TERMS OF USE CAREFULLY
          BY ACCESSING OR USING THIS INTERNET BASED PLATFORM AND THE SERVICES
          PROVIDED THROUGH THIS INTERNET BASED PLATFORM, YOU AGREE TO BE BOUND
          BY THE TERMS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE.
          IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS INTERNET
          BASED PLATFORM OR AVAIL ANY SERVICES PROVIDED THROUGH THIS INTERNET
          BASED PLATFORM.
        </p>
        <ol className="numberList">
          <li>GENERAL TERMS OF USE</li>
          <ol className="letterList">
            <li>
              The domain name www.Rahainsure.com, an internet-based portal, the
              FMA android app and iOS app, are owned and operated by Tall Tree
              Fintech Private Limited., a company duly incorporated under the
              provisions of the Companies Act, 1956 (hereinafter referred to as
              “FMA” or “We” or “Our” or “Us” or “Company”). The domain name and
              the mobile application are collectively referred to as the
              “Website”.
            </li>
            <li>
              Your access or use of the Website, transacting on the Website and
              use of Services (as defined herein below) hosted or managed
              remotely through the Website, are governed by the following terms
              and conditions (hereinafter referred to as the Terms of Use”),
              including the applicable policies which are incorporated herein by
              way of reference. These Terms of Use constitute a legal and
              binding contract between you (hereinafter referred to as “You” or
              “Your” or “Customer” or the “User”) on one part and FMA on the
              other Part.
            </li>
            <li>
              By accessing, browsing or in any way transacting on the Website,
              or availing any Services, you signify Your agreement to be bound
              by these Terms of Use. Further, by impliedly or expressly
              accepting these Terms of Use, you also accept and agree to be
              bound by Our policies, including the Privacy Policy (as set out in
              Part B herein below), and such other rules, guidelines, policies,
              terms and conditions as are relevant under the applicable law(s)
              in India and other jurisdictions for the purposes of accessing,
              browsing or transacting on the Website, or availing any of the
              Services, and such rules, guidelines, policies, terms and
              conditions shall be deemed to be incorporated into, and considered
              as part and parcel of these Terms of Use. However, if You navigate
              away from the Website to a third-party website, you may be subject
              to alternative terms and conditions of use and privacy policy, as
              may be specified on such website. In such event, the terms and
              conditions of use and privacy policy applicable to that website
              will govern Your use of that website.
            </li>
            <li>
              The Website is a platform that facilitates (i) online purchase of
              pharmaceutical products sold by various Third-Party Medicine
              Vendor and manufactures (“Third-Party Medicine Vendor”); (ii)
              diagnostic services being offered by various Third-Party
              diagnostic centres (“Third-Party Labs”); (iii) online medical
              consultancy services/ second opinion / family doctor being offered
              by Third-Party independent doctors (“Medical Experts”); (iv)
              Nursing services provided by Third-Party independent nursing
              professionals at your home (“Nursing Professionals”); (v) Medical
              facilitation services being offered by various Third-Party vendors
              (“Medical Facilitation”); (vi) Wellness Services such as yoga,
              physiotherapy, diet consultation, Zumba etc. provided by
              Third-Party independent professionals and Partners (“Wellness
              Professionals”); (vii) Electronic health record services provided
              by CallHealth Services Private Limited (“EHR Services”); (viii)
              Health insurance buying advise through IRDA registered
              intermediaries (Third-Party Broking Services) and(ix) Wallet
              Service and Payment Gateway Vendors (x) online advertisements,
              including for insurance services, of various sponsors advertising
              and marketing their own good and services (“Third-Party
              Advertising Entities”). The Third-Party Medicine Vendor,
              Third-Party Labs, Medical Experts, Nursing Professionals, Wellness
              Vendors and Professionals, Third-Party Broking Services and the
              Third-Party Advertising Entities are collectively referred to as
              the “Third-Party Service Providers”. Further the Website also
              serves as an information platform providing health and wellness
              related information to the Users accessing the Website (The
              services of Third-Party Services Provider, Medical Facilitation,
              EHR Services and the information services are collectively
              referred to as the “Services”).
            </li>
            <li>
              The arrangement between the Third-Party Service Providers, You and
              FMA shall be governed in accordance with these Terms of Use. The
              Services would be made available to such natural persons who have
              agreed to use the Website after obtaining due registration, in
              accordance with the procedure as determined by Us, from time to
              time. The Services are offered to You through various modes which
              shall include issue of Wallets, discount coupons and vouchers that
              can be redeemed for the Services offered for sale on the Website.
              To facilitate the relation between You and the Third-Party Service
              Providers through the Website, FMA shall send to You promotional
              content including but not limited to emailers, notifications and
              messages and may share your contact data with the Third-Party
              Service Providers.
            </li>
            <li>
              You agree and acknowledge that the Website is a platform that You
              and Third-Party Service Providers utilize to meet and interact
              with another for their transactions. FMA is not and cannot be a
              party to or save as except as may be provided in these Terms of
              Use, control in any manner, any transaction between You and the
              Third-Party Service Providers.
            </li>
            <li>
              FMA reserves the right to change or modify these Terms of Use or
              any policy or guideline of the Website including the Privacy
              Policy, at any time and in its sole discretion. Any changes or
              modifications will be effective immediately upon posting the
              revisions on the Website and You waive any right You may have to
              receive specific notice of such changes or modifications. Your
              continued use of the Website will confirm Your acceptance of such
              changes or modifications; therefore, you should frequently review
              these Terms of Use and applicable policies to understand the terms
              and conditions that apply to Your use of the Website.
            </li>
            <li>
              As a condition to Your use of the Website, you must be 18
              (eighteen) years of age or older to use or visit the Website in
              any manner. By visiting the Website or accepting these Terms of
              Use, You represent and warrant to FMA that You are 18 (eighteen)
              years of age or older, and that You have the right, authority and
              capacity to use the Website and agree to and abide by these Terms
              of Use.
            </li>
            <li>
              These Terms of Use is published in compliance of, and is governed
              by the provisions of Indian laws, including but limited to:
            </li>
            <ol className="romanList">
              <li>the Indian Contract Act, 1872 (“Contract Act”);</li>
              <li>
                the (Indian) Information Technology Act, 2000 (“IT Act”) and the
                rules, regulations, guidelines and clarifications framed
                thereunder, including the (Indian) Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Information) Rules, 2011, and the (Indian) Information
                Technology (Intermediaries Guidelines) Rules, 2011 (“IG
                Guidelines”);
              </li>
              <li>
                the Drugs and Cosmetic Act, 1940 (“Drugs Act”), read with the
                Drugs and Cosmetics Rules, 1945 (“Drugs Rules”);
              </li>
              <li>
                the Drugs and Magic Remedies (Objectionable Advertisements) Act,
                1954 (“Drugs and Magic Act”);
              </li>
              <li>
                The Indian Medical Council Act, 1956 read with the Indian
                Medical Council Rules, 1957;
              </li>
              <li>Pharmacy Act, 1948 (“Pharmacy Act”) and</li>
              <li>the Consumer Protection Act, 1986.</li>
            </ol>
            <li>
              FMA authorizes You to view and access the content available on the
              Website solely for the purposes of availing the Services, such as
              visiting, using, ordering, receiving, delivering and communicating
              only as per these Terms of Use. The contents on the Website
              including information, text, graphics, images, logos, button
              icons, software code, design, and the collection, arrangement and
              assembly of content, contains Third-Party Service Providers’
              content (“Third-Party Content”) as well as in-house content
              provided by FMA including without limitation, text, copy, audio,
              video, photographs, illustrations, graphics and other visuals
              (“FMA Content”) (collectively, “Content”). The Content is the
              property of FMA, or Third-Parties as applicable and is protected
              under copyright, trademark and other applicable law(s). You shall
              not modify the Content or reproduce, display, publicly perform,
              distribute, or otherwise use the Content in any way for any public
              or commercial purpose or for personal gain.
            </li>
            <li>
              Compliance with these Terms of Use would entitle You to a
              personal, non-exclusive, non-transferable, limited privilege to
              access and transact on the Website.
            </li>
            <li>
              These Terms of Use constitute an electronic record in terms of the
              IT Act and rules framed there under, as applicable and amended
              from time to time. This electronic record is generated by a
              computer system and does not require any physical or digital
              signatures.
            </li>
          </ol>
          <li>ELIGIBILITY FOR USE OF WEBSITE AND / OR SERVICES</li>
          <ol className="letterList">
            <li>
              For the purposes of availing the Services and/or transacting with
              the Third-Party Service Providers through the Website, you are
              required to obtain registration, in accordance with the procedure
              established by FMA in this regard. As part of the registration
              process, FMA may collect the following personal information from
              You:
            </li>
            <ol className="romanList">
              <li>Username</li>
              <li>User ID</li>
              <li>User Email Address</li>
              <li>Address (including country and ZIP/ postal code);</li>
              <li>Gender</li>
              <li>Age</li>
              <li>Phone Number</li>
              <li>Password chosen by the User</li>
              <li>Company Information</li>
              <li>User Reporting Manager</li>
              <li>User Dependent Details </li>
              <ol className="letterList">
                <li>Spouse Name, Age, DOB</li>
                <li>Children Name, Agen, DOB</li>
                <li>Parents Name, Age, DOB</li>
                <li>Parent-In-Laws Name, Age, DOB</li>
              </ol>
              <li>User Salary Wherever applicable after user consent. </li>
              <li>Other details as You may volunteer.</li>
            </ol>
            <li>
              The registration on or use/ access of the Website is only
              available to natural persons, other than those who are
              ‘incompetent to contract’ under the Contract Act. That is, persons
              including minors, un-discharged insolvents etc. are not eligible
              to register on, or use/ access the Website. By registering,
              accessing or using the Website, you accept the terms of these
              Terms of Use and represent and warrant to FMA that you are
              ‘competent to contract’ under the Contract Act and have the right,
              authority and capacity to use the Website and agree to and abide
              by these Terms of Use.
            </li>
            <li>
              A registered id can only be utilized by the person whose details
              have been provided and FMA does not permit multiple persons to
              share a single log in/ registration id. However, a registered
              user, being either the parent or legal guardian of a person
              ‘incompetent to contract’ such as minors or persons with unsound
              mind or being authorised by another natural person, would be
              permitted to access and use the Website for the purposes of
              procuring the Services, on behalf of such persons.
            </li>
            <li>
              You agree and acknowledge that You would (i) Create Accounts as
              per your Company / Employee Requirement; (ii) provide accurate,
              truthful, current and complete information when creating Your
              account and in all Your dealings through the Website; (iii)
              maintain and promptly update Your account information; (iv)
              maintain the security of Your account by not sharing Your password
              with others and restricting access to Your account and Your
              computer; (v) promptly notify FMA if You discover or otherwise
              suspect any security breaches relating to the Website; and (vi)
              take responsibility for all the activities that occur under Your
              account and accept all risk of unauthorized access.
            </li>
            <li>
              The Website uses temporary cookies to store certain data (that is
              not sensitive personal data or information) that is used by Tall
              Tree for the technical administration of the Website, research and
              development, and for User administration. In the course of serving
              advertisements or optimizing services to You, FMA may allow
              authorized third parties to place or recognize a unique cookie on
              the Your browser. FMA does not store personally identifiable
              information in the cookies.
            </li>
          </ol>
          <li>TERMS AND CONDITIONS FOR SALE AND DELIVERY OF MEDICINES</li>
          <ol className="letterList">
            <li>Platform to facilitate transaction of business:</li>
            <ol className="romanList">
              <li>
                Through the Website, FMA facilitates the purchase of drugs and
                other pharmaceutical products, medical equipment, and services
                offered for sale by Third-Party Medicine Vendor or authorized
                sellers (“Pharmaceutical Goods and Services”). You understand
                and agree that FMA and the Website merely provide hosting
                services to You and persons browsing / visiting the Website. All
                items offered for sale on the Website, and the content made
                available by the Third-Party Medicine Vendor, are Third-Party
                user generated content and Third-Party products. FMA has no
                control over such Third-Party user generated content and/
                Third-Party products and does not - originate or initiate the
                transmission, or select the sender/recipient of the
                transmission, or the information contained in such transmission.
                The authenticity and genuineness of the Pharmaceutical Goods and
                Services made available by the Third-Party Medicine Vendor
                through the Website shall be the sole responsibility of the
                Third-Party Medicine Vendor. You understand and agree that Tall
                Tree shall have no liability with respect to the authenticity of
                the Pharmaceutical Goods and Services being facilitated through
                the Website.
              </li>
              <li>
                You understand and agree that all commercial / contractual
                terms, with respect to the sale/ purchase/ delivery and
                consumption of the Pharmaceutical Goods and Services are offered
                by and agreed to between You and the Third-Party Medicine Vendor
                and the contract for purchase of any of the Pharmaceutical Goods
                and Services, offered for sale on the Website by the Third-Party
                Medicine Vendor shall strictly be a bipartite contract between
                the Third-Party Medicine Vendor and You.
              </li>
              <li>
                You understand and agree that all commercial / contractual
                terms, with respect to the sale/ purchase/ delivery and
                consumption of the Pharmaceutical Goods and Services are offered
                by and agreed to between You and the Third-Party Medicine Vendor
                and the contract for purchase of any of the Pharmaceutical Goods
                and Services, offered for sale on the Website by the Third-Party
                Medicine Vendor shall strictly be a bipartite contract between
                the Third-Party Medicine Vendor and You.
              </li>
              <li>
                The commercial / contractual terms include without limitation -
                price, shipping costs, payment methods, payment terms, date,
                period and mode of delivery, warranties related to
                Pharmaceutical Goods and Services offered for sale by the
                Third-Party Medicine Vendor, and after sales services related to
                such Pharmaceutical Goods and Services. FMA does not have any
                control over, and does not determine or advise or in any way
                involve itself in the offering or acceptance of, such commercial
                / contractual terms offered by and agreed to, between You and
                the Third-Party Medicine Vendor.
              </li>
            </ol>
            <li>Representation as to legal title</li>
            <ol className="romanList">
              <li>
                FMA does not make any representation or warranty as to the legal
                title of the Pharmaceutical Goods and Services offered for sale
                by the Third-Party Medicine Vendor on the Website. At no time
                shall any right, title, claim or interest in the products sold
                through or displayed on the Website vest with FMA nor shall FMA
                have any obligations or liabilities in respect of any
                transactions on the Website. You agree and acknowledge that the
                ownership of the inventory of such Pharmaceutical Goods and
                Services shall always vest with the Third-Party Medicine Vendor,
                who are advertising or offering them for sale on the Website and
                are the ultimate sellers.
              </li>
              <li>
                You agree and acknowledge that the Third-Party Medicine Vendor
                shall be solely responsible for any claim/ liability/ damages
                that may arise in the event it is discovered that such
                Third-Party Medicine Vendor do not have the sole and exclusive
                legal ownership over the Pharmaceutical Goods and Services that
                have been offered for sale on the Website by such Third-Party
                Medicine Vendor, or did not have the absolute right, title and
                authority to deal in and offer for sale such Pharmaceutical
                Goods and Services on the Website.
              </li>
            </ol>
            <li>Non-Performance of Contract</li>
            <p>You accept and acknowledge the following:</p>
            <ol className="romanList">
              <li>
                FMA is not responsible for any unsatisfactory, delayed,
                non-performance or breach of the contract entered into between
                You and the Third-Party Medicine Vendor for purchase and sale of
                goods or services offered by such Third-Party Medicine Vendor on
                the Website.
              </li>
              <li>
                FMA cannot and does not guarantee that the concerned Third-Party
                Medicine Vendor will perform any transaction concluded on the
                Website.
              </li>
              <li>
                The Third-Party Medicine Vendor(s) are solely responsible for
                ensuring that the Pharmaceutical Goods and Services offered for
                sale on the Website are kept in stock for successful fulfilment
                of orders received. Consequently, FMA is not responsible if the
                Third-Party Medicine Vendor(s) does not satisfy the contract for
                sale of Pharmaceutical Goods and Services which are out of
                stock, back ordered or otherwise unavailable, but were shown as
                available on the Website at the time of placement of order by
                You; and
              </li>
              <li>
                FMA shall not and is not required to mediate or resolve any
                dispute or disagreement between You and Third-Party Medicine
                Vendor. In particular, FMA does not implicitly or explicitly
                support or endorse the sale or purchase of any items or services
                on the Website.
              </li>
              <li>
                Any liability of FMA for any failure of any Third-Party Service
                Provider shall be restricted to the amount paid by you towards
                availing and your sole recourse for any failure to provide any
                Service by any Third-Party Service Provider shall be for a
                refund of any amounts already paid by you for such Service
              </li>
            </ol>
            <li>
              Exhibition of drugs and publication of Third-Party Medicine Vendor
              content on the Website
            </li>
            <ol className="romanList">
              <li>
                You agree and acknowledge that the respective Third-Party
                Medicine Vendor are exhibiting Third-Party Content which
                includes catalogue of drugs/ pharmaceutical products or
                services, and information in relation to such drugs/
                pharmaceutical products or services, on the Website.
              </li>
              <li>
                The Third-Party Content available on the Website, including
                without limitation, text, copy, audio, video, photographs,
                illustrations, graphics and other visuals, is for general
                information purposes only and does not constitute either an
                advertisement/ promotion of any drug being offered for sale by
                the Third-Party Medicine Vendor on the Website or any
                professional medical advice, diagnosis, treatment or
                recommendations of any kind.
              </li>
              <li>
                You acknowledge and agree that such Third-Party Medicine Vendor
                shall be solely responsible for ensuring that such Third-Party
                Content made available regarding the Pharmaceutical Goods and
                Services offered for sale on the Website, are not misleading and
                describe the actual condition of the Pharmaceutical Goods and
                Services. In this connection, it is solely the responsibility of
                the concerned Third-Party Medicine Vendor(s) to ensure that all
                such information is accurate in all respects and there is no
                exaggeration or over emphasis on the specifics of such
                Pharmaceutical Goods and Services so as to mislead the Users in
                any manner. You acknowledge and understand that FMA provides no
                warranty or representation with respect to the authenticity/
                veracity of the information provided on the Website and You must
                run Your own independent check. You agree and acknowledge that
                FMA has not played any role in the ascertainment of the actual
                impact/ effect of any Pharmaceutical Goods and Services being
                offered for sale by the Third-Party Medicine Vendor on the
                Website. Further, it is hereby clarified that the Third-Party
                Medicine Vendor are offering the Pharmaceutical Goods and
                Services for sale to You and they are responsible for procuring
                the appropriate licenses for the same under the Drugs Act read
                with the Drug rules and the Pharmacy Act. You agree and
                acknowledge that You shall not hold FMA responsible or liable
                for any damages arising out of such reliance on Third-Party user
                generated content by You.
              </li>
            </ol>
            <li>Prescription Drugs</li>
            <ol className="romanList">
              <li>
                The Website is a platform that can be used by the Users to
                purchase various drugs and pharmaceutical products that requires
                a valid medical prescription issued by a medical expert/ doctor
                to be provided to a registered pharmacist for the purpose of
                dispensing such medicine (“Prescription Drugs”), offered for
                sale on the Website by Third-Party Medicine Vendor. In order to
                purchase Prescription Drugs from Third-Party Medicine Vendor
                through the Website, You are required to upload a scanned copy
                of a valid prescription on the Website or email the same to Tall
                Tree. The order would not be processed and forwarded to the
                concerned Third-Party Medicine Vendor(s) by FMA until it
                receives a copy of a valid prescription. Third-Party Medicine
                Vendor will verify the prescription forwarded by You and in case
                the Third-Party Medicine Vendor(s) observes any discrepancy in
                the prescription uploaded by You, the Third-Party Medicine
                Vendor(s) will cancel the order immediately. You are also
                required to make the original prescription available at the time
                of receipt of delivery of Prescription Drugs. If required by law
                You shall allow the delivery agent to stamp the original
                prescription at the time of medicine delivery failing which
                medicines will not be delivered.
              </li>
              <li>
                You authorize FMA to maintain a record of all the prescriptions
                uploaded by You and to also include it in the EHR Services.
              </li>
            </ol>
            <li>Substitution of Prescribed Drugs</li>
            <ol className="romanList">
              <li>
                You acknowledge and accept that the order for a substitute of a
                Prescription Drug would only be processed if the medical expert/
                doctor has himself/ herself permitted for any other equivalent
                generic drug to be dispensed in place of the Prescription Drug
                and this fact is explicitly mentioned in the prescription or if
                the prescription solely lists the salt names instead of a
                specific brand name.
              </li>
              <li>
                You further acknowledge and accept that, in the absence of the
                above, the concerned Third-Party Medicine Vendor would not
                dispense a substitute drug in place of the Prescription Drug.
              </li>
            </ol>
            <li>Invitation to offer for sale</li>
            <ol className="romanList">
              <li>
                Notwithstanding anything else contained in any other part of
                these Terms of Use, the listing of drugs and other
                pharmaceutical products on the Website by the Third-Party
                Medicine Vendor is merely an ‘invitation to an offer for sale’
                and not an ‘offer for sale’. The placement of an order by You
                shall constitute an offer by You to enter into an agreement with
                the Third-Party Medicine Vendor (“Offer”). Post the Offer from
                the Third-Party Medicine Vendor, FMA along with the service
                provider shall send an email to You with the information on the
                Offer along with the details of the concerned Third-Party
                Medicine Vendor(s) who may undertake the sale, and such an email
                shall not be considered as an acceptance of the Offer. The
                acceptance of the Offer would only be undertaken by the
                Third-Party Medicine Vendor(s) after the validation/
                verification of the prescription by such Third-Party Medicine
                Vendor (in case of Prescription Drugs) and the ascertainment of
                the available stock in the relevant Third-Party Medicine
                Vendor(s) (in the case of prescription as well as other drugs/
                pharmaceutical products), by way of a confirmatory email to be
                sent to You.
              </li>
              <li>
                For the avoidance of any doubt, it is hereby clarified that any
                reference of the term ‘offer/ offered for sale by the
                Third-Party Medicine Vendor’, as appearing in these Terms of
                Use, shall be construed solely as an ‘invitation to offer for
                sale’ by any such Third-Party Medicine Vendor.
              </li>
              <li>Transfer of Property and Completion of Sale</li>
              <li>
                Upon acceptance of the Offer by the concerned Third-Party
                Medicine Vendor, the Pharmaceutical Drugs and Services would be
                dispensed at the pharmacy, in accordance with the terms of the
                order placed by You. Such dispensation shall also take place
                under the direct/ personal supervision of the pharmacist of the
                Third-Party Medicine Vendor, wherever required under the
                applicable law(s).
              </li>
              <li>
                You agree and acknowledge that the property and title in the
                Pharmaceutical Drugs and Services ordered by You shall stand
                immediately transferred to You upon the dispensation of
                Pharmaceutical Drugs and Services and the raising of the invoice
                at the concerned Third-Party Medicine Vendor. Accordingly, the
                sale of Pharmaceutical Drugs and Services is done by the
                concerned Third-Party Medicine Vendor itself.
              </li>
              <li>
                The invoice in relation to the Pharmaceutical Drugs and
                Services, that are required to be delivered to You shall be
                issued by the Third-Party Medicine Vendor which is to process
                and satisfy the order for such Pharmaceutical Drugs and
                Services.
              </li>
            </ol>
            <li>Delivery of Drugs</li>
            <ol className="romanList">
              <li>
                The Pharmaceutical Drugs and Services shall be delivered by the
                Third-Party Medicine Vendor or independent contractors. You
                accept and acknowledges that the Third-Party Medicine Vendor or
                such other transporter/ courier/ delivery personnel, engaged by
                the Third-Party Medicine Vendor or FMA including its Vendors,
                service partners, shall be independent contractors in-charge of
                the delivery of the Pharmaceutical Drugs and Services from the
                concerned Third-Party Medicine Vendor to the address notified by
                You, with no control over the Pharmaceutical Drugs and Services
                and no additional obligation apart from standard delivery
                obligations and duty of care.
              </li>
            </ol>
          </ol>
          <li>ADVERTISING GUIDELINES FOR THE WEBSITE</li>
          <ol className="letterList">
            <li>
              As part of the Services provided by Us; We facilitate and allow
              Third-Party Advertising Entities to place advertisements on the
              Website. Accordingly, there are guidelines (as listed herein
              below) which the Third-Party Advertising Entities have to follow
              for placing such advertisements (the “Advertising Policy”).
            </li>
            <ol className="romanList">
              <li>
                General Rules: All the advertisements must comply with the
                Advertising Policy, the terms of these Terms of Use and the
                Privacy Policy. FMA may, at any time and without having to serve
                any prior notice to the Third-Party Advertising Entities, (i)
                upgrade, update, change, modify, or improve the Website or a
                part thereof in a manner it may deem fit, and (ii) change the
                content of the Advertising Policy and/ or these Terms of Use
                and/ or the Privacy Policy. It is the responsibility of the
                Third-Party Advertising Entities, in such cases, to review the
                terms of the Advertising Policy and/ or these Terms of Use and/
                or the Privacy Policy, from time to time. Such a change shall be
                made applicable when they are posted. FMA may also alter or
                remove any content from the Website without notice and without
                liability. The Third-Party Advertising Entities are also
                responsible for ensuring that their advertisements comply with
                all applicable law(s) in India and any other jurisdiction that
                such Third-Party Advertiser(s) are based out of, industry codes,
                rules and regulations in each geographic area where the
                advertisements will run. All disclosures in the advertisements
                must be clear and conspicuous.
              </li>
              <li>
                Review: All the advertisements are subject to the review and
                approval of FMA. FMA reserves the right to reject or remove any
                advertisement in its sole discretion for any reason. Further,
                FMA also reserves the right to reject an advertisement or to
                request modifications to any advertisement, and to require
                factual substantiation for any claim made in an advertisement.
              </li>
            </ol>
            <li>Editorial Policy for the Website</li>
            <p>
              As part of the Services, FMA provides Vendor / Service Partner
              Content on the Website targeted at general public for
              informational purposes only and does not constitute professional
              medical advice, diagnosis, treatment or recommendations of any
              kind. FMA Content is subject to the following:
            </p>
          </ol>
          <li>
            DIAGNOSTICS SERVICES PROVIDED BY THIRD-PARTY DIAGNOSTIC LABS AND
            RADIOLOGY CENTRES
          </li>
          <p>
            As a condition of Your use of and access to the diagnostic services
            provided through the Website and Your acceptance of these Terms of
            Use, You are subject to the following rules/ guidelines:
          </p>
          <ol className="letterList">
            <li>Platform to facilitate transaction of business:</li>
            <ol className="romanList">
              <li>
                FMA provides Services through the Website as a marketplace and
                facilitates the Users to avail diagnostic test/ packages
                facilities offered by Third-Party Diagnostic Labs and Radiology
                Clinics through the Website. FMA is not and shall not be
                responsible for any sample collected, tests conducted and
                reports generated by the Third-Party Diagnostic Labs and
                Radiology Clinics and does not deal with any of Third-Party
                Labs’ client or patient managed by Third-Party Diagnostic Labs
                and Radiology Clinics through the Website and only provides
                facilitation Services to the Users through the Website. Use of
                the Website may require the Third-Party Diagnostic Labs and
                Radiology Clinics to use software and the Third-Party Diagnostic
                Labs and Radiology Clinics have to ensure the procurement of
                such software from the concerned providers. User and the
                Third-Party Diagnostic Labs and Radiology Clinics agree to use
                the Website and the materials provided therein only for purposes
                that are permitted by: (a) these Terms of Use; and (b) any
                applicable law(s), regulation or generally accepted practices or
                guidelines in the relevant jurisdictions.
              </li>
              <li>
                The Third-Party Diagnostic Labs and Radiology Clinics may not
                access the Services if the Third-Party Diagnostic Labs and
                Radiology Clinics are FMA’s direct competitor, except with FMA’s
                prior written consent. In addition, the Third-Party Diagnostic
                Labs and Radiology Clinics may not access the Services for
                purposes of monitoring their availability, performance or
                functionality, or for any other benchmarking or competitive
                purposes.
              </li>
              <li>
                FMA will use commercially reasonable efforts to make the
                Services available 24 (twenty-four) hours a day, 7 (seven) days
                a week, except for (i) planned downtime or (ii) any
                unavailability caused by circumstances beyond FMA’s reasonable
                control, including without limitation Force Majeure Events (as
                defined herein below). FMA will provide the Services only in
                accordance with applicable law(s) and government regulations.
              </li>
              <li>
                Notwithstanding anything to the contrary contained herein,
                Third-Party Diagnostic Labs and Radiology Clinics alone shall be
                liable for their dealings and interaction with the Users who
                avail the Services and FMA shall have no liability or
                responsibility in this regard. FMA does not guarantee or make
                any representation with respect to the correctness, completeness
                or accuracy of the information or details provided by such User,
                Third-Party Diagnostic Labs and Radiology Clinics or any
                diagnostic center or any Third-Party through the Website. The
                Services should not be used for emergency appointment purposes.
              </li>
              <li>
                Notwithstanding anything to the contrary contained herein,
                Third-Party Diagnostic Labs and Radiology Clinics alone shall be
                liable for dealings and interaction with Users contacted or
                managed through the Website and FMA shall have no liability or
                responsibility in this regard. FMA does not guarantee or make
                any representation with respect to the correctness, completeness
                or accuracy of the tests conducted and reports generated by the
                Third-Party Labs.
              </li>
              <li>
                FMA may, at its sole discretion, suspend Third-Party Diagnostic
                Labs and Radiology Clinics or Users ability to use or access the
                Website at any time. FMA shall have no liability or
                responsibility in this regard.
              </li>
            </ol>
          </ol>
          <li>TERMS FOR USE OF THE ONLINE DOCTOR CONSULTANCY SERVICES</li>
          <p>
            FMA is an online health platform that provides a variety of online
            and online-linked health products and services to the Users for
            health-related information and resources. Whenever We use the words
            "Your physician" or "Your doctor" or "healthcare provider" or
            similar words on the Website, including in these Terms of Use, we
            mean Your personal doctor with whom You have an actual, mutually
            acknowledged, doctor-patient relationship. FMA’s Medical Experts are
            not "Your" physician or healthcare provider.
          </p>
          <ol className="letterList">
            <li>
              You acknowledge that the Medical Experts empaneled with Us are
              independent contractors and thereby FMA has an independent
              contractor relationship with such Medical Experts and therefore in
              no event FMA will be directly or vicariously liable for any advice
              or medical consultancy or any loss arising therefrom that the
              Medical Experts may provide to You or You may avail as part of the
              Services.
            </li>
            <li>
              You acknowledge that although some of the content, text, data,
              graphics, images, information, suggestions, guidance, and other
              material (collectively, “Information”) that is provided to You on
              the Website (including Information provided in direct response to
              Your questions or postings) may be provided by individuals in the
              medical profession, the provision of such Information does not
              create a doctor/medical professional-patient relationship, but is
              provided to inform You on various medical conditions, medical
              diagnosis and treatment and it does not constitute a direct
              medical diagnosis, treatment or prescription. Everything on the
              Website should be used for information purposes only.
            </li>
            <li>
              FMA is designed to support the health decisions and choices that
              You make. These decisions and choices are Yours, and We believe
              that You, in connection with the advice You receive from Your
              doctor or other professional healthcare provider, are the best
              decision maker about Your health. We cannot make decisions for
              you. However, what We can do is help You find good health
              information and connect with doctors for in-person information. On
              FMA You can ask and find informational questions and related
              educational answers by Medical Experts.
            </li>
            <li>
              We do not recommend or endorse any specific Medical Expert(s),
              tests, products, procedures, opinions, or other information that
              may be mentioned on the Website. Reliance on any information
              provided on the Website is solely at Your own risk. In case of any
              medical emergency, kindly contact Your nearest doctor/hospital or
              any related helpline.
            </li>
            <li>
              The Services are not for use in medical emergencies or for
              critical health situations requiring prompt medical attention. You
              take full responsibility for ensuring that the information
              submitted is accurate and FMA shall not make any effort to
              validate any information provided by You for using the Services
              with respect to content, correctness or usability. We, with an
              intention to provide the best services possible could ask You to
              share more information as and when needed.
            </li>
            <li>
              The opinions, statements, answers (collectively “Consultation”)
              provided by the Medical Experts through the Website are solely the
              individual and independent opinions and statements of such Medical
              Experts and do not reflect the opinions of FMA, its affiliates or
              any other organizations or institutions to which such Medical
              Expert or such specialist or professional is affiliated or
              provides services. FMA does not recommend or endorse any specific
              tests, physicians, products, procedures, opinions, or other
              information that may be mentioned on the Website or by a licensee
              of FMA.
            </li>
            <li>
              The inclusion of professionals, specialists and/ or Medical
              Experts on the Website or in any professional directory on the
              Website does not imply recommendation or endorsement and is
              provided on an ‘as-is’ basis and FMA disclaims all warranties,
              either express or implied, including but not limited to the
              implied warranties of merchantability and fitness for particular
              purpose.
            </li>
            <li>
              FMA the owners and the employee staff of FMA accept no
              responsibility for any medical, legal or financial events or
              outcomes related to the Services availed through the use of the
              Website.
            </li>
            <li>
              FMA makes no warranty that the Services will meet Your
              requirements, or that the Service(s) will be uninterrupted,
              timely, secure, or error free. This includes loss of data, or any
              service interruption caused by FMA employees. FMA is not
              responsible for transmission errors, corruption of data.
            </li>
            <li>
              FMA is for personal use and the Services are for individuals to
              use for supporting their personal health decisions. You may use
              the Website for personal, but not for commercial, purposes.
            </li>
            <li>
              The Website may not be used for illegal purposes. You may not
              access our networks, computers, or the Information and Services in
              any manner that could damage, disable, overburden, or impair them,
              or interfere with any other person's use and enjoyment. You may
              not attempt to gain unauthorized access to any Information or
              Services, other accounts, computer systems, or networks connected
              with the Website, the Information, or Services. You may not use
              any automated means to access the Website, the Information, or
              Services for any purpose.
            </li>
            <li>
              You may not use another person’s login credentials to access his
              or her FMA profile/ account unless expressly permitted to do so by
              FMA. Any attempt by any individual or entity to solicit login
              information of any other user or Medical Expert or to access any
              such account is an express and direct violation of these Terms of
              Use and of applicable law(s), including relevant privacy and
              security laws and laws prohibiting unfair or unethical business
              practices.
            </li>
            <li>Your right to use the Services is not transferable.</li>
            <li>
              Notwithstanding anything to the contrary contained herein, you
              alone shall be liable for Your dealings and interaction with
              patients or Medical Experts (as the case may be) contacted or
              managed through the Website and FMA shall have no liability or
              responsibility in this regard. FMA does not guarantee or make any
              representation with respect to the correctness, completeness or
              accuracy of the Information or detail provided by such client,
              patient, User, Medical Experts or any Third-Party through the
              Website. The Services should not be used for emergency appointment
              purposes.
            </li>
            <li>
              FMA may, at its sole discretion, suspend User’s or Medical
              Expert’s ability to use or access the Website at any time. Tall
              Tree can edit profiles of Medical Experts to make them more
              suitable for patient/ Users searches on the Website
            </li>
          </ol>
          <p className="paragraphTitle">NO DOCTOR - PATIENT RELATIONSHIP</p>
          <p>
            Please note that some of the content, text, data, graphics, images,
            information, suggestions, guidance, and other material
            (collectively, "Information") that may be available on the app
            (including information provided in direct response to your questions
            or postings) may be provided by individuals in the medical
            profession. The provision of such Information does not create a
            licensed medical professional/patient relationship, between Tall
            Tree and you and does not constitute an opinion, medical advice, or
            diagnosis or treatment of any particular condition, but is only
            provided to assist you with locating appropriate medical care from a
            qualified practitioner. We make no guarantees, representations or
            warranties, whether expressed or implied, with respect to
            professional qualifications, quality of work, expertise or other
            information provided on the website. We do not in any way endorse
            any individual described herein. In no event shall we be liable to
            you or anyone else for any decision made or action taken by you in
            reliance on such information
          </p>
          <p className="paragraphTitle">E-Consultation Services:</p>
          <p>
            User agrees that E-Consultation is not a substitute for physical
            consultation and the Services are meant for general consultation
            only. If after the E-consultation, the Physician recommends any
            diagnostic tests to be undertaken or issues a Prescription, the same
            are provided based on the information and preliminary examination by
            the Physician, hence the same shall not be treated as accurate,
            final and conclusive. Physician reserves their rights to modify the
            prescription or recommended diagnostic tests if the User provides
            any additional information in future consultation. While rendering
            E-Consultation, Physician will require to know the age, sex,
            previous and existing health conditions, symptoms, past medical
            history, physical examination (if directed by the Physician) etc.
            All the information with respect to the User History, consultation
            records, medical record and prescriptions issued by the Physician
            will be saved with FMA and the same may be used for rendering
            Services as may be required by the User for time to time. Services
            offered by FMA are intended for direct use of the User or their
            family members, but the same cannot be used for rendering Services
            to third parties. FMA disclaims all liabilities arising from use of
            the Services by the User and the maximum liability of FMA to the
            User shall be limited to the Service Fees paid by the User to FMA.
          </p>
          <p className="paragraphTitle">
            Consent to Participate in Telemedicine Consultation: -:
          </p>
          <p>
            User consent to, agree to and understand the following with regards
            to usage of FMA website and/or mobile applications to participate in
            a telemedicine / video consultation:
          </p>
          <ol>
            <li>
              Video conferencing technology will be used to affect consultation
              and this consultation will not be the same as a direct
              patient/healthcare provider visit due to the fact that user will
              not be in the same room as healthcare provider.
            </li>
            <li>
              There are potential risks to this technology, including
              interruptions, unauthorized access and technical difficulties.
            </li>
            <li>
              Healthcare provider or user can discontinue the telemedicine
              consult/visit if it is felt that the videoconferencing connection
              is not adequate for the situation.
            </li>
            <li>
              User healthcare information may be shared with other individuals
              for scheduling and billing purposes.
            </li>
            <li>
              Others may be present during the consultation other than
              healthcare provider in order to enable them to provide effective
              services. All such personnel will maintain confidentiality of the
              information obtained.
            </li>
            <li>
              E-consultations shall be available for family physician & multi
              specialties E-consultation from a specific doctor/physician shall
              be available subject to his/her
              availability/willingness/discretion. Confirmation of your
              appointment with a specific doctor/physician, as per his/her
              availability, shall be sent to you via SMS and/or E-mail. Tall
              Tree reserves the right to reschedule or cancel an appointment
              without any prior notice. The time provided for consultation to
              you is indicative and actual consultation time may change
              depending on the consulting doctor's discretion. Your consulted
              physician reserves the right to provide post consultation free
              review consultations can be booked, rescheduled or cancelled only
              within the stipulated time limits
            </li>
            <li>
              Please note that E-Consultation services are provided at the
              express consent by the User and the same shall not be construed as
              a replacement for physical consultation. Users are warned not to
              use E-Consultation Services in times of emergency and any medical
              Services that require further diagnostics and physical inspection.
            </li>
          </ol>
          <li>RISKS OF USING FMA’S SERVICES</li>
          <ol className="letterList">
            <li>
              As with any medical procedure, there are potential risks
              associated with using the Services. By using the Services, You
              agree to abide by these Terms of Use, Privacy Policy and risks
              described below. These risks include, but may not be limited to:
            </li>
            <ol className="romanList">
              <li>
                The Medical Experts not having access to all or some of Your
                medical history that might be critical to consult You. The
                Medical Experts may not have the benefit of information that
                would be obtained by examining You in person, observing Your
                physical condition and by going through Your medical records.
                This means that the Services provided is different from the
                diagnostic and treatment services typically decided by a
                physician. Therefore, the Medical Experts may not be aware of
                facts or information that would affect his or her opinion of
                Your diagnosis. To reduce the risk of this limitation, FMA
                strongly encourages You to be in touch with an on-ground
                physician and share the FMA’s opinion with him/her.
              </li>
              <li>
                By requesting a medical opinion through the Website, you
                acknowledge and agree that:
              </li>
              <ol className="smallLetterList">
                <li>
                  the advice/information/opinion on diagnosis You may receive
                  could be limited and provisional.
                </li>
                <li>
                  the medical opinion is not intended to replace a face-to-face
                  visit with a physician, and it does replace an actual
                  doctor-patient relationship.
                </li>
                <li>
                  in case of a second opinion where there is a difference of
                  opinion among Our Medical Experts and Your physician, you
                  would bear the responsibility to decide on online or offline
                  consultation, or procedure, and/or treatment;
                </li>
                <li>
                  the Medical Expert is reliant on information provided by You
                  and hence any information demonstrated to have been falsified,
                  misleading or incomplete will immediately render the opinion
                  and all details therein null and void;
                </li>
                <li>
                  in some cases, the Medical Expert may determine that the
                  transmitted information is of inadequate quality and may ask
                  for more information, without which he/she may refuse to
                  answer the query;
                </li>
                <li>
                  in rare cases, the Medical Experts may feel that the query may
                  not be answerable without physically examining the patient/
                  Users and the Consultation may be refused forthwith;
                </li>
                <li>
                  in very rare instances, security protocols could fail, causing
                  a breach of privacy of personal medical information; and
                </li>
                <li>
                  delays in medical evaluation and answers could occur due to
                  deficiencies or failures of the service as per those mentioned
                  in these Terms of Use.
                </li>
              </ol>
            </ol>
          </ol>
          <li>OTHER TERMS</li>
          <ol className="smallLetterList">
            <li>
              Your Profile, Collection, Use, Storage and Transfer of Personal
              Information:
            </li>
            <ol className="romanList">
              <li>
                Your FMA profile is created to store record of Your
                Consultations and Your personal health information online,
                including history, health conditions, allergies and medications.
              </li>
              <li>
                Any information provided as part of a web Consultation or
                obtained from use of the Services by You becomes part of Your
                FMA record. You agree to provide accurate information to help Us
                serve You best to Our knowledge, to periodically review such
                information and to update such information as and when
                necessary. FMA reserves the right to maintain, delete or destroy
                all communications and materials posted or uploaded to the
                Website according to its internal record retention and/or
                destruction policies. You might be contacted via email to review
                the information provided by You for FMA’s record or for the
                Services. Please make sure You provide a valid email-id and You
                update it as and when needed.
              </li>
              <li>
                The terms “personal information” and “sensitive personal data or
                information” are defined under the Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Information) Rules, 2011 (the “SPI Rules”).
              </li>
              <ol>
                <li>The Privacy Policy sets out:</li>
                <ol className="smallLetterList">
                  <li>
                    the type of information collected from Users, including
                    sensitive personal data or information.
                  </li>
                  <li>
                    the purpose, means and modes of usage of such information;
                    and
                  </li>
                  <li>how and to whom FMA will disclose such information.</li>
                </ol>
                <li>
                  The Users are expected to read and understand the Privacy
                  Policy, so as to ensure that he or she has the knowledge of:
                </li>
                <ol className="smallLetterList">
                  <li>the fact that the information is being collected.</li>
                  <li>
                    the purpose for which the information is being collected.
                  </li>
                  <li>the intended recipients of the information.</li>
                  <li>
                    the name and address of the agency that is collecting the
                    information and the agency that will retain the information;
                    and
                  </li>
                  <li>
                    the various rights available to such Users in respect of
                    such information.
                  </li>
                </ol>
              </ol>
              <li>
                FMA shall not be responsible in any manner for the authenticity
                of the personal information or sensitive personal data or
                information supplied by the Users to FMA or any other person
                acting on behalf of FMA.
              </li>
              <li>
                The use of the Website involves every Users’ registration
                information and browsing history being stored and submitted to
                the appropriate authorities. The consent and procedure for such
                collection and submission is provided in the Privacy Policy. The
                other information collected by FMA from Users as part of the
                registration process is described in the Privacy Policy. The
                consent and revocation procedures in relation to the same are
                set out in the Privacy Policy.
              </li>
              <li>
                The Users are responsible for maintaining the confidentiality of
                the Users’ account access information and password. The Users
                shall be responsible for all uses of the Users’ account and
                password, whether or not authorized by the Users. The Users
                shall immediately notify FMA of any actual or suspected
                unauthorized use of the Users’ account or password.
              </li>
              <li>
                If a User provides any information that is untrue, inaccurate,
                not current or incomplete (or becomes untrue, inaccurate, not
                current or incomplete), or FMA has reasonable grounds to suspect
                that such information is untrue, inaccurate, not current or
                incomplete, FMA shall have the right to suspend or terminate
                such account at its sole discretion.
              </li>
              <li>
                FMA may disclose or transfer User Information (as defined in the
                Privacy Policy) to its affiliates in other countries, and You
                hereby consent to such transfer. The SPI Rules only permit FMA
                to transfer sensitive personal data or information including any
                information, to any other body corporate or a person in India,
                or located in any other country, that ensures the same level of
                data protection that is adhered to by Tall Tree as provided for
                under the SPI Rules, only if such transfer is necessary for the
                performance of the lawful contract between FMA or any person on
                its behalf and the user or where the User has consented to data
                transfer.
              </li>
              <li>
                By accepting these Terms of Use and by registering on the
                Website, FMA App or through Support Associate, Support Centre .
                You consent to be contacted by Us and by the Third-Party Service
                Providers and You further consent to receive emails and messages
                (SMS) notifications and information at any time from Us and from
                Third-Party Service Providers.
              </li>
            </ol>
            <li>Payment, Fees and Taxes:</li>
            <ol className="romanLetterList">
              <li>
                Registration on the Website and the access to the information
                provided on the Website is free. FMA does not charge any fee for
                accessing, browsing and buying through the Website. You agree to
                make all payments directly to the respective Third-Party
                Medicine Vendor for purchase of Pharmaceutical Goods and
                Services from such Third-Party Medicine Vendor through our
                Payment Gateway Vendor. You agree and acknowledge that You shall
                not hold FMA responsible for any loss or damage caused to You
                during the process, due to any acts or omission on the part of
                third parties viz. the Third-Party Medicine Vendor or the
                collection agents or for any actions/ omissions which are beyond
                the control of FMA.
              </li>
              <li>
                In relation to the diagnostic services being availed from the
                Website, Third-Party Diagnostic Labs and Radiology Clinics agree
                to pay all package fees, consulting fees and other fees
                applicable to the Third-Party Diagnostic Labs and Radiology
                Clinics use of such Services and the Third-Party Diagnostic Labs
                and Radiology Clinics shall not circumvent the fee structure.
                The fee is dependent on the package that the Third-Party
                Diagnostic Labs and Radiology Clinics purchase and not on actual
                usage of the Services. In relation to the Users using the
                diagnostic Services, the Users agree to make all payments
                directly to the respective Third-Party Diagnostic Labs and
                Radiology Clinics for use of the diagnostic Services from the
                Website through FMA payment Gateway Vendor. You agree and
                acknowledge that You shall not hold FMA responsible for any loss
                or damage caused to You during the process, due to any acts or
                omission on the part of the Third-Party Labs’ any actions/
                omissions which are beyond the control of FMA.
              </li>
              <li>
                Each User / Third-Party Service Providers are solely responsible
                for payment of all taxes, legal compliances, statutory
                registrations and reporting. FMA is in no way responsible for
                any of the taxes except for its own income tax.
              </li>
              <li>
                The subscription fees for the Services, if any charged by Tall
                Tree, could be paid online through the facility made on the
                Website. Third parties support and services are required to
                process online fee payment. FMA is not responsible for any loss
                or damage caused to User/ Third-Party Service Providers during
                this process as these third parties are beyond the control of
                FMA. The fees could also be paid offline and be either collected
                personally from the User/ Third-Party Service Providers or
                required to be mailed to FMA at the following address:
                info@Rahainsure.com
              </li>
              <li>All fees are exclusive of applicable taxes.</li>
              <li>
                FMA reserves the right to modify the fee structure by providing
                on the Website which shall be considered as valid and agreed
                communication.
              </li>
              <li>
                In order to process the payments, FMA might require details of
                User’s/ Third-Party Service Providers’ bank account, credit card
                number etc. Please check Our Privacy Policy on how FMA uses the
                confidential information provided by Users.
              </li>
            </ol>
            <li>Return, Refund, Cancellation and Shipping charges:</li>
            <ol className="romanList">
              <li>
                We offer return and refund on the products and Services ordered
                by You on the Website which are subject to further terms and
                conditions as detailed in the return, refund, cancellation, and
                shipping charges policy (“Return and Refund Policy”). The Return
                and Refund Policy forms an integral part of these Terms of Use
                and the Users are requested to carefully read the same.
              </li>
            </ol>
          </ol>
          <li>COVENANTS</li>
          <ol className="romanList">
            <li>
              Covenanters for the purposes of these terms of use shall include
              the users and the third-party service providers
            </li>
            <li>
              Each Covenanter undertakes that it shall not do any act or post,
              display, upload, modify, publish, transmit, update or share any
              information that -
            </li>
            <ol>
              <li>
                belongs to another person and to which the such Covenanter does
                not have any right;
              </li>
              <li>
                is grossly harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, pedophilic, libelous, invasive of another's
                privacy, hateful, or racially, ethnically objectionable,
                disparaging, relating or encouraging money laundering or
                gambling, or otherwise unlawful in any manner whatever.
              </li>
              <li>
                infringes any patent, trademark, copyright or other intellectual
                proprietary rights of any Third-Party;
              </li>
              <li>violates any law for the time being in force.</li>
              <li>impersonates another person.</li>
              <li>
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource.
              </li>
              <li>
                is prohibited under applicable law(s) for the time being in
                force including Drugs Act read with the Drugs Rules, the Drugs
                and Magic Act, the Indian Penal Code, 1860, as amended from time
                to time and rules made there under; and
              </li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognizable
                offence or prevents investigation of any offence or is insulting
                any other nation.
              </li>
            </ol>
            <li>You are also prohibited from:</li>
            <ol>
              <li>
                violating or attempting to violate the integrity or security of
                the Website or any FMA Content;
              </li>
              <li>
                transmitting any information (including job posts, messages and
                hyperlinks) on or through the Website that is disruptive or
                competitive to the provision of Services by FMA;
              </li>
              <li>
                intentionally submitting on the Website any incomplete, false or
                inaccurate information;
              </li>
              <li>
                making any unsolicited communications to other Covenanters.
              </li>
              <li>
                using any engine, software, tool, agent or other device or
                mechanism (such as spiders, robots, avatars or intelligent
                agents) to navigate or search the Website;
              </li>
              <li>
                attempting to decipher, decompile, disassemble or reverse
                engineer any part of the Website;
              </li>
              <li>
                copying or duplicating in any manner any of the FMA Content or
                other information available from the Website; and
              </li>
              <li>framing or hotlinking or deep linking any FMA Content.</li>
            </ol>
            <li>
              FMA, upon obtaining knowledge by itself or been brought to actual
              knowledge by an affected person in writing or through email signed
              with electronic signature about any such information as mentioned
              in this Clause 4, shall be entitled to disable such information
              that is in contravention of this Clause 4 or any provisions of
              these Terms of Use. FMA shall be entitled to preserve such
              information and associated records for at least 90 (ninety) days
              for production to governmental authorities for investigation
              purposes.
            </li>
            <li>
              In case of non-compliance with any applicable law(s), rules or
              regulations, or these Terms of Use or the Privacy Policy by a
              Covenanter, FMA has the right to immediately terminate the access
              or usage rights of the Covenanter to the Services and to remove
              noncompliant information.
            </li>
          </ol>
          <li>LIABILITY</li>
          <ol>
            <li>
              FMA shall not be responsible or liable in any manner to the Users
              or any Third-Party Service Providers (collectively referred to as
              the “Other Parties”) for any losses, damage, injuries or expenses
              incurred by Other Parties as a result of any disclosures made by
              FMA, where Other Parties have consented to such disclosures.
            </li>
            <li>
              The Services provided by FMA or any of its licensors or providers
              or Third-Party Service Providers are provided ‘as is’ and without
              any warranties or conditions (express or implied, including the
              implied warranties of merchantability, accuracy, fitness for a
              particular purpose, title and non-infringement, arising by statute
              or otherwise in law or from a course of dealing or usage or
              trade). FMA does not provide or make any representations,
              warranties or guarantees, express or implied about the Website or
              the Services. FMA to the fullest extent permitted by applicable
              law(s), disclaims all liability arising out of the Other Parties’
              use or reliance upon the Website, the Services, the FMA Content,
              Third-Party Content, representations and warranties made by the
              Other Parties on the Website or any loss arising out of the manner
              in which the Services have been rendered.
            </li>
            <li>
              The Website may be linked to the website of third parties and Tall
              Tree has no control over, and not liable or responsible for
              content, accuracy, validity, reliability, quality of such websites
              or made available by/through the Website. Inclusion of any link on
              the Website does not imply that FMA endorses the linked website.
              Other Parties may use these links services at their own risk.
            </li>
            <li>
              FMA shall not be responsible for the mishaps/missed services due
              to no service/no show from the Other Parties; FMA shall not be
              responsible for any error in any of the services being provided by
              the Third-Party Service Providers.
            </li>
            <li>
              The listing of Third-Party Service Providers on the Website is
              based on numerous factors including Users comments and feedback.
              In no event shall the Indemnified Parties (as defined herein
              below) be liable or responsible for the listing order of
              Third-Party Service Providers on the Website.
            </li>
            <li>
              To the maximum extent permitted by applicable law(s), FMA, its
              affiliates, independent contractors, service providers,
              consultants, licensors, agents, and representatives, and each of
              their respective directors, officers or employees (“Indemnified
              Parties ”), shall not be liable for any direct, indirect, special,
              incidental, punitive, exemplary or consequential damages, or any
              other damages of any kind, arising from, or directly or indirectly
              related to, (i) the use of, or the inability to use, the Website
              or the content, materials and functions related thereto; (ii)
              User's provision of information via the Website; even if such
              Protected Entity has been advised of the possibility of such
              damages.
            </li>
            <li>
              In no event shall the Indemnified Parties be liable for, or in
              connection with, (i) the provision of, or failure to provide, all
              or any products or service by a Third-Party Service Provider to
              any User; or (ii) any comments or feedback given by any of the
              Users; or (ii) any content posted, transmitted, exchanged or
              received by or on behalf of any User, Third-Party Service
              Providers or other person on or through the Website.
            </li>
            <li>
              All liabilities arising out of any wrong diagnosis of medical
              condition by the Medical Experts and/ or arising from the
              e-prescription will be of the concerned Medical Expert. Further,
              all liabilities arising out of any wrong diagnosis report by the
              Third-Party Diagnostic Labs and Radiology Clinics and/ or arising
              from the wrong dispensation of the Pharmaceutical Goods and
              Services will be of the concerned Third-Party Diagnostic Labs and
              Radiology Clinics or the Third-Party Medicine Vendor as the case
              may be.
            </li>
            <li>
              The Users may share their previous medical history during
              interaction with the Medical Experts FMA reserves the right to
              retain such information for the purpose of providing Services to
              the Users.
            </li>
            <li>
              With respect to the Consultation Services, after selection of the
              type of treatment viz. Homeopathy, Allopathy or Ayurveda along
              with the specification of the disease by the patient, FMA will
              decide the Medical Expert to whom the query should be directed
              based on the information shared by the User. However, in no event
              the Indemnified Parties shall be held liable for the losses
              attributable to such decision making and in no event shall the
              Indemnified Parties be liable for any Consultation provided and/or
              e-prescription issued by the Medical Expert by using the interface
              of online medical consultancy.
            </li>
            <li>
              The Users acknowledge that the Indemnified Parties merely act in
              the capacity of facilitators between the Other Parties by
              providing a platform for them to interact and transact. In no
              event shall the Indemnified Parties be held liable for any of the
              losses attributable to Services offered through the Website.
            </li>
            <li>
              In no event shall the total aggregate liability of the Indemnified
              Parties to any Other Parties for all damages, losses, and causes
              of action (whether in contract or tort, including, but not limited
              to negligence, strict liability, product liability or otherwise)
              arising from these Terms of Use or any Other Parties’ use of the
              Website exceed an aggregate amount of USD 1000/- (Indian Rupees
              One Thousand only). FMA accepts no liability for any errors or
              omissions on behalf of the Other Parties.
            </li>
            <li>
              In no event shall the Indemnified Parties be liable for failure on
              the part of the Users or Third-Party Service Providers to provide
              agreed services or to make himself/herself available at the
              appointed time, cancellation or rescheduling of appointments. In
              no event shall the Indemnified Parties be liable for any comments
              or feedback given by any of the Users in relation to the services
              provided by a Third-Party Service Providers.
            </li>
          </ol>
          <li>INDEMNITY</li>
          <ol>
            <li>
              You agree to defend, indemnify and hold harmless FMA, the
              Indemnified Parties , independent contractors, service providers,
              consultants, licensors, agents, and representatives, and each of
              their respective directors, officers and employees, from and
              against any and all claims, losses, liability, damages, and/or
              costs (including, but not limited to, reasonable attorney fees and
              costs) arising from or related to (a) Covenanters access to or use
              of Website; (b) Covenanters violation of these Terms of Use or any
              applicable law(s); (c) Covenanters violation of any rights of
              another person/ entity, including infringement of their
              intellectual property rights; or (d) Covenanters conduct in
              connection with the Website.
            </li>
          </ol>
          <li>MODIFICATION OF WEBSITE</li>
          <ol>
            <li>
              FMA reserves the right to modify or discontinue, temporarily or
              permanently, the Website or any features or portions thereof
              without prior notice. Other Parties agree that FMA will not be
              liable for any modification, suspension or discontinuance of the
              Website or any other part thereof.
            </li>
          </ol>
          <li>INTELLECTUAL PROPERTY RIGHTS</li>
          <ol>
            <li>
              All the intellectual property used on the Website except those
              which have been identified as the intellectual properties of the
              Other Parties shall remain the exclusive property of the Company.
              The content on the Website or otherwise may not be modified,
              copied, reproduced, distributed, republished, downloaded,
              displayed, sold, compiled, posted or transmitted in any form or by
              any means.
            </li>
          </ol>
          <li>COMPLIANCE OF APPLICABLE LAW</li>
          <ol>
            <li>
              While communicating/ transacting with each other through the
              Website, the Other Parties shall at all times ensure full
              compliance with the provisions of the applicable laws.
            </li>
            <li>
              The Users must also ensure that the prescription uploaded on the
              Website or emailed to FMA for processing the order for
              Prescription Drugs is a valid prescription duly obtained from a
              registered medical practitioner. The Users acknowledge and accept
              that they shall bear all costs/ liability/ damages, caused to the
              Third-Party Service Providers or to FMA, as a result of any
              dispensation of Prescription Drugs by the Third-Party Service
              Providers owing to the non-compliance by the User in this regard.
            </li>
          </ol>
          <li>TERMINATION</li>
          <ol>
            <li>
              Parties for the Purpose of these Terms of Use shall collectively
              mean the Other Parties and FMA
            </li>
            <li>
              The provisions of these Terms of Use shall continue to apply until
              terminated by either of the Party as set for below:
            </li>
            <li>
              In case of Other Parties wanting to terminate these Terms of Use,
              Other Parties may do so by closing their FMA accounts for all of
              the Services that they use.
            </li>
            <li>
              FMA reserves the right to, either partly or fully, at any time,
              and with or without notice, terminate these Terms of Use against
              each of the Users or the Third-Party Service Providers or the
              Other Parties as a whole, if there is:
            </li>
            <ol className="romanList">
              <li>
                Breach or intended breach any of applicable law(s), or the
                provisions of these Terms of Use or the terms of the Privacy
                Policy or any other terms, conditions, or policies that may be
                applicable to the Other Parties from time to time; or
              </li>
              <li>
                FMA is unable to verify or authenticate any information provided
                to FMA by Other Party(s); or
              </li>
              <li>
                FMA believes, in its sole discretion, that Other Party(s)
                actions may cause legal liability for FMA (or any of its
                affiliates, independent contractors, service providers,
                consultants, licensors, agents, and representatives); or
              </li>
              <li>Requirement to do so by law; or</li>
              <li>
                If Other Party(s) fail to provide (or revoke) the consents
                necessary or desirable for FMA to provide the Services; or
              </li>
              <li>
                Provision of the Services is in FMA’s opinion, no longer
                commercially viable; or
              </li>
              <li>
                FMA decides to discontinue, in part or in full, with or without
                reason, access to the Website or the Services.
              </li>
            </ol>
            <li>
              FMA may also terminate or suspend (temporarily or permanently) all
              or a portion of Other Party(s) account or access to the Services,
              with or without reason. Such termination or suspension of Other
              Party(s) account may include: (i) removal of access to all
              offerings within the Website or Services; and (ii) barring from
              further use or access of the Website or Services.
            </li>
            <li>
              Once terminated or suspended (temporarily or permanently), Other
              Party(s) may not use the Website under the same account, a
              different account or re-register under a new account.
            </li>
            <li>
              Upon any termination of these Terms of Use, FMA may, unless
              prohibited by law, delete all of Other Party(s) data in its
              systems or otherwise in its possession or under its control,
              including but not limited to Other Party(s) personal information,
              log-in ID and password, order details (including any prescriptions
              uploaded) and all related information.
            </li>
            <li>
              The right to terminate/ suspend is in addition to, and without
              prejudice to, FMA’s right to initiate appropriate legal action
              against the Other Party(s), in accordance with applicable law.
            </li>
          </ol>
          <li>FORCE MAJEURE</li>
          <ol className="romanList">
            <li>
              Neither Party shall be liable for any loss or damage caused as a
              result of delay or default or deficiency or failure in the
              Services as a result of any natural disasters, fire, riots, civil
              disturbances, actions or decrees of governmental bodies,
              communication line failures (which are not caused due to the fault
              of a Party), or any other delay or default or deficiency or
              failure which arises from causes beyond a Party’s reasonable
              control (“Force Majeure Event”). In the event of any Force Majeure
              Event arising, a Party, depending on whose performance has been
              impacted under the Terms of Use, shall immediately give notice to
              the Other Party(s) of the facts which constitute the Force Majeure
              Event.
            </li>
          </ol>
          <li>GOVERNING LAW AND DISPUTE RESOLUTION</li>
          <ol className="romanList">
            <li>
              These Terms of Use and any contractual obligation between the
              Parties will be governed by the laws of India, without reference
              to the conflict of laws principles. Any legal action or proceeding
              related to Other Party(s) access to, or use of, the Website or
              these Terms of Use shall be subject to the exclusive jurisdiction
              of the courts at Hyderabad. All disputes will be subject to
              arbitration at Hyderabad in English by a sole arbitrator appointed
              by FMA under the Arbitration and Conciliation Act, 1996.
            </li>
          </ol>
          <li>SURVIVAL</li>
          <ol className="romanList">
            <li>
              Even after termination, obligations of a continuous nature, such
              as, but not limited to, mentioned under Covenants, Liability,
              Indemnity, Intellectual Property, Dispute Resolution will continue
              and survive termination.
            </li>
          </ol>
          <li>SEVERABILITY</li>
          <ol className="romanList">
            <li>
              If any provision of these Terms of Use is deemed invalid,
              unlawful, void or for any other reason unenforceable, then that
              provision shall be deemed severable from these Terms of Use and
              shall not affect the validity and enforceability of any of the
              remaining provisions.
            </li>
          </ol>
          <li>WAIVER</li>
          <ol className="romanList">
            <li>
              . No provision of these Terms of Use shall be deemed to be waived
              and no breach excused, unless such waiver or consent shall be in
              writing and signed by FMA. Any consent by FMA to, or a waiver by
              FMA of any breach by Other Parties, whether expressed or implied,
              shall not constitute consent to, waiver of, or excuse for any
              other different or subsequent breach.
            </li>
          </ol>
          <li>HEADINGS</li>
          <ol className="romanList">
            <li>
              The headings and subheadings herein are included for convenience
              and identification only and are not intended to describe,
              interpret, define or limit the scope, extent or intent of these
              Terms of Use.
            </li>
          </ol>
          <li>CONTACT INFORMATION</li>
          <ol className="romanList">
            <li>
              If any Other Party(s) has any grievance, comment, question or
              suggestion regarding any of our Services, please contact our
              customer service at info@Rahainsure.com. If any Other Party(s) has
              any questions concerning FMA, the Website, these Terms of Use, or
              anything related to any of the foregoing, FMA can be reached at
              the following email address info@Rahainsure.com.
            </li>
          </ol>
          <li>LISTING OF THIRD-PARTY SERVICE PROVIDERS</li>
          <ol>
            <li>
              FMA’s algorithm for listing of Third-Party Service Providers is a
              fully automated system that lists the Third-Party Service
              Providers, their profile and information regarding their Practice
              on its Website. These listings of Third-Party Service Providers do
              not represent any fixed objective ranking or endorsement by Tall
              Tree. FMA will not be liable for any change in the relevance of
              the Third-Party Service Providers on search results, which may
              take place from time to time. The listing of Third-Party Service
              Providers will be based on automated computation of the various
              factors including inputs made by the Users including their
              comments and feedback. Such factors may change from time to time,
              in order to improve the algorithm. FMA in no event will be held
              responsible for the accuracy and the relevancy of the listing
              order of the Third-Party Service Providers on the Website
            </li>
            <li>
              FMA collects, directly or indirectly, and displays on the Website,
              relevant information regarding he Third-Party Service Providers
              listed on the Website, such as their professional degree,
              specialization, qualification, fees, location, visiting hours, and
              other details. FMA takes reasonable efforts to ensure that such
              information is updated at frequent intervals. Although FMA screens
              and vets the information submitted by the Third-Party Service
              Providers, it is not liable for any inaccuracies or incompleteness
              represented from it, despite such reasonable efforts.
            </li>
            <li>
              The Services provided by FMA are provided on an "as is" and “as
              available’ basis, and without any warranties or conditions
              (express or implied, including the implied warranties of
              merchantability, accuracy, fitness for a particular purpose, title
              and non-infringement, arising by statute or otherwise in law or
              from a course of dealing or usage or trade). FMA does not provide
              or make any representation, warranty or guarantee, express or
              implied about the Website or the Services. FMA does not guarantee
              the accuracy or completeness of any content or information
              provided by Users on the Website. To the fullest extent permitted
              by law, FMA disclaims all liability arising out of the User’s use
              or reliance upon the Website, the Services, representations and
              warranties made by other Users, the content or information
              provided by the Users on the Website, or any opinion or suggestion
              given or expressed by FMA or any User in relation to any User or
              services provided by such User.
            </li>
            <li>
              The Website may be linked to the website of third parties,
              affiliates and business partners. FMA has no control over, and not
              liable or responsible for content, accuracy, validity,
              reliability, quality of such websites or made available by/through
              our Website. Inclusion of any link on the Website does not imply
              that FMA endorses the linked site. User may use the links and
              these services at User’s own risk.
            </li>
            <li>
              FMA assumes no responsibility, and shall not be liable for, any
              damages to, or viruses that may infect User’s equipment on account
              of User’s access to, use of, or browsing the Website or the
              downloading of any material, data, text, images, video content, or
              audio content from the Website. If a User is dissatisfied with the
              Website, User’s sole remedy is to discontinue using the Website.
            </li>
            <li>
              If FMA determines that you have provided fraudulent, inaccurate,
              or incomplete information, including through feedback, FMA
              reserves the right to immediately suspend your access to the
              Website or any of your accounts with FMA and makes such
              declaration on the website alongside your name/your clinic’s name
              as determined by FMA for the protection of its business and in the
              interests of Users. You shall be liable to indemnify Tall Tree for
              any losses incurred as a result of your misrepresentations or
              fraudulent feedback that has adversely affected FMA or its Users.
            </li>
            <li>
              The User shall be bound by the jurisdiction as contained in these
              Terms and Conditions hereunder, at all times, irrespective of the
              location they may be consulting with Practitioners online.
            </li>
            <li>
              The User shall indemnify and hold harmless FMA and its affiliates,
              subsidiaries, directors, officers, employees and agents from and
              against any and all claims, proceedings, penalties, damages, loss,
              liability, actions, costs and expenses (including but not limited
              to court fees and attorney fees) arising due to or in relation to
              the use of Website by the User, by breach of the Terms or
              violation of any law, rules or regulations by the User, or due to
              such other actions, omissions or commissions of the User that gave
              rise to the claim.
            </li>
            <li>
              If User decides to use the payment gateway to make payments
              online, it is solely at User's discretion. Should there be any
              issues with regard to the payment not reaching the respective
              Practitioner’s account, please reach out to info@Rahainsure.com
            </li>
          </ol>
          <li>
            INFORMATION ON MEDICINES, MEDICINAL PRODUCTS AND OTHER PRODUCTS /
            SERVICES
          </li>
          <p>
            The Website also provides an information source on medicines,
            medicinal products and other products services and concentrates on
            providing understanding including but not limited to the elements
            used in such medicines and/or medicinal products, usage of such
            products / services, indications thereof, alternatives thereto, the
            side effects that may be caused or arise owing to its use, symptoms
            and associated medicines/medicinal products generally administered
            in such cases.
          </p>
          <p>
            This feature is specifically governed by the following terms and
            conditions
          </p>
          <ol>
            <li>For Users:</li>
            <ol className="smallLetterList">
              <li>
                Any information provided under this feature is not intended to
                serve as a substitute for clinical advice and should not be
                relied upon while making treatment related decisions. You are
                hereby notified that the medicines and/or medicinal products
                related information differs from country to country and varies
                from region to region and hence the information/content
                published herein is India specific and neither FMA nor the
                content owner is responsible for the applicability of the
                content outside India. FMA provides information here on an ‘as
                is’, basis and not with an intention to replace consultation
                with a qualified medical practitioner.
              </li>
              <li>
                FMA has used commercially reasonable efforts to source the
                information from reliable sources, however, the information
                contained in this feature is brought from, amongst others, open
                source platforms and third-party sites / reports. FMA shall not
                be responsible or in any manner be liable for the accuracy of
                the information or for any errors, omissions or inaccuracies
                contained in such information irrespective of the reason or
                cause of such occurrence, or for any consequences arising from
                it FMA advocates or rejects the use of a particular medicine
                and/or medicinal product listed herein.
              </li>
              <li>
                You understand that the information on medicines and/or
                medicinal products contained herein does not constitute an offer
                or invitation or advise to use the same. The information on
                medicines and/or medicinal products provided hereunder should
                not be relied upon in connection with any decision to
                self-medicate. It is suggestible that you always consult a
                medical practitioner at all times prior to relying upon any
                information set out herein.
              </li>
              <li>
                Protection against the issue of prescription by Dr based out of
                India to International customer/patient.
              </li>
            </ol>
            <p className="paragraphTitle">
              Prescribe in the best interest of the patient:{" "}
            </p>
            <p>
              Prescribe medicines only when in their professional judgement it
              is in the patient's best interests to receive the medicine being
              requested and, accordingly, to refuse to prescribe medicines when
              they believe it is not in the patient's best interests to receive
              medicines without the benefit of a face to face consultation.
            </p>
            <p className="paragraphTitle">Comply with instructions: </p>
            <p>
              Read carefully, each message uploaded into your secure online
              patient record before taking any medicines that our clinicians
              have prescribed and to comply with any instructions given for each
              medicine. If you are uncertain as to how to use a testing kit or
              take any medicine we have provided to you, you will contact us and
              not use the kit or medicine until you have sufficient information
              from us and understand the correct instructions.
            </p>
            <p>
              The e-consultation and prescription written by registered doctors
              and the medicines are dispensed by a partner pharmacy who are
              based in INDIA within INDIA. FMA may pass your order to registered
              doctors for review supported by the medical questionnaire which
              you confirm to have filled in truthfully. Tall Tree cannot be held
              liable for the accuracy of the diagnosis, treatment or medication
              prescribed.
            </p>
            <li>For Practitioners:</li>
            <ol className="smallLetterList">
              <li>
                Practitioners agree and acknowledge that FMA does not make any
                representation or warranty and does not guarantee the accuracy
                and/or legitimacy of any information or content provided vide
                the feature mentioned herein.
              </li>
              <li>
                The feature herein acts as an information source only and not as
                a database of medical prescription, and thus, does not warrant
                or confirm the validity, accuracy, completeness, safety,
                usefulness, reliability, legality, quality and/ or the
                applicability thereof. The Practitioners understand that any
                information provided under this feature is therefore not
                intended to serve as a substitute for clinical judgement and
                agrees to use his/her discretion while relying upon the same for
                treatment related advice, decisions or otherwise.
              </li>
              <li>
                FMA does not represent or warrant that the information provided
                herein will meet with the requirements or expectations of the
                Practitioner and that any content, material or results that may
                be obtained from use of information provided under the said
                feature will be accurate, timely, complete, reliable, genuine
                and that any errors with regard to the same will be correct at
                all times.
              </li>
            </ol>
            <li>Disclaimer:</li>
            <ol className="smallLetterList">
              <li>
                The content/information provided herein, regarding medicine
                and/or medicinal products including but not limited to dietary
                supplements etc., have not been evaluated or approved by the
                Drug Controller of India or any other statutory body.
              </li>
              <li>
                THE INFORMATION PROVIDED UNDER THIS FEATURE IS BROUGHT TO THE
                VIEWERS ON AN, "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
                WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. FMA EXPRESSLY
                DISCLAIMS ALL WARRANTEES OF ANY KIND, WHETHER EXPRESSED OR
                IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION,
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, NON-INFRINGEMENT, SECURITY, ACCURACY OR COMPLETENESS OF
                THE INFORMATION PROVIDED HEREIN. FMA ALSO EXPRESSLY DISCLAIMS
                ANY AND ALL LIABILITY TO ANY PERSON WHOSOEVER IN RESPECT OF ANY
                LOSS, DAMAGE, DEATH, PERSONAL INJURY OR OTHER CONSEQUENCES
                WHATSOEVER, HOWSOEVER CAUSED OR ARISING HEREUNDER, BY THEIR USE
                OF OR RELIANCE UPON, IN ANY WAY, TO THE INFORMATION CONTAINED
                HEREIN.
              </li>
              <li>
                No warranties: FMA website and/or mobile applications are
                provided “as is” without any representations or warranties,
                express or implied. The FMA makes no representations or
                warranties in relation to FMA website and/or mobile applications
                or the information and materials provided on Tall Tree website
                and/or mobile applications.
              </li>
            </ol>
            <p>
              Without prejudice to the generality of the foregoing paragraph,
              FMA does not warrant that:
            </p>
            <ol>
              <li>
                FMA website and/or mobile applications will be constantly
                available, or available at all; or that FMA services are fit for
                a particular purpose, title, non-infringement, and free from
                computer/mobile device virus or other harmful code; or
              </li>
              <li>
                The information on FMA website and/or mobile applications is
                complete, true, accurate or non-misleading.
              </li>
            </ol>
            <p>
              Nothing on FMA website and/or mobile applications constitutes, or
              is meant to constitute, advice of any kind.
            </p>
            <p>
              The FMA also does not warrant that FMA services will be
              operational, error free, secure, or safe, or that FMA services
              will function without disruptions, delays, or imperfections. The
              FMA does not control, and is not responsible for, controlling how
              or when FMA users use Tall Tree services or the features,
              services, and interfaces FMA services provide. The FMA is not
              responsible for and is not obligated to control the actions or
              information (including content) of FMA users or other third
              parties.
            </p>
            <li>Limitation of Liability.</li>
            <p>
              In no event, shall FMA be liable to you for any lost or corrupted
              data, downtime, lost profits, business interruption, replacement
              service or other special, incidental, consequential, punitive or
              indirect damages, however caused and regardless of theory of
              liability, including without limitation negligence and in no event
              shall the total aggregate liability of FMA, for all claims arising
              out of or under these terms and conditions, exceed USD 1000
              (Indian Rupees One Thousand only).
            </p>
            <li>Change or modification:</li>
            <p>
              Any reference to any medicines and/or medicinal products thereof,
              is subject to change/modification depending upon the applicable
              laws/technical/medical updates. These, changes or improvements to
              such medicines and/or medicinal products may be made at any time
              without notice.
            </p>
            <li>Hold harmless:</li>
            <ol className="smallLetterList">
              <li>
                The use of the feature herein and the content is at the viewer’s
                sole risk and consequence. FMA shall not be responsible for the
                results of viewer’s decisions resulting from the use of this
                feature.
              </li>
              <li>
                The viewers/Users/Practitioners hereby release and agree to hold
                harmless FMA, its affiliates/group companies, its directors,
                officers, employees, agents, successors, advisors, consultants,
                representatives, and assigns from any and all claims, losses,
                related suits, actions, proceedings, investigations, judgments,
                deficiencies, damages, settlements, liabilities, reasonable
                legal fees and expenses incurred, by the
                viewers/Users/Practitioners arising due to the negligence or
                misconduct of viewers/Users/Practitioners or due to the access
                of or decisions taken on the information provided herein.
              </li>
            </ol>
            <li>Third-Party Links:</li>
            <p>
              FMA may provide links to Third-Party websites or external
              websites/internal servers or resources including advertisements by
              others. FMA has no control over such sites and the information
              provided in them. FMA does not recommend and does not endorse the
              content on /of any Third-Party websites including that of
              advertisers. FMA is not responsible or liable for the content of
              these external sites nor does it endorse, warrant or guarantee the
              accuracy, genuineness, reliability offered or advertised on the
              products, services or information provided by such Third-Party
              websites.
            </p>
            <li>Payment:</li>
            <p>
              The prices mentioned with respect to the medicines/drugs are for
              indicative purposes.
            </p>
          </ol>
          <li>PRIVACY POLICY</li>
          <p>
            We insist upon the highest standards for secure transactions and
            customer information privacy. Please read the following statement to
            learn about our information gathering and dissemination practices.
          </p>
          <p>
            By mere use of the Website, you expressly consent to our use and
            disclosure of your personal information in accordance with this
            Privacy Policy. This Privacy Policy is incorporated into and subject
            to the Terms of Use.
          </p>
          <ol>
            <li>
              Collection of Personally Identifiable Information and other
              Information
            </li>
            <p>
              When you use FMA Platform, we collect and store your personal
              information which is provided by you from time to time. Our
              primary goal in doing so is to provide you a safe, efficient,
              smooth and customized experience. This allows us to provide
              services and features that most likely meet your needs, and to
              customize our FMA Platform to make your experience safer and
              easier. More importantly, while doing so we collect personal
              information from you that we consider necessary for achieving this
              purpose.
            </p>
            <p>
              In general, you can browse the Website without telling us who you
              are or revealing any personal information about you. Once you give
              us your personal information, you are not anonymous to us. Where
              possible, we indicate which fields are required and which fields
              are optional. You always have the option to not provide
              information by choosing not to use a particular service or feature
              on the FMA Platform. You always have the option to withdraw the
              consent, we have the option not to provide services for which the
              said information was sought. We may automatically track certain
              information about you based upon your behaviour on our Website. We
              use this information to do internal research on our users’
              demographics, interests, and behaviour to better understand,
              protect and serve you. This information is compiled and analysed
              on an aggregated basis. This information may include the URL that
              you just came from (whether this URL is on our Website or not),
              which URL you next go to (whether this URL is on our Website or
              not), your computer browser information, and your IP address.
            </p>
            <p>
              If you transact with us, we collect some additional information,
              such as a billing address, a credit / debit card number and a
              credit / debit card expiration date and/ or other payment
              instrument details and tracking information from cheques or money
              orders.
            </p>
            <p>
              If you choose to post messages on our message boards, chat rooms
              or other message areas or leave feedback, we will collect that
              information you provide to us. We retain this information as
              necessary to resolve disputes, provide customer support and
              troubleshoot problems as permitted by law.
            </p>
            <p>
              If you send us personal correspondence, such as emails or letters,
              or if other Users or third parties send us correspondence about
              your activities or postings on the Website, we may collect such
              information into a file specific to you.
            </p>
            <p>
              We collect personally identifiable information (email address,
              name, phone number, credit card / debit card / other payment
              instrument details, etc.) from you when you register and create an
              account with us. We use your contact information to send you
              offers based on your previous orders and interests.
            </p>
            <li>Use of Demographic / Profile Data / User Information</li>
            <p>
              We use personal information to provide the services you request.
              To the extent we use your personal information to market to you,
              we will provide to you the ability to opt-out of such uses. We use
              your personal information to resolve disputes; troubleshoot
              problems; help promote a safe service; collect money; measure
              consumer interest in our products and services, inform you about
              online and offline offers, products, services, and updates;
              customize user experience; detect and protect you against error,
              fraud and other criminal activity; enforce our terms and
              conditions; and as otherwise described to you at the time of
              collection.
            </p>
            <p>
              In our efforts to continually improve our product and service
              offerings, we collect and analyse demographic and profile data
              about our Users’ activity on our Website.
            </p>
            <p>
              We maintain standard call and IP logs that record data about all
              Users who call us and/or access our Website. We store this
              information for providing services and as an analysis tool. All
              call logs are stored securely, and may only be accessed by our
              employees or designees on a need-to-know basis for a specific
              purpose. We use call log information to help provide our members
              the efficient services under this membership. We collect IP
              address of all visitors to the Website.
            </p>
            <p>
              We identify and use your IP address to help diagnose problems with
              our server, and to administer our Website. Your IP address is also
              used to help identify you and to gather broad demographic
              information.
            </p>
            <p>
              We will occasionally ask you to complete optional online surveys.
              These surveys may ask you for contact information and demographic
              information (like pin code, age, or income level). We use this
              data to tailor your experience at our Website, providing you with
              content that we think you might be interested in and to display
              content according to your preferences.
            </p>
            <p className="paragraphTitle">Cookies</p>
            <p>
              A “cookie” is a small piece of information stored by a web server
              on a web browser so it can be later read back from that browser.
              Cookies are useful for enabling the browser to remember
              information specific to a given User. We place both permanent and
              temporary cookies in your computer’s hard drive. The cookies do
              not contain any of your personally identifiable information.
            </p>
          </ol>
        </ol>
      </div>
    </div>
  );
};

export default Terms;
