import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./myprofile.module.scss";
import { BankContext } from "../../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { CopyBtn, EachCustomProfileDropdown } from "../../../components/Custom";

import { ReactComponent as CompleteIcon } from "../../../assest/images/icons/complete.svg";
import { ReactComponent as TrashcanIcon } from "../../../assest/images/icons/trashcan.svg";

//assets icon
import { ReactComponent as PropertyIcon } from "../../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../../assest/images/profile/world.svg";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavbarV2 from "../../../components/NavbarV2";
import NavbarV3 from "../../../components/NavbarV3";
import { logoutToSameLogin } from "../../../assest/functions";

const MyProfileEmployee = () => {
  const history = useHistory();
  const { name, email, updateLoading, setUpdateLoading } =
    useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] = useState(
    "My Employee Profile"
  );
  const [selectedDiffSection, setSelectedDiffSection] =
    useState("Personal Details");
  const [mobileAdding, setMobileAdding] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileFound, setIsProfileFound] = useState(false);

  useEffect(() => {
    setUpdateLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    )
      .then((response) => {
        console.log(response?.data?.data, "user profile response");
        if (response?.data?.status && response?.data?.data) {
          setIsProfileFound(response?.data?.data);
        } else {
          setIsProfileFound(false);
        }
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
        setUpdateLoading(false);
        // setAllInsuranceCompaniesLoading(false);
      });
  }, []);

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      {updateLoading ? (
        ""
      ) : isProfileFound ? (
        <>
          <NavbarV3
            dropdownItems={[]}
            selectedTab={selectedProfileNav}
            setSelectedTab={setSelectedProfileNav}
            setSearchQuery={setSearchQuery}
          />

          <div className={classNames.myProfileDetails}>
            <div className={classNames.mainDiv}>
              <div className={classNames.detailedItem}>
                <div>
                  <img
                    src={
                      isProfileFound?.userDetails?.profile_picture
                        ? isProfileFound?.userDetails?.profile_picture
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                    }
                    alt="brokerage_icon"
                  />
                </div>
                <div>
                  <div>
                    {isProfileFound?.userDetails?.first_name
                      ? isProfileFound?.userDetails?.first_name
                      : ""}
                  </div>
                  <div>Personal Profile: 0.00% Completed</div>
                </div>
              </div>
              <div className={classNames.diffSections}>
                <div
                  className={
                    selectedDiffSection === "Personal Details"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Personal Details");
                  }}
                >
                  Personal Details
                </div>
                <div
                  className={
                    selectedDiffSection === "Policy Configurations"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    // setSelectedDiffSection("Policy Configurations");
                  }}
                >
                  Policy Configurations
                </div>
                <div
                  className={
                    selectedDiffSection === "Family Members"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    // setSelectedDiffSection("Family Members");
                  }}
                >
                  Family Members
                </div>
              </div>
            </div>
            <div className={classNames.selectedSectionMain}>
              {selectedDiffSection === "Personal Details" ? (
                <div className={classNames.personalDetails}>
                  <EachProfileInput
                    title="Full Name"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.userDetails?.first_name &&
                      isProfileFound?.userDetails?.last_name
                        ? isProfileFound?.userDetails?.first_name +
                          " " +
                          isProfileFound?.userDetails?.last_name
                        : isProfileFound?.userDetails?.first_name
                        ? isProfileFound?.userDetails?.first_name
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Gender"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.userDetails?.gender
                        ? isProfileFound?.userDetails?.gender
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Email"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.userDetails?.user_email
                        ? isProfileFound?.userDetails?.user_email
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Date Of Birth (DD/MM/YYYY)"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.userDetails?.date_of_birth
                        ? isProfileFound?.userDetails?.date_of_birth
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Mobile Number"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.userDetails?.primary_phone_number
                        ? isProfileFound?.userDetails?.primary_phone_number
                        : ""
                    }
                  />
                  {/* <EachProfileInput
                    title="Annual Income"
                    placeholder="Select annual income..."
                    dropdown="true"
                    stateValue="Less then 1Lahk"
                  /> */}
                  {/* <EachProfileInput
                    title="Martial Status"
                    placeholder="Select marriage status..."
                    dropdown="true"
                    stateValue={
                      isProfileFound?.martial_status
                        ? isProfileFound?.martial_status
                        : ""
                    }
                  /> */}
                  <EachProfileInput
                    title="Employee ID"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.employeeDetails?.length > 0
                        ? isProfileFound?.employeeDetails[0]?.user_employee_id
                          ? isProfileFound?.employeeDetails[0]?.user_employee_id
                          : ""
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Designation"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.employeeDetails?.length > 0
                        ? isProfileFound?.employeeDetails[0]?.role
                          ? isProfileFound?.employeeDetails[0]?.role
                          : ""
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="CTC"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFound?.employeeDetails?.length > 0
                        ? isProfileFound?.employeeDetails[0]?.CTC
                          ? isProfileFound?.employeeDetails[0]?.CTC
                          : ""
                        : ""
                    }
                  />
                </div>
              ) : selectedDiffSection === "Contact Details" ? (
                <div className={classNames.contactDetails}>
                  <div>
                    <div className={classNames.title}>Mobile Numbers</div>
                    <EachProfileInput
                      title="Mobile Number"
                      placeholder="Enter number..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.primary_phone_number
                          ? isProfileFound?.primary_phone_number
                          : ""
                      }
                      status="primary"
                    />
                    {/* {Array.from({ length: mobileAdding }).map((_, index) => (
                      <EachProfileInput
                        title="Mobile Number"
                        placeholder="Enter number..."
                        dropdown=""
                        stateValue="+91 52351 12345"
                        status="secondary"
                        removeState={setMobileAdding}
                      />
                    ))} */}
                    {isProfileFound?.secondary_phone_numbers
                      ? isProfileFound?.secondary_phone_numbers?.map(
                          (number, index) => (
                            <EachProfileInput
                              title="Mobile Number"
                              placeholder="Enter number..."
                              dropdown=""
                              stateValue={number ? number : ""}
                              status="secondary"
                              removeState={setMobileAdding}
                            />
                          )
                        )
                      : ""}
                    <div
                      className={classNames.addNewBtn}
                      onClick={() => {
                        setMobileAdding((prev) => prev + 1);
                      }}
                    >
                      <div className={classNames.icon}>+</div>
                      <span>Add new number</span>
                    </div>
                  </div>
                  <div>
                    <div className={classNames.title}>Email Addresses</div>
                    <EachProfileInput
                      title="Email Address"
                      placeholder="Enter email..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.user_email
                          ? isProfileFound?.user_email
                          : ""
                      }
                      status="primary"
                    />
                    {/* {Array.from({ length: emailAdding }).map((_, index) => (
                      <EachProfileInput
                        title="Email Address"
                        placeholder="Enter email..."
                        dropdown=""
                        stateValue="shorupan@gmail.com"
                        status="secondary"
                        removeState={setEmailAdding}
                      />
                    ))} */}
                    {/* <div
                      className={classNames.addNewBtn}
                      onClick={() => {
                        setEmailAdding((prev) => prev + 1);
                      }}
                    >
                      <div className={classNames.icon}>+</div>
                      <span>Add new email</span>
                    </div> */}
                  </div>
                </div>
              ) : (
                <div className={classNames.assetsDetails}>
                  <div className={classNames.title}>Add</div>
                  <div className={classNames.assetsContainer}>
                    <div className={classNames.eachAsset}>
                      <div>Property</div>
                      <div>
                        <PropertyIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div className={classNames.eachAsset}>
                      <div>Vehicles</div>
                      <div>
                        <VehicleIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div className={classNames.eachAsset}>
                      <div>Other</div>
                      <div>
                        <WorldIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classNames.noProfile}>
          <div>
            <div>Looks Like You Haven’t Completed</div>
            <div>Updating Your FMA Profile</div>
          </div>
          <div>No Problem. It Only Takes A Few Minutes.</div>
          <Link to="/app/rahax/newprofile">Complete Registration</Link>
        </div>
      )}
    </div>
  );
};

export default MyProfileEmployee;

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  pic,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: isOpen ? "250px" : "" }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          padding: isOpen ? "0.6rem 1.75rem" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            <img
              src={
                pic
                  ? pic
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
              }
              alt=""
            />
            <span>
              {selectedOption
                ? selectedOption
                : dropdown?.length > 0
                ? dropdown[0]
                : "Shorupan P"}
            </span>
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title === "selectprofile"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          // setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        <img
                          src={
                            pic
                              ? pic
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                          }
                          alt=""
                        />
                        {eachitem}
                        {selectedOption == eachitem && <CompleteIcon />}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"All Countries"}
              >
                All Countries
              </li>
            ) : (
              ""
            )}
            <li className={classNames.addBtn}>Add Family Member</li>
          </ul>
        )}
      </div>
    </div>
  );
};

const EachProfileInput = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <input
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
        />
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};
