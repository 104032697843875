import React, { useContext, useRef, useState } from "react";
import classNames from "./employeeoverview.module.scss";
import { BankContext } from "../../../context/Context";

import { ReactComponent as PhoneIcon } from "../../../assest/images/icons/phone.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/icons/lock.svg";

import kpPic from "../../../assest/images/draft/kp.svg";

//sidebar

import cultfitLogo from "../../../assest/images/coverfi/proactive/cultfit.svg";
import dialLogo from "../../../assest/images/coverfi/proactive/dial.svg";
import callhealthLogo from "../../../assest/images/coverfi/proactive/callhealth.svg";

import { ReactComponent as AmbulanceIcon } from "../../../assest/images/coverfi/proactive/icons/ambulance.svg";

import { LoadingAnimationRaha } from "../../../components/Custom";

import { useEffect } from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import NavbarV3 from "../../../components/NavbarV3";
import { useHistory } from "react-router-dom";
import { predefinedToast } from "../../../utils/toast";
import fullLogo from "../../../assest/images/fullLogo.svg";
import GetQuoteEmployer from "../../GetQuote/getquotestartemployer";
import { EachCustomDropdown } from "../../../components/Custom/Input";
import {
  base64ToBlob,
  downloadFile,
  logoutToSameLogin,
} from "../../../assest/functions";
import { capitalizeFirstLetter } from "../../../utils/FunctionTools";
import truepeo from "../../../assest/images/rahax/truepeo.svg";
import talltreesupport from "../../../assest/images/rahax/talltreesupport.svg";
import parasupport from "../../../assest/images/rahax/para.svg";
import useWindowDimensions from "../../../utils/WindowSize";
import MobileNavbar from "../../../components/MobileNavbar";
import fullLogoWhite from "../../../assest/images/fullLogoWhite.svg";

ChartJS.register(ArcElement, Tooltip, Legend);

const EmployeeOverview = () => {
  const history = useHistory();
  const {
    email,
    globalHrId,
    setGlobalHrId,
    setPopularAction,
    supportDataGlobal,
    masterEmployeeID,
    policiesApiGlobal,
    setPoliciesApiGlobal,
    usersDataGlobal,
    onLoginFormEmployerProfile,
    profileDetailsAssociatesClone,
  } = useContext(BankContext);
  const { width } = useWindowDimensions();
  const [selectedProfileNav, setSelectedProfileNav] = useState("Dashboard");
  const [searchQuery, setSearchQuery] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPolicyEmployee, setSelectedPolicyEmployee] = useState("");
  const [associateDetails, setAssociateDetails] = useState("");
  const [associateDetailsLoading, setAssociateDetailsLoading] = useState(true);
  const [enteredPieCategory, setEnteredPieCategory] = useState("");
  const [totalCdBalance, setTotalCdBalance] = useState("");
  const [employeeStats, setEmployeeStats] = useState("");
  const [activateWalletLoading, setActivateWalletLoading] = useState(false);
  const [claimsApi, setClaimsApi] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState(0);
  const [selectedPolicySumDropdown, setSelectedPolicySumDropdown] = useState(0);
  const [userPolicyTypes, setUserPolicyTypes] = useState("");
  const [selectedPolicySum, setSelectedPolicySum] = useState(0);

  //get support data
  const [salesAssociatesData, setSalesAssociatesData] = useState("");
  const [salesTeamleaderData, setSalesTeamleaderData] = useState("");

  async function getSupportData() {
    await supportDataGlobal.forEach((eachItem, index) => {
      if (
        eachItem?.role_id?.role_type === "Sales" &&
        eachItem?.role_id?.role_designation === "Associates" &&
        eachItem?.user_id
      ) {
        setSalesAssociatesData(eachItem?.user_id);
      } else if (
        eachItem?.role_id?.role_type === "Sales" &&
        eachItem?.role_id?.role_designation === "Team Leader" &&
        eachItem?.user_id
      ) {
        setSalesTeamleaderData(eachItem?.user_id);
      }
    });
  }

  useEffect(() => {
    if (Array.isArray(supportDataGlobal) && supportDataGlobal?.length > 0) {
      getSupportData();
    } else {
      setSalesAssociatesData("");
      setSalesTeamleaderData("");
    }
  }, [supportDataGlobal]);

  function getUserId() {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuser?user_email=${email}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          localStorage?.setItem(
            "globalUserIdRaha",
            response?.data?.data[0]?.user_id
          );
          let globalHRID = await getGlobalHrId(
            response?.data?.data[0]?.user_id
          );
        }
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
      });
  }

  function getGlobalHrId(userid) {
    if (userid) {
      Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get?user_id=${userid}`
      )
        .then((response) => {
          console.log(response?.data, "Global HR ID response", userid);
          if (response?.data?.status && response?.data?.data?.length > 0) {
            // console.log(response?.data?.data[0]?.hr_id, "hr id response");
            setGlobalHrId(response?.data?.data[0]?.hr_id);
            localStorage.setItem(
              "globalUserHrIdRaha",
              response?.data?.data[0]?.hr_id
            );
          } else {
          }
        })
        .catch((error) => {
          console.log(error?.message, "user profile error");
          // setAllInsuranceCompaniesLoading(false);
        });
    }
  }

  async function getTotalCDBalance() {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/get?hr_id=${globalHrId}`
    );

    let res = response?.data?.data;

    if (response?.data?.status) {
      setTotalCdBalance(res[0]);
    } else {
      setTotalCdBalance("activatewallet");
    }

    console.log(response, "get total CD balance response");
  }

  async function getEmployeeStats() {
    let response;
    if (globalHrId) {
      response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get/count?hr_id=${globalHrId}`
      );
    } else {
      await getUserId();
      response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/hr/get/count?hr_id=${globalHrId}`
      );
    }

    let res = response?.data?.data;

    setEmployeeStats(res);
    console.log(res, "getEmployeeStats response");
  }

  function getAssociatesDetails() {
    setAssociateDetailsLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/get?user_email=${email}`
    )
      .then((response) => {
        console.log(response?.data?.data, "associateDetails API");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setAssociateDetails(response?.data?.data[0]);
        }
        setAssociateDetailsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "associateDetails error");
        setAssociateDetailsLoading(false);
      });
  }

  function getClaimsApi() {
    Axios.get(
      `https://coverfinew.apimachine.com/employeeproduct/get?employee_email=${email}`
    )
      .then((response) => {
        console.log(response, "all policies response");
        if (
          // response?.data?.message === "hr_id is not registered in hr profile"
          !response?.data?.status
        ) {
          setClaimsApi("emptyPolicyBox");
        } else if (response?.data?.data?.length > 0) {
          setClaimsApi(response?.data?.data);
          let policyTypes = [];
          if (
            Array.isArray(response?.data?.data) &&
            response?.data?.data?.length > 0 &&
            response?.data?.data[0]?.policies?.length > 0
          ) {
            response?.data?.data[0]?.policies.forEach((eachPolicy) => {
              policyTypes.push({
                type:
                  eachPolicy?.policy_type + " - " + eachPolicy?.policy_number,
                sum: eachPolicy?.sum_insured
                  ? eachPolicy?.sum_insured
                  : eachPolicy?.policy_id?.sum_insured?.length > 0
                  ? eachPolicy?.policy_id?.sum_insured[0]
                  : eachPolicy?.policy_id?.sum_insured,
              });
            });
            setUserPolicyTypes(policyTypes);
          }
        } else {
          setClaimsApi("");
        }
      })
      .catch((error) => {
        console.log(error?.message, "claims API employer proactive error");
        setClaimsApi("emptyPolicyBox");
        // setAllInsuranceCompaniesLoading(false);
      });
  }

  async function downloadHealthCardEmployee(tpaID, policyNum) {
    let policyNumber = policyNum?.replace(/\//g, "%2F");
    let response = await Axios.get(
      `https://coverfi.apimachine.com/api/v1/tpa/getEcard/${tpaID}/${encodeURIComponent(
        policyNumber
      )}/${encodeURIComponent(masterEmployeeID)}`
    );
    if (response?.data?.status && response?.data?.data?.length > 0) {
      // window.open(response?.data?.data[0].ecard_url, "_blank");
      //new method start
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0 &&
        response?.data?.data[0]?.type == "browser"
      ) {
        window?.open(
          response?.data?.data[0]?.ecard_url
            ? response?.data?.data[0]?.ecard_url
            : response?.data?.data[0]["e-card"],
          "_blank"
        );
      } else if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0 &&
        response?.data?.data[0]?.type == "base64"
      ) {
        const blob = base64ToBlob(
          response?.data?.data[0]["e-card"],
          "application/pdf"
        );
        downloadFile(blob);
      }
      //new method end
    } else {
      predefinedToast(response?.data?.message);
    }
    // console.log(response, "download health card employee app");
  }

  //renderings

  useEffect(() => {
    if (globalHrId) {
      getTotalCDBalance();
      getEmployeeStats();
    } else {
      setClaimsApi("emptyPolicyBox");
    }
    getAssociatesDetails();
  }, [globalHrId]);

  useEffect(() => {
    if (email) {
      getClaimsApi();
    }
  }, [email]);

  function onCloseSidebar() {
    if (sidebarOpen) {
      setSidebarOpen((prev) => !prev);
    }
  }

  const handleScrollButtonClick = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");

    // Scroll the div back to the top
    scrollableDiv.scrollTop = 0;
  };

  //tempstates

  const [speaktolead, setSpeakToLead] = useState(false);
  const [speaktoAssociate, setSpeakToAssociate] = useState(false);
  const [speaktoCustomerService, setSpeakToCustomerService] = useState(false);
  const [speaktoAshley, setSpeakToAshley] = useState(false);
  const [speaktoRussell, setSpeakToRussell] = useState(false);
  const [speaktoParaServicio, setSpeakToParaServicio] = useState(false);
  const [speaktoParaAshley, setSpeakToParaAshley] = useState(false);

  function calculateDaysRemaining(validTillDate, daysRemain) {
    // Parse the "validTillDate" string in dd-mm-yyyy format
    const [day, month, year] = validTillDate?.split("-");
    const validTill = new Date(`${year}-${month}-${day}`);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in days
    const timeDifference = validTill - currentDate;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysRemain) {
      return daysRemaining;
    }

    return {
      datasets: [
        {
          data: [365 - daysRemaining, daysRemaining], // The values for your data points
          backgroundColor: ["var(--opacity-main)", "#17A1FA21"], // Colors for each data point
          hoverBackgroundColor: ["var(--opacity-main)", "#17A1FA21"],
        },
      ],
    };
  }

  const donutOptionsPolicies = {
    cutout: "80%", // Set the cutout as a percentage to make the donut circle smaller
    plugins: {
      legend: {
        display: true,
        // position: "right",
      },
    },
    elements: {
      arc: {
        spacing: 0, // Set spacing to 0 to remove the gap between donut segments
      },
    },
  };

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      {width > 768 ? (
        <NavbarV3
          dropdownItems={["Dashboard"]}
          selectedTab={selectedProfileNav}
          setSelectedTab={setSelectedProfileNav}
          setSearchQuery={setSearchQuery}
        />
      ) : (
        <>
          <MobileNavbar />
          <div className={classNames.welcomeContainer}>
            <div>Welcome Back,</div>
            <div>
              {usersDataGlobal?.userDetails?.first_name &&
                capitalizeFirstLetter(usersDataGlobal?.userDetails?.first_name)}
            </div>
          </div>
        </>
      )}
      {width < 768 ? (
        <MobileOverview
          claimsApi={claimsApi}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          setSpeakToAssociate={setSpeakToAssociate}
          setSpeakToLead={setSpeakToLead}
        />
      ) : activateWalletLoading ? (
        <div className={classNames.walletLoading}>
          <div className={classNames.walletLoadingBox}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Your CD Balance Has Been Activated
            </div>
          </div>
        </div>
      ) : associateDetailsLoading ? (
        <LoadingAnimationRaha />
      ) : (
        <div
          className={classNames.proactiveDetails}
          style={{ overflowY: sidebarOpen ? "hidden" : "" }}
          id="scrollableDiv"
        >
          <SidebarMenu
            position={sidebarOpen ? "0" : ""}
            onClose={onCloseSidebar}
            openedItem={sidebarOpen}
            selectedItemMain={selectedPolicyEmployee}
          />

          <div className={classNames.policiesDiv}>
            <div className={classNames.header}>
              <div className={classNames.miniCards}>
                <div className={classNames.title}>Contact Information</div>
                <div className={classNames.box}>
                  <div className={classNames.associateProfile}>
                    <img src={fullLogo} alt="profilePic" />
                  </div>
                  <div className={classNames.agentDetails}>
                    <div className={classNames.name}>For English</div>
                    <div
                      className={classNames.agentBtn}
                      style={{ marginTop: "0.15rem" }}
                      onClick={() => setSpeakToCustomerService((prev) => !prev)}
                    >
                      <PhoneIcon />
                      {speaktoCustomerService
                        ? "(877) 453-4201"
                        : "Customer Service"}
                    </div>
                    <div
                      className={classNames.agentBtn}
                      style={{ marginTop: "0.15rem" }}
                      onClick={() => setSpeakToAshley((prev) => !prev)}
                    >
                      <PhoneIcon />
                      {speaktoAshley ? "(704) 200-8666" : "Customer Service 1"}
                    </div>
                    <div
                      className={classNames.agentBtn}
                      style={{ marginTop: "0.15rem" }}
                      onClick={() => setSpeakToRussell((prev) => !prev)}
                    >
                      <PhoneIcon />
                      {speaktoRussell ? "(203) 984-0520" : "Customer Service 2"}
                    </div>
                  </div>
                </div>
                <div className={classNames.box}>
                  <div className={classNames.associateProfile}>
                    <img
                      src={parasupport}
                      alt="kpPic"
                      style={{ border: "none" }}
                    />
                  </div>
                  <div className={classNames.agentDetails}>
                    <div className={classNames.name}>Para el español</div>

                    <div
                      className={classNames.agentBtn}
                      style={{ marginTop: "0.15rem" }}
                      onClick={() => setSpeakToParaServicio((prev) => !prev)}
                    >
                      <PhoneIcon />
                      {speaktoParaServicio
                        ? "(877) 453-4201"
                        : "Servicio al Cliente"}
                    </div>
                    <div
                      className={classNames.agentBtn}
                      style={{ marginTop: "0.15rem" }}
                      onClick={() => setSpeakToParaAshley((prev) => !prev)}
                    >
                      <PhoneIcon />
                      {speaktoParaAshley ? "(704) 200-8666" : "Customer Service 1"}
                    </div>
                  </div>
                </div>
                {/* {salesAssociatesData && (
                  <div className={classNames.box}>
                    <div className={classNames.associateProfile}>
                      {salesAssociatesData?.user_picture ? (
                        <img
                          src={
                            salesAssociatesData?.user_picture
                              ? salesAssociatesData?.user_picture
                              : ""
                          }
                          alt="profilePic"
                        />
                      ) : (
                        <div className={classNames.profileLetter}>
                          {salesAssociatesData?.user_first_name
                            ? salesAssociatesData?.user_first_name?.charAt(0)
                            : "R"}
                        </div>
                      )}
                    </div>
                    <div className={classNames.agentDetails}>
                      <div className={classNames.name}>
                        {salesAssociatesData?.user_first_name
                          ? salesAssociatesData?.user_first_name
                          : ""}
                      </div>
                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "0.15rem" }}
                        onClick={() => setSpeakToAssociate((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktoAssociate &&
                        salesAssociatesData?.user_phone_number?.length > 0
                          ? salesAssociatesData?.user_phone_number[0]
                          : "Speak To Associate"}
                      </div>
                    </div>
                  </div>
                )}
                {salesTeamleaderData && (
                  <div className={classNames.box}>
                    <div className={classNames.associateProfile}>
                      {salesTeamleaderData?.user_picture ? (
                        <img
                          src={
                            salesTeamleaderData?.user_picture
                              ? salesTeamleaderData?.user_picture
                              : ""
                          }
                          alt="kpPic"
                          style={{ border: "none" }}
                        />
                      ) : (
                        <div className={classNames.profileLetter}>
                          {salesTeamleaderData?.user_first_name
                            ? salesTeamleaderData?.user_first_name?.charAt(0)
                            : "R"}
                        </div>
                      )}
                    </div>
                    <div className={classNames.agentDetails}>
                      <div className={classNames.name}>
                        {salesTeamleaderData?.user_first_name
                          ? salesTeamleaderData?.user_first_name
                          : ""}
                      </div>

                      <div
                        className={classNames.agentBtn}
                        style={{ marginTop: "0.15rem" }}
                        onClick={() => setSpeakToLead((prev) => !prev)}
                      >
                        <PhoneIcon />
                        {speaktolead &&
                        salesTeamleaderData?.user_phone_number?.length > 0
                          ? salesTeamleaderData?.user_phone_number[0]
                          : "Speak To Team Lead"}
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className={classNames.otherDashContainer}>
              <div
                style={{
                  borderRadius: claimsApi === "startQuote" ? "35px" : "",
                  overflow: claimsApi === "startQuote" ? "hidden" : "",
                }}
              >
                <div className={classNames.title}>
                  <div>
                    Hello{" "}
                    <span>
                      {window?.location?.pathname?.includes("/agency") ||
                      window?.location?.pathname?.includes("/teamlead") ||
                      window?.location?.pathname?.includes("/verticalhead") ||
                      window?.location?.pathname?.includes("/businesshead") ||
                      window?.location?.pathname?.includes("/affiliates")
                        ? capitalizeFirstLetter(
                            profileDetailsAssociatesClone?.user_first_name
                          )
                        : window?.location?.pathname?.includes("/coverfi")
                        ? capitalizeFirstLetter(
                            onLoginFormEmployerProfile?.hrDetails?.first_name
                          )
                        : capitalizeFirstLetter(
                            usersDataGlobal?.userDetails?.first_name
                          )}
                      ,
                    </span>
                  </div>
                  {/* {Array.isArray(claimsApi) && claimsApi?.length > 0 && (
                    <div>Active Policies</div>
                  )} */}
                </div>
                {
                  // claimsApi === "emptyPolicyBox" ? (
                  //   <div className={classNames.emptyPoliciesDiv}>
                  //     <div>
                  //       Looks like you are new. Lets finish you’re onboarding
                  //       process
                  //     </div>
                  //     <div className={classNames.btnsContainer}>
                  //       <button
                  //         onClick={() => {
                  //           setClaimsApi("startQuote");
                  //         }}
                  //       >
                  //         New Quote
                  //       </button>
                  //       <button
                  //         onClick={() => {
                  //           setSelectedProfileNav("Quotes");
                  //         }}
                  //       >
                  //         Existing Quotes
                  //       </button>
                  //     </div>
                  //   </div>
                  // ) : claimsApi === "startQuote" ? (
                  //   <GetQuoteEmployer
                  //     setSelectedProfileNav={setSelectedProfileNav}
                  //   />
                  // ) :

                  Array.isArray(claimsApi) && claimsApi?.length > 0
                    ? claimsApi?.map((eachPolicy) => {
                        return (
                          <div
                            className={`${classNames.eachPolicy} ${
                              selectedPolicy == "0"
                                ? classNames.eachPolicySelected
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedPolicy(0);
                            }}
                          >
                            <div className={classNames.headDiv}>
                              <div className={classNames.imageDiv}>
                                <img
                                  src={
                                    Array.isArray(eachPolicy?.productDetails) &&
                                    eachPolicy?.productDetails?.length > 0 &&
                                    eachPolicy?.productDetails[0]?.company_icon
                                      ? eachPolicy?.productDetails[0]
                                          ?.company_icon
                                      : ""
                                  }
                                  alt="iciciIcon"
                                />
                              </div>
                              <div className={classNames.companyDetails}>
                                <div className={classNames.title}>
                                  {Array.isArray(eachPolicy?.productDetails) &&
                                  eachPolicy?.productDetails?.length > 0 &&
                                  eachPolicy?.productDetails[0]?.name
                                    ? eachPolicy?.productDetails[0]?.name
                                    : ""}
                                </div>
                                <div className={classNames.company}>
                                  Product By :{" "}
                                  <span style={{ fontWeight: "600" }}>
                                    {Array.isArray(
                                      eachPolicy?.productDetails
                                    ) &&
                                    eachPolicy?.productDetails?.length > 0 &&
                                    eachPolicy?.productDetails[0]?.provider
                                      ? eachPolicy?.productDetails[0]?.provider
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div className={classNames.donutBox}>
                                {/* <Doughnut
                                  data={calculateDaysRemaining(
                                    eachPolicy?.ssn_coverage_start_date
                                  )}
                                  options={donutOptionsPolicies}
                                /> */}
                                <div className={classNames.totalPerc}>
                                  <div>
                                    {/* {calculateDaysRemaining(
                                      eachPolicy?.ssn_coverage_start_date,
                                      "daysRemain"
                                    )}{" "}
                                    Days */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={classNames.desc}
                              style={{ paddingBottom: "2rem" }}
                            >
                              {Array.isArray(eachPolicy?.productDetails) &&
                                eachPolicy?.productDetails?.length > 0 &&
                                eachPolicy?.productDetails[0]?.description}
                            </div>
                            {/* <div
                              className={classNames.desc}
                              style={{
                                paddingTop: "0",
                                display: "flex",
                                gap: "3rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                }}
                              >
                                <div>
                                  Issuance Date:{" "}
                                  {eachPolicy?.coverage_issuance_date
                                    ? eachPolicy?.coverage_issuance_date
                                    : "Processing"}
                                </div>
                                <div>
                                  Category:{" "}
                                  {eachPolicy?.product_category
                                    ? eachPolicy?.product_category
                                    : "Processing"}
                                </div>
                              </div>
                              
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                }}
                              >
                                <div>
                                  Start Date:{" "}
                                  {eachPolicy?.ssn_coverage_start_date
                                    ? eachPolicy?.ssn_coverage_start_date
                                    : "Processing"}
                                </div>
                                <div>
                                  Plan Type:
                                  {eachPolicy?.plan_type
                                    ? eachPolicy?.plan_type
                                    : "Processing"}
                                </div>
                              </div>
                            </div> */}
                            <div className={classNames.moreDetails}>
                              {/* <div className={classNames.coveragesDiv}>
                                <div className={classNames.moreDetailsTitle}>
                                  Coverage Amount
                                </div>
                                <div className={classNames.allPolicyVarients}>
                                  <div className={classNames.eachVarient}>
                                    <div>
                                      {eachPolicy?.coverage_amount
                                        ? eachPolicy?.coverage_amount
                                        : "Processing"}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div> */}

                              <div
                                style={{
                                  display: "flex",
                                  opacity: "0.5",
                                  pointerEvents: "none",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    className={classNames.btn}
                                    onClick={() => {
                                      if (
                                        eachPolicy?.link?.includes("https://")
                                      ) {
                                        window.open(eachPolicy?.link, "_blank");
                                      } else {
                                        window.open(
                                          "https://" + eachPolicy?.link,
                                          "_blank"
                                        );
                                      }
                                    }}
                                  >
                                    ID Card
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    className={classNames.btn}
                                    onClick={() => {
                                      // setSelectedPolicyEmployee(eachPolicy);
                                      // setSidebarOpen("detailedPolicy");
                                      // handleScrollButtonClick();
                                    }}
                                  >
                                    See Terms
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""
                }
              </div>
              {/* <div className={classNames.employeeGraph}>
                <div className={classNames.hiddenGraph}>
                  <div className={classNames.title}>Claims</div>
                  <div className={classNames.lockedDiv}>
                    <div className={classNames.opacityDiv}></div>
                    <LockIcon />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeOverview;

const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
  setSpeakToAssociate,
}) => {
  const sidebarRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const allItem = [
    { name: "Cult", image: cultfitLogo },
    { name: "Dial 4242", image: dialLogo },
    { name: "CallHealth", image: callhealthLogo },
  ];

  console.log(
    selectedItemMain?.policy_id?.terms_and_conditions,
    "terms & conditions"
  );

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      {openedItem == "detailedPolicy" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "var(--main-color)" }}
          >
            {/* <div onClick={() => setSelectedItem("")}>Back</div> */}
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <div className={classNames.eachPolicy}>
                <div className={classNames.headDiv}>
                  <div className={classNames.imageDiv}>
                    <img
                      src={
                        selectedItemMain?.policy_id?.insurance_company_id
                          ?.insurance_company_logo_url
                          ? selectedItemMain?.policy_id?.insurance_company_id
                              ?.insurance_company_logo_url
                          : ""
                      }
                      alt="iciciIcon"
                    />
                  </div>
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {selectedItemMain?.policy_id?.policy_type
                        ? selectedItemMain?.policy_id?.policy_type
                        : ""}
                    </div>
                    <div className={classNames.company}>
                      Product By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {selectedItemMain?.policy_id?.insurance_company_id
                          ?.insurance_company_display_name
                          ? selectedItemMain?.policy_id?.insurance_company_id
                              ?.insurance_company_display_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {selectedItemMain?.policy_id?.description}
                </div>
                <div className={classNames.desc} style={{ paddingTop: "0" }}>
                  Policy Valid Until: {selectedItemMain?.policy_id?.end_date}
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>Coverage (USD)</div>
                    <div className={classNames.allPolicyVarients}>
                      <div className={classNames.eachVarient}>
                        <div>
                          {selectedItemMain?.policy_grade
                            ? selectedItemMain?.policy_grade
                            : ""}
                        </div>
                        <div>
                          {Array.isArray(selectedItemMain?.sum_insured) &&
                          selectedItemMain?.sum_insured?.length > 0
                            ? selectedItemMain?.sum_insured[0]
                            : selectedItemMain?.sum_insured
                            ? selectedItemMain?.sum_insured
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.desc} style={{ margin: "0rem" }}>
              <div className={classNames.searchDiv}>
                <input
                  type="text"
                  placeholder="Search The Terms In Your Policy..."
                  onChange={(event) => setSearchQuery(event?.target?.value)}
                />
              </div>
              <div className={classNames.allDesc}>
                {selectedItemMain?.policy_id?.terms_and_conditions?.length > 0
                  ? selectedItemMain?.policy_id?.terms_and_conditions
                      ?.filter((eachItem) => {
                        return eachItem?.termDescription?.includes(searchQuery);
                      })
                      ?.map((eachItem, index) => {
                        return (
                          <div
                            key={
                              "termDescription" + eachItem?.termNumber + index
                            }
                          >
                            <div>{eachItem?.termNumber}</div>
                            <div>{eachItem?.termDescription}</div>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : openedItem == "detailedConfiguration" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "var(--main-color)" }}
          >
            {/* <div onClick={() => setSelectedItem("")}>Back</div> */}
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <div className={classNames.eachPolicy}>
                <div className={classNames.headDiv}>
                  <div className={classNames.imageDiv}>
                    <img
                      src={
                        selectedItemMain?.insurance_company_id
                          ?.insurance_company_logo_url
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_logo_url
                          : ""
                      }
                      alt="iciciIcon"
                    />
                  </div>
                  <div className={classNames.companyDetails}>
                    <div className={classNames.title}>
                      {selectedItemMain?.policy_type
                        ? selectedItemMain?.policy_type
                        : ""}
                    </div>
                    <div className={classNames.company}>
                      Product By :{" "}
                      <span style={{ fontWeight: "600" }}>
                        {selectedItemMain?.insurance_company_id
                          ?.insurance_company_name
                          ? selectedItemMain?.insurance_company_id
                              ?.insurance_company_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.desc}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  {selectedItemMain?.description}
                </div>
                <div className={classNames.desc} style={{ paddingTop: "0" }}>
                  Policy Valid Until:{" "}
                  {selectedItemMain?.end_date ? selectedItemMain?.end_date : ""}
                </div>
                <div className={classNames.moreDetails}>
                  <div className={classNames.amount}>
                    <div>Coverage (USD)</div>
                    <div>
                      {selectedItemMain?.sum_insured?.length > 0 &&
                        selectedItemMain?.sum_insured[0]}
                    </div>
                  </div>
                  <div
                    className={classNames.btn}
                    onClick={() => {
                      window.open(selectedItemMain?.policy_pdf_link, "_blank");
                    }}
                  >
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.desc} style={{ margin: "0rem" }}>
              <div className={classNames.detail}>
                <div>Type:</div>
                <div>
                  {selectedItemMain?.policy_variant
                    ? selectedItemMain?.policy_variant
                    : "-"}
                </div>
              </div>
              <div className={classNames.detail}>
                <div>Covered Persons:</div>
                <div>
                  {selectedItemMain?.covered_members
                    ? selectedItemMain?.covered_members
                    : "-"}
                </div>
              </div>

              <div className={classNames.sumTable}>
                {selectedItemMain?.policy_variant == "Graded" ? (
                  selectedItemMain?.sum_insured?.map((eachItem, index) => {
                    return (
                      <div
                        className={classNames.eachCell}
                        key={eachItem + index + "Grade"}
                      >
                        <div>{"Grade " + (index + 1)}</div>
                        <div>{eachItem}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className={classNames.eachCell}>
                    <div>Flat</div>
                    <div>
                      {selectedItemMain?.sum_insured?.length > 0
                        ? selectedItemMain?.sum_insured[0]
                        : "-"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : selectedItem == "Dial 4242" ? (
        <div className={classNames.eachItemDetailed}>
          <div
            className={classNames.emptyCard}
            style={{ background: "#04BFDA" }}
          >
            <div onClick={() => setSelectedItem("")}>Back</div>
          </div>
          <div className={classNames.allDetails}>
            <div className={classNames.mainDetail}>
              <img src={dialLogo} alt="dialLogo" />
              <div>
                <div>Dial 4242</div>
                <div>Ambulance Service Provider</div>
              </div>
            </div>
            <div className={classNames.desc}>
              <div className={classNames.title}>About</div>
              <div className={classNames.para}>
                DIAL4242, India's biggest app based ambulance network is built
                to deliver the best in emergency care to people across the
                nation through its ambulance services that is not only limited
                to emergencies, but can be used by a patient to schedule
                check-ups & appointments or simply to get home after being
                discharged from hospital. The mobile app connects users to
                emergency facilities by providing timely ambulance service when
                required. With just one click or a call, a patient can have our
                associated ambulances at the location of their choice.
              </div>
            </div>
            <div className={classNames.offerings}>
              <div className={classNames.title}>Offerings</div>
              <div className={classNames.allOfferings}>
                <div className={classNames.eachOffering}>
                  <div style={{ background: "#04BFDA40" }}>
                    <AmbulanceIcon />
                  </div>
                  <div>
                    <div>
                      <div className={classNames.title}>
                        Employee Ambulance Assurance Plan
                      </div>
                      <div className={classNames.learnBtn}>Learn More</div>
                    </div>
                    <div className={classNames.priceDiv}>
                      <div>
                        $149
                        <span>$205</span>
                      </div>
                      <div className={classNames.btn}>ADD</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames.allWellnessItems}>
          <div className={classNames.title}>Create Your Wellness Cart</div>
          <div className={classNames.allItems}>
            {allItem?.map(({ name, image }, index) => {
              return (
                <div
                  className={classNames.eachWellnessItem}
                  key={name}
                  onClick={() => {
                    setSelectedItem(name);
                  }}
                >
                  <img src={image} alt={name} />
                  <div>{name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const MobileOverview = ({
  claimsApi,
  selectedPolicy,
  setSelectedPolicy,
  setSpeakToAssociate,
  setSpeakToLead,
}) => {
  const [speaktoCustomerService, setSpeakToCustomerService] = useState(false);
  const [speaktoAshley, setSpeakToAshley] = useState(false);
  const [speaktoRussell, setSpeakToRussell] = useState(false);
  const [speaktoParaServicio, setSpeakToParaServicio] = useState(false);
  const [speaktoParaAshley, setSpeakToParaAshley] = useState(false);

  return (
    <div className={classNames.mobileOverview}>
      <div className={classNames.title} style={{ color: "white" }}>
        Your Products
      </div>
      <div className={classNames.productsContainer}>
        {Array.isArray(claimsApi) && claimsApi?.length > 0
          ? claimsApi?.map((eachPolicy) => {
              return (
                <div
                  className={`${classNames.eachPolicy} ${
                    selectedPolicy == "0" ? classNames.eachPolicySelected : ""
                  }`}
                  onClick={() => {
                    setSelectedPolicy(0);
                  }}
                >
                  <div className={classNames.headDiv}>
                    <div className={classNames.imageDiv}>
                      <img
                        src={
                          Array.isArray(eachPolicy?.productDetails) &&
                          eachPolicy?.productDetails?.length > 0 &&
                          eachPolicy?.productDetails[0]?.company_icon
                            ? eachPolicy?.productDetails[0]?.company_icon
                            : ""
                        }
                        alt="iciciIcon"
                      />
                    </div>
                    <div className={classNames.companyDetails}>
                      <div className={classNames.title}>
                        {Array.isArray(eachPolicy?.productDetails) &&
                        eachPolicy?.productDetails?.length > 0 &&
                        eachPolicy?.productDetails[0]?.name
                          ? eachPolicy?.productDetails[0]?.name
                          : ""}
                      </div>
                      <div className={classNames.company}>
                        Product By :{" "}
                        <span style={{ fontWeight: "600" }}>
                          {Array.isArray(eachPolicy?.productDetails) &&
                          eachPolicy?.productDetails?.length > 0 &&
                          eachPolicy?.productDetails[0]?.provider
                            ? eachPolicy?.productDetails[0]?.provider
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className={classNames.donutBox}>
                      <div className={classNames.totalPerc}>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames.desc}
                    style={{ paddingBottom: "2rem" }}
                  >
                    {Array.isArray(eachPolicy?.productDetails) &&
                      eachPolicy?.productDetails?.length > 0 &&
                      eachPolicy?.productDetails[0]?.description}
                  </div>
                  <div className={classNames.moreDetails}>
                    {/* <div className={classNames.coveragesDiv}>
                      <div className={classNames.moreDetailsTitle}>
                        Coverage Amount
                      </div>
                      <div className={classNames.allPolicyVarients}>
                        <div className={classNames.eachVarient}>
                          <div>
                            {eachPolicy?.coverage_amount
                              ? eachPolicy?.coverage_amount
                              : "Processing"}
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        opacity: "0.5",
                        pointerEvents: "none",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={classNames.btn}
                          onClick={() => {
                            if (eachPolicy?.link?.includes("https://")) {
                              window.open(eachPolicy?.link, "_blank");
                            } else {
                              window.open(
                                "https://" + eachPolicy?.link,
                                "_blank"
                              );
                            }
                          }}
                        >
                          ID Card
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={classNames.btn}
                          onClick={() => {
                            // setSelectedPolicyEmployee(eachPolicy);
                            // setSidebarOpen("detailedPolicy");
                            // handleScrollButtonClick();
                          }}
                        >
                          See Terms
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      <div className={classNames.title}>Contact Information</div>
      <div className={classNames.supportContainer}>
        <div className={classNames.box}>
          <div className={classNames.associateProfile}>
            <img src={fullLogo} alt="profilePic" />
          </div>
          <div className={classNames.agentDetails}>
            <div className={classNames.name}>For English</div>
            <div
              className={classNames.agentBtn}
              style={{ marginTop: "0.15rem" }}
              onClick={() => setSpeakToCustomerService((prev) => !prev)}
            >
              <PhoneIcon />
              {speaktoCustomerService ? "(877) 453-4201" : "Customer Service"}
            </div>
            <div
              className={classNames.agentBtn}
              style={{ marginTop: "0.15rem" }}
              onClick={() => setSpeakToAshley((prev) => !prev)}
            >
              <PhoneIcon />
              {speaktoAshley ? "(704) 200-8666" : "Customer Service 1"}
            </div>
            <div
              className={classNames.agentBtn}
              style={{ marginTop: "0.15rem" }}
              onClick={() => setSpeakToRussell((prev) => !prev)}
            >
              <PhoneIcon />
              {speaktoRussell ? "(203) 984-0520" : "Customer Service 2"}
            </div>
          </div>
        </div>
        <div className={classNames.box}>
          <div className={classNames.associateProfile}>
            <img src={parasupport} alt="kpPic" style={{ border: "none" }} />
          </div>
          <div className={classNames.agentDetails}>
            <div className={classNames.name}>Para el español</div>

            <div
              className={classNames.agentBtn}
              style={{ marginTop: "0.15rem" }}
              onClick={() => setSpeakToParaServicio((prev) => !prev)}
            >
              <PhoneIcon />
              {speaktoParaServicio ? "(877) 453-4201" : "Servicio al Cliente"}
            </div>
            <div
              className={classNames.agentBtn}
              style={{ marginTop: "0.15rem" }}
              onClick={() => setSpeakToParaAshley((prev) => !prev)}
            >
              <PhoneIcon />
              {speaktoParaAshley ? "(704) 200-8666" : "Customer Service 1"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
