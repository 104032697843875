import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./wellnesstabs.module.scss";

//assets
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import {
  allBrands,
  allCompanies,
  allTests,
  mainMenuItems,
  subCategory,
  subMenuItemsLabTests,
  allService,
  wellnessTypeMenu,
} from "../../../assest/data/employer/wellness";
import dummyTest from "../../../assest/images/employer/wellness/dummytest.svg";
import removeItem from "../../../assest/images/icons/removeitem.svg";
import { useHistory } from "react-router-dom";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, { CopyBtn } from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as EditIcon } from "../../../assest/images/allicons/tableaction/edit.svg";
import { ReactComponent as TrashIcon } from "../../../assest/images/allicons/tableaction/trash.svg";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
} from "../../../components/Custom/Input";
import Axios from "axios";
import NoImage from "../../../assest/no-image.jpg";

import InfiniteScroll from "react-infinite-scroll-component";
import { MdLocationOn } from "react-icons/md";
import { predefinedToast } from "../../../utils/toast";
import { addRemoveItemCart } from "../../../assest/functions";
import { FaChevronDown } from "react-icons/fa6";

const Discover = ({ setSelectedDiscoverSection, setSelectedMainTest }) => {
  const history = useHistory();
  const dropdownMenuRef = useRef();
  const { email } = useContext(BankContext);
  //constants
  const allSubTabs = ["Physical Health", "Mental health", "Health Assistance"];
  const [selectedSubTab, setSelectedSubTab] = useState("Physical Health");
  const [dropdownActive, setDropdownActive] = useState(false);
  const [subMenuItemsAdmin, setSubMenuItemsAdmin] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectedMenuItemHover, setSelectedMenuItemHover] = useState("");
  const [selectedMenuItemSub, setSelectedMenuItemSub] = useState("");
  const [selectedMenuItemSubHover, setSelectedMenuItemSubHover] = useState("");

  const [welnessHealthType, setWelnessHealthType] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [service, setService] = useState([]);
  const [countItem, setCountItem] = useState(Array(service.length).fill(0));
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [typeWellness, setTypeWellness] = useState("Services");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState(categoryData);
  const [filteredBrand, setFilteredBrand] = useState(welnessHealthType);
  const [dropdownMyself, setDropdownMyself] = useState(false);
  const [allCartItemsLocal, setAllCartItemsLocal] = useState([]);
  const [localUserIdCart, setLocalUserIdCart] = useState("");
  const [refreshLocal, setRefreshLocal] = useState(false);

  useEffect(() => {
    Axios.get("https://coverfi.apimachine.com/api/v1/wellness-master/category")
      .then((response) => {
        console.log("status", response.data.status);
        if (response?.data?.status === true) {
          console.log("category1", response);
          setCategoryData(response?.data?.data);
          setFilteredCategory(response?.data?.data);
        } else if (response?.data?.status === false) {
          console.log("category2", response);
          setCategoryData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Axios.get(
      "https://coverfi.apimachine.com/api/v1/wellness-master/service?limit=3500&skip=1"
    )
      .then((response) => {
        if (response?.data?.status) {
          console.log("service response", response?.data);
          setService(response?.data?.data);
          setCountItem(Array(response?.data?.data.length).fill(0));
          setItems(response?.data?.data);
          setFilteredServices(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshLocal]);

  const handleIncrementItem = (index) => {
    const newCountItem = [...countItem];
    newCountItem[index]++;
    setCountItem(newCountItem);
  };

  const handleDecrementItem = (index) => {
    const newCountItem = [...countItem];
    if (newCountItem[index] > 0) {
      newCountItem[index]--;
      setCountItem(newCountItem);
    }
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 10 more records in 1.5 secs
    setTimeout(() => {
      const newItems = service.slice(items.length, items.length + 10);
      setItems([...items, ...newItems]);
      const newFilteredServices = service.slice(
        filteredServices.length,
        filteredServices.length + 10
      );
      setFilteredServices([...filteredServices, ...newFilteredServices]);

      // Stop loading when there are no more items to load
      if (items.length >= service.length) {
        setHasMore(false);
      }
      if (filteredServices.length >= service.length) {
        setHasMore(false);
      }
    }, 3500);
  };

  const changeTypeWellness = (eachItem) => {
    setTypeWellness(eachItem?.name);
    setDropdownActive((prev) => !prev);
  };

  const subTabChange = (eachTab) => {
    setSelectedSubTab(eachTab);
    console.log("eachTab", eachTab);
    if (eachTab === "Physical Health") {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/wellness-master/provider?service_categories=${eachTab}`
      )
        .then((response) => {
          // setFilteredBrand(response.data);
          console.log("status", response.data.status);
          if (response?.data?.status === true) {
            console.log("welnessHealthType1", response);
            setWelnessHealthType(response?.data?.data);
            setFilteredBrand(response?.data?.data);
          } else if (response?.data?.status === false) {
            console.log("welnessHealthType2", response);
            setWelnessHealthType([]);
            setFilteredBrand([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setWelnessHealthType([]);
      setFilteredBrand([]);
    }
  };
  useEffect(() => {
    if (selectedSubTab === "Physical Health") {
      subTabChange("Physical Health");
    }
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 0) {
      if (typeWellness === "Services") {
        setFilteredCategory([]);
        setFilteredBrand([]);
      } else if (typeWellness === "Categories") {
        setFilteredServices([]);
        setFilteredBrand([]);
      } else if (typeWellness === "Brands") {
        setFilteredServices([]);
        setFilteredCategory([]);
      }
    } else {
      setFilteredServices(items);
      setFilteredCategory(categoryData);
      setFilteredBrand(welnessHealthType);
    }
    if (typeWellness === "Services") {
      const filtered = items.filter((service) =>
        service.wellness_service_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      setFilteredServices(filtered);
    } else if (typeWellness === "Categories") {
      const filtered = categoryData.filter((category) =>
        category.wellness_category_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      setFilteredCategory(filtered);
    } else if (typeWellness === "Brands") {
      const filtered = welnessHealthType.filter((brand) =>
        brand.wellness_partner_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      setFilteredBrand(filtered);
    }
  };

  let placeholderText = "";
  if (typeWellness === "Services") {
    placeholderText = "Search A Service...";
  } else if (typeWellness === "Categories") {
    placeholderText = "Search A Categories...";
  } else if (typeWellness === "Brands") {
    placeholderText = "Search A Brands...";
  }

  async function getCartUserIdWithHR() {
    try {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      );

      // console.log(response?.data?.data, "Get Cart user ID for logged in HR");
      if (response?.data?.data?.hrDetails?.hrCompanyDetails?._id) {
        getCompanyCartDetails(
          response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        );
        setLocalUserIdCart(
          response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        );
      } else {
        predefinedToast("User ID from getCartUserIdWithHR not found!");
        console.log("User ID from getCartUserIdWithHR not found!");
      }
    } catch (error) {
      console.log(error?.message, "Get Cart user ID for logged in HR error");
    }
  }

  async function getCompanyCartDetails(userID) {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/cart/?user_id=${userID}`
      );
      console.log(response?.data, "Get company cart details");
      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllCartItemsLocal(response?.data?.data[0]);
      }
    } catch (error) {
      console.log(error, "Get company cart details error");
    }
  }

  useEffect(() => {
    if (localUserIdCart) {
      getCompanyCartDetails(localUserIdCart);
    } else {
      getCartUserIdWithHR();
    }
  }, [refreshLocal]);

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.header}>
        <div>
          <div className={classNames.subTabs}>
            {allSubTabs?.map((eachTab, index) => {
              return (
                <div
                  key={eachTab + index}
                  className={
                    selectedSubTab === eachTab
                      ? classNames.selectedTab
                      : classNames.nonSelectedTab
                  }
                  onClick={() => subTabChange(eachTab)}
                >
                  {eachTab}
                </div>
              );
            })}
          </div>
          <div className={classNames.locationFilter}>
            <label htmlFor="">Shopping For</label>
            <div className={classNames.city}>
              Myself
              <div className={classNames.addressPopover}>
                <div className={classNames.location}>
                  <MdLocationOn />
                  <span>Home</span>
                </div>
                <div className={classNames.eachAddressField}>
                  <div>Address</div>
                  <div>40 manjeera purple town villas</div>
                </div>
                <div className={classNames.eachAddressField}>
                  <div>Pincode</div>
                  <div>500019</div>
                </div>
                <div className={classNames.eachAddressField}>
                  <div>City</div>
                  <div>Hyderabad</div>
                </div>
                <div className={classNames.eachAddressField}>
                  <div>State</div>
                  <div>Telangana</div>
                </div>
                <div className={`rahaGreenBtn ${classNames.greenBtn}`}>
                  Edit
                </div>
              </div>
            </div>
            <div className={classNames.svgContainer}>
              <MdEdit
                onClick={() => {
                  setDropdownMyself((prev) => !prev);
                }}
              />
              <div className={classNames.myselfDropdownOpen}>
                <input placeholder="Select one of your dependents" />
                <div className={classNames.myRelate}>
                  <div className={classNames.eachRelate}>
                    <h6>Nitin Kumar | Son</h6>
                    <p>40 manjeera purple town villas, Hyderabad, TL</p>
                  </div>
                  <div className={classNames.eachRelate}>
                    <h6>Nitin Kumar | Son</h6>
                    <p>40 manjeera purple town villas, Hyderabad, TL</p>
                  </div>
                  <div className={classNames.eachRelate}>
                    <h6>Nitin Kumar | Son</h6>
                    <p>40 manjeera purple town villas, Hyderabad, TL</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ alignItems: "flex-start" }}>
          <div className={classNames.filterDiv}>
            <div className={classNames.searchDiv}>
              <div
                className={classNames.searchDropdown}
                onClick={() => {
                  setDropdownActive((prev) => !prev);
                }}
              >
                {typeWellness}{" "}
                {!dropdownActive ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
              <input
                placeholder={placeholderText}
                className={classNames.inputContainer}
                value={searchTerm}
                onChange={handleSearch}
              />
              {dropdownActive && (
                <div
                  className={classNames.menuDropdownWellness}
                  ref={dropdownMenuRef}
                >
                  <div className={classNames.mainMenuWellness}>
                    {wellnessTypeMenu?.map((eachItem, index) => {
                      return (
                        <div
                          className={classNames.eachTypewellness}
                          onClick={() => changeTypeWellness(eachItem)}
                        >
                          {eachItem?.name}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classNames.subMenu}></div>
                </div>
              )}
            </div>
          </div>
          {/* <div className={classNames.addressSelf}>
            40 manjeera purple town villas, Hyderabad, TL, 500019
          </div> */}
        </div>
      </div>
      <div className={classNames.discoverOverflow}>
        {filteredCategory.length > 0 ? (
          <>
            <div className={classNames.title}>Shop by category</div>
            <div className={classNames.allTests}>
              {filteredCategory?.map((eachTest, index) => {
                return (
                  <div
                    className={`${classNames.eachTest}`}
                    onClick={() => {
                      setSelectedDiscoverSection("AllConsultations");
                      setSelectedMainTest((prev) => {
                        return {
                          ...prev,
                          mainTest: eachTest?.wellness_category_name,
                        };
                      });
                    }}
                  >
                    {eachTest?.wellness_category_logo_url.includes(
                      "example"
                    ) ? (
                      <img
                        src={NoImage}
                        alt={eachTest?.wellness_category_name}
                      />
                    ) : (
                      <img
                        src={eachTest?.wellness_category_logo_url}
                        alt={eachTest?.wellness_category_name}
                      />
                    )}

                    <div className={classNames.wellness_category_name}>
                      {eachTest?.wellness_category_name}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}

        {filteredBrand.length > 0 ? (
          <>
            <div className={classNames.buytests}>
              <div className={classNames.title}>Shop by brand</div>
              <div className={classNames.allTestsBuy}>
                {/* <div className={classNames.eachTest}>
            <div className={classNames.testDesc}>
              <img src={dummyTest} alt="dummyTest" />
              <div>
                <div className={classNames.title}>Popular Packages</div>
                <div className={classNames.desc}>
                  Health Shield Comprehensive - Male
                </div>
                <div className={classNames.price}>$ 4999</div>
              </div>
            </div>
            <div className={classNames.btnsContainer}>
              <div>More Info</div>
              <div>Add to Cart</div>
            </div>
          </div> */}

                {/* {allBrands?.map((eachCompany, index) => {
            return (
              <div className={classNames.eachShopByBrand}>
                <img src={eachCompany?.image} alt="headspace" />
                <div className={classNames.brandContent}>
                  <div className={classNames.companyDetails}>
                    <div>{eachCompany?.letter}</div>
                    <div>
                      <div>{eachCompany?.companyName}</div>
                      <div>{eachCompany?.category}</div>
                    </div>
                  </div>
                  <button className={classNames.buyBtn}>Learn More</button>
                </div>
              </div>
            );
          })} */}
                {filteredBrand &&
                  filteredBrand?.map((eachCompany, index) => {
                    return (
                      <div className={classNames.eachShopByBrand} key={index}>
                        {eachCompany?.wellness_partner_images_url.length > 0 ? (
                          <img
                            src={eachCompany?.wellness_partner_images_url}
                            alt="no_img"
                            width="301"
                            height="170"
                          />
                        ) : (
                          <img
                            src={NoImage}
                            alt="no_img"
                            width="301"
                            height="170"
                          />
                        )}
                        <div className={classNames.brandContent}>
                          <div className={classNames.companyDetails}>
                            {eachCompany?.wellness_partner_logo_url ? (
                              <img
                                src={eachCompany?.wellness_partner_logo_url}
                                alt="no_img"
                                width="48"
                                height="170"
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt="no_img"
                                width="48"
                                height="170"
                              />
                            )}
                            <div>
                              <div>{eachCompany?.wellness_partner_name}</div>
                              {/* <div>{eachCompany?.service_categories}</div> */}
                            </div>
                          </div>
                          {/* <button className={classNames.buyBtn}>Learn More</button> */}
                        </div>
                        <p className={classNames.paraDescription}>
                          {eachCompany?.wellness_partner_description}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        ) : null}

        <div className={classNames.getServices}>
          {filteredServices.length > 0 ? (
            <>
              <div className={classNames.title}>Shop by Service</div>
              <div className={classNames.allServicesList}>
                {/* <InfiniteScroll
                className={classNames.infiniteScroll}
                dataLength={filteredServices.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
              > */}
                <div>
                  <div>
                    {filteredServices.map((eachProduct, index) => {
                      return (
                        <div
                          className={classNames.eachOffering}
                          key={"eachcartitem" + index}
                        >
                          <div style={{ padding: "0px" }}>
                            {/* <CheckboxIcon /> */}
                            <img
                              src={
                                eachProduct?.wellness_service_logo_url
                                  ? eachProduct?.wellness_service_logo_url
                                  : eachProduct?.wellness_partner_logo_url
                                  ? eachProduct?.wellness_partner_logo_url
                                  : ""
                              }
                              alt={
                                eachProduct?.wellness_service_name
                                  ? eachProduct?.wellness_service_name
                                  : "eachproductlogo"
                              }
                            />
                          </div>
                          <div>
                            <div>
                              <div className={classNames.title}>
                                {eachProduct?.wellness_service_name}
                                <span>
                                  <FaChevronDown />
                                </span>
                              </div>
                              <div className={classNames.desc}>
                                Provided By:{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {
                                    eachProduct?.wellness_partner_id
                                      ?.wellness_partner_name
                                  }
                                </span>
                              </div>
                            </div>
                            <div className={classNames.priceDiv}>
                              <div>
                                ${" "}
                                {eachProduct?.wellness_service_price
                                  ? (
                                      Number(
                                        eachProduct?.wellness_service_price
                                      ) / 100
                                    )?.toFixed(2)
                                  : "0.00"}
                                {/* <span style={{ textDecoration: "none" }}>Per year</span> */}
                                <span>
                                  {" "}
                                  {eachProduct?.wellness_service_price
                                    ? (
                                        (Number(
                                          eachProduct?.wellness_service_price
                                        ) /
                                          100) *
                                        1.2
                                      )?.toFixed(2)
                                    : "0.00"}
                                </span>
                              </div>
                            </div>
                            <div className={classNames.addButton}>
                              <span
                                className={classNames.btns}
                                onClick={() => {
                                  if (
                                    allCartItemsLocal?.cart_id &&
                                    eachProduct?._id
                                  ) {
                                    addRemoveItemCart(
                                      allCartItemsLocal?.cart_id,
                                      eachProduct?._id,
                                      "Remove",
                                      setRefreshLocal
                                    );
                                  } else {
                                    predefinedToast(
                                      "Cart/service id not found!"
                                    );
                                  }
                                }}
                              >
                                -
                              </span>
                              <span>0</span>
                              <span
                                className={classNames.btns}
                                onClick={() => {
                                  if (
                                    allCartItemsLocal?.cart_id &&
                                    eachProduct?._id
                                  ) {
                                    addRemoveItemCart(
                                      allCartItemsLocal?.cart_id,
                                      eachProduct?._id,
                                      "Add",
                                      setRefreshLocal
                                    );
                                  } else {
                                    predefinedToast(
                                      "Cart/service id not found!"
                                    );
                                  }
                                }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* </InfiniteScroll> */}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Discover;

export const AllConsultations = ({
  setSelectedDiscoverSection,
  selectedMainTest,
  setSelectedMainTest,
}) => {
  //constants
  const allSubTabs = ["Physical Health", "Mental health", "Health Assistance"];

  const [selectedSubTab, setSelectedSubTab] = useState("Physical Health");
  const [selectedTest, setSelectedTest] = useState("");

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.subTabs}>
        {allSubTabs?.map((eachTab, index) => {
          return (
            <div
              key={eachTab + index}
              className={
                selectedSubTab === eachTab ? classNames.selectedTab : ""
              }
              onClick={() => setSelectedSubTab(eachTab)}
            >
              {eachTab}
            </div>
          );
        })}
      </div>
      <div className={classNames.filterDiv}>
        <div className={classNames.searchDiv}>
          <div className={classNames.searchDropdown}>
            All <IoIosArrowDown />
          </div>
          <input
            placeholder="Enter your Wellness Program Or Service"
            className={classNames.inputContainer}
          />
        </div>
        <div className={classNames.locationFilter}>
          <label htmlFor="">Location:</label>
          <div className={classNames.city}>Hyderabad</div>
          <MdEdit />
        </div>
      </div>
      <div className={classNames.title}>{selectedMainTest?.mainTest}</div>
      <div className={classNames.allTests}>
        {allCompanies?.map((eachTest, index) => {
          return (
            <div
              className={`${classNames.eachCompany}`}
              onClick={() => {
                setSelectedDiscoverSection("SubCategory");
                setSelectedMainTest((prev) => {
                  return {
                    ...prev,
                    subCompanies: eachTest?.title,
                    subCompaniesImage: eachTest?.image,
                  };
                });
              }}
            >
              <img src={eachTest?.image} alt={eachTest?.title} />
              {/* <div className={classNames.title}>{eachTest?.title}</div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const AllSubCategory = ({
  setSelectedDiscoverSection,
  selectedMainTest,
  setSelectedMainTest,
}) => {
  //constants
  const allSubTabs = ["Physical Health", "Mental health", "Health Assistance"];

  const [selectedSubTab, setSelectedSubTab] = useState("Physical Health");
  const [selectedTest, setSelectedTest] = useState("");

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.filterDiv}>
        <div className={classNames.majorSearch}>
          <div className={classNames.searchDiv}>
            <div className={classNames.searchDropdown}>
              All <IoIosArrowDown />
            </div>
            <input
              placeholder="Enter your Wellness Program Or Service"
              className={classNames.inputContainer}
            />
          </div>
          {/* <div className={classNames.breadCrumb}>
            <span>{selectedMainTest?.mainTest}</span>
            <span>{`>`}</span>
            <span>{selectedMainTest?.subCompanies}</span>
          </div> */}
        </div>
        <div className={classNames.filterCompany}>
          <img
            src={selectedMainTest?.subCompaniesImage}
            alt={selectedMainTest?.subCompanies}
            className={classNames.logoImage}
          />
          <div className={classNames.locationFilter}>
            <label htmlFor="">Location:</label>
            <div className={classNames.city}>Hyderabad</div>
            <MdEdit />
          </div>
        </div>
      </div>
      <div className={classNames.companyDesc}>
        <div className={classNames.title}>{selectedMainTest?.mainTest}</div>
        <div className={classNames.desc}>
          {selectedMainTest?.subCompanies} in partnership with FMA brings
          experienced General Physicians to the doorstep – both virtually and
          physically.
        </div>
      </div>
      <div className={classNames.subMenuSelection}>
        <div className={classNames.title}>Select a main category</div>
        <div className={classNames.allSubItems}>
          <div
            className={
              selectedMainTest?.mainTest === "Doctor Consultation"
                ? classNames.selectedSubItems
                : ""
            }
          >
            Doctor Consultation
          </div>
          <div
            className={
              selectedMainTest?.mainTest === ""
                ? classNames.selectedSubItems
                : ""
            }
          >
            Covid Consultation
          </div>
        </div>
      </div>
      <div className={classNames.subMenuSelection}>
        <div className={classNames.title}>Select a sub category</div>
        <div className={classNames.allSubItems}>
          {subCategory?.map((eachCategory, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedDiscoverSection("AllServices");
                  setSelectedMainTest((prev) => {
                    return {
                      ...prev,
                      subCategory: eachCategory,
                    };
                  });
                }}
              >
                {eachCategory}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const AllServices = ({
  setSelectedDiscoverSection,
  selectedMainTest,
  setSelectedMainTest,
}) => {
  const { setAllCartItems, addToCart } = useContext(BankContext);
  //constants
  const allSubTabs = ["Physical Health", "Mental health", "Health Assistance"];

  const [selectedSubTab, setSelectedSubTab] = useState("Physical Health");
  const [selectedTest, setSelectedTest] = useState("");

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.filterDiv}>
        <div className={classNames.majorSearch}>
          <div className={classNames.searchDiv}>
            <div className={classNames.searchDropdown}>
              All <IoIosArrowDown />
            </div>
            <input
              placeholder="Enter your Wellness Program Or Service"
              className={classNames.inputContainer}
            />
          </div>
          <div className={classNames.breadCrumb}>
            <span>{selectedMainTest?.mainTest}</span>
            <span>{`>`}</span>
            <span>{selectedMainTest?.subCategory}</span>
          </div>
        </div>
        <div className={classNames.filterCompany}>
          <img
            src={selectedMainTest?.subCompaniesImage}
            alt={selectedMainTest?.subCompanies}
            className={classNames.logoImage}
          />
          <div className={classNames.locationFilter}>
            <label htmlFor="">Location:</label>
            <div className={classNames.city}>Hyderabad</div>
            <MdEdit />
          </div>
        </div>
      </div>
      <div className={classNames.buytests}>
        <div className={classNames.title}>Services</div>
        <div className={classNames.allTestsBuy}>
          <div
            className={classNames.eachTestServices}
            onClick={
              () => {
                addToCart("Complete Blood Count (CBC) / CBP - Whole Blood");
              }
              // setAllCartItems((prev) => {
              //   return [
              //     ...prev,
              //     {
              //       title: "Complete Blood Count (CBC) / CBP - Whole Blood",
              //       desc: `Complete Blood Count (CBC) or Complete Blood Picture is
              //     a group of tests that evaluates the status of the circulating
              //     blood cells including the Red Blood Cells, White Blood Cells
              //     and Platelets in the body. This test is routinely performed
              //     to screen or diagnose various conditions .....`,
              //       price: "100",
              //       logo: dummyTest,
              //     },
              //   ];
              // })
            }
          >
            <div className={classNames.testDesc}>
              {/* <img src={dummyTest} alt="dummyTest" /> */}
              <div>
                <div className={classNames.title}>
                  Complete Blood Count (CBC) / CBP - Whole Blood
                </div>
                <div className={classNames.desc}>
                  Complete Blood Count (CBC) or Complete Blood Picture (CBP) is
                  a group of tests that evaluates the status of the circulating
                  blood cells (including the Red Blood Cells, White Blood Cells
                  and Platelets) in the body. This test is routinely performed
                  to screen or diagnose various conditions .....
                </div>
                <div className={classNames.price}>$ 100</div>
              </div>
            </div>
            <div className={classNames.btnsContainer}>
              <div>Add to Cart</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.buytests}>
        <div className={classNames.title}>Popular Packages</div>
        <div className={classNames.allTestsBuy}>
          <div
            className={classNames.eachTest}
            onClick={
              () => {
                addToCart("Popular Packages");
              }
              // setAllCartItems((prev) => {
              //   return [
              //     ...prev,
              //     {
              //       title: "Popular Packages",
              //       desc: "Health Shield Comprehensive - Male",
              //       price: "4999",
              //       logo: dummyTest,
              //     },
              //   ];
              // })
            }
          >
            <div className={classNames.testDesc}>
              <img src={dummyTest} alt="dummyTest" />
              <div>
                <div className={classNames.title}>Popular Packages</div>
                <div className={classNames.desc}>
                  Health Shield Comprehensive - Male
                </div>
                <div className={classNames.price}>$ 4999</div>
              </div>
            </div>
            <div className={classNames.btnsContainer}>
              <div>More Info</div>
              <div>Add to Cart</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AllCartItems = ({
  selectedDiscoverSection,
  setSelectedDiscoverSection,
  selectedMainTest,
  setSelectedMainTest,
}) => {
  const { email, allCartItems, setAllCartItems, allCartProducts, addToCart } =
    useContext(BankContext);
  //constants
  const allSubTabs = ["Physical Health", "Mental health", "Health Assistance"];

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSubTab, setSelectedSubTab] = useState("Physical Health");
  const [selectedTest, setSelectedTest] = useState("");
  const [checkboxStatus, setCheckboxStatus] = useState([]);
  const [allMembersList, setAllMembersList] = useState([
    {
      name: "betty thomas",
      assignedUnit: "HR Manager",
      date: "01/01/2024",
      location: "Hyderabad",
      service: "HR Manager",
    },
  ]);
  const [userHasCart, setUserHasCart] = useState("loading");
  const [allCartItemsLocal, setAllCartItemsLocal] = useState([]);
  const [localUserIdCart, setLocalUserIdCart] = useState("");
  const [localUserHRId, setLocalUserHRId] = useState("");
  const [refreshLocal, setRefreshLocal] = useState(false);

  async function createCart() {
    try {
      let response = await Axios.post(
        `https://coverfi.apimachine.com/api/v1/client-user/cart/create`,
        {
          user_id: localUserIdCart,
        }
      );
      if (response?.data?.status) {
        predefinedToast("Created cart succesfully!");
        setRefreshLocal((prev) => !prev);
      } else {
        predefinedToast("Error while creating cart");
        setRefreshLocal((prev) => !prev);
      }
    } catch (error) {
      predefinedToast("Error while creating cart");
      console.log(error?.message, "Error while creating cart");
    }
  }

  async function getCartUserIdWithHR() {
    try {
      let response = await Axios.get(
        `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
      );

      // console.log(response?.data?.data, "Get Cart user ID for logged in HR");
      if (response?.data?.data?.hrDetails?.hrCompanyDetails?._id) {
        getCompanyCartDetails(
          response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        );
        setLocalUserHRId(response?.data?.data?.hrDetails?._id);
        setLocalUserIdCart(
          response?.data?.data?.hrDetails?.hrCompanyDetails?._id
        );
      } else {
        predefinedToast("User ID from getCartUserIdWithHR not found!");
        console.log("User ID from getCartUserIdWithHR not found!");
      }
    } catch (error) {
      console.log(error?.message, "Get Cart user ID for logged in HR error");
    }
  }

  async function getCompanyCartDetails(userID) {
    setUserHasCart("loading");
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/client-user/cart/?user_id=${userID}`
      );
      console.log(response?.data, "Get company cart details");
      if (response?.data?.status && response?.data?.data?.length > 0) {
        setUserHasCart(true);
        setAllCartItemsLocal(response?.data?.data[0]);
      } else {
        setUserHasCart(false);
      }
    } catch (error) {
      console.log(error, "Get company cart details error");
      setUserHasCart(false);
    }
  }

  async function initiateExpressCheckout() {
    try {
      let response = await Axios.post(
        `https://coverfi.apimachine.com/api/v1/client-user/cart/checkout/${allCartItemsLocal?.cart_id}/${localUserHRId}`
      );
      console.log(response?.data, "Initiate express checkout");
      if (response?.data?.status) {
        setUserHasCart("success");
        predefinedToast(response?.data?.message);
        setCheckboxStatus([]);
      } else {
        predefinedToast(response?.data?.message);
      }
    } catch (error) {
      console.log(error, "Initiate express checkout error");
      predefinedToast(error?.message);
    }
  }

  useEffect(() => {
    if (localUserIdCart) {
      getCompanyCartDetails(localUserIdCart);
    } else {
      getCartUserIdWithHR();
    }
  }, [refreshLocal]);

  return (
    <div className={classNames.discoverTab}>
      <div className={classNames.offerings}>
        <div className={classNames.paymentBreadcrumb}>
          <div
            className={
              selectedDiscoverSection === "allcart"
                ? classNames.currentBreadcrumb
                : ""
            }
          >
            1
          </div>
          <div
            className={
              selectedDiscoverSection === "allcartmembers"
                ? classNames.currentBreadcrumb
                : ""
            }
          >
            2
          </div>
          <div
            className={
              selectedDiscoverSection === "allcartreview"
                ? classNames.currentBreadcrumb
                : ""
            }
          >
            3
          </div>
          <div
            className={
              selectedDiscoverSection === "allcartpayment"
                ? classNames.currentBreadcrumb
                : ""
            }
          >
            4
          </div>
        </div>
        {selectedDiscoverSection === "allcartpayment" ? (
          <>
            <div className={classNames.titleBig}>Payment Details</div>
            <div className={classNames.paymentContainer}></div>
            <div className={classNames.buttonsContainer}>
              <button
                className={classNames.greyBtn}
                onClick={() => {
                  setSelectedDiscoverSection("allcartreview");
                }}
              >
                Go Back
              </button>
              <button
                className={classNames.successBtn}
                // onClick={() => {
                //   setSelectedDiscoverSection("allcartpayment");
                // }}
              >
                Checkout
              </button>
            </div>
          </>
        ) : selectedDiscoverSection === "allcartreview" ? (
          <>
            <div className={classNames.titleBig}>Review Member Details</div>
            <TableEmployeesSimplified
              allLeads={allMembersList}
              setAllLeads={setAllMembersList}
              allBrokeragesSearch={searchQuery}
              allLeadsLoading={false}
            />
            <div className={classNames.buttonsContainer}>
              <button
                className={classNames.greyBtn}
                onClick={() => {
                  setSelectedDiscoverSection("allcartmembers");
                }}
              >
                Go Back
              </button>
              <button
                className={classNames.successBtn}
                onClick={() => {
                  setSelectedDiscoverSection("allcartpayment");
                }}
              >
                Next
              </button>
            </div>
          </>
        ) : selectedDiscoverSection === "allcartmembers" ? (
          <>
            <div className={classNames.titleBig}>Members</div>
            <TableEmployeesSimplified
              allLeads={allMembersList}
              setAllLeads={setAllMembersList}
              allBrokeragesSearch={searchQuery}
              allLeadsLoading={false}
              editTable={true}
            />
            <div className={classNames.buttonsContainer}>
              <button
                className={classNames.greyBtn}
                onClick={() => {
                  setSelectedDiscoverSection("allcart");
                }}
              >
                Go Back
              </button>
              <button
                className={classNames.successBtn}
                onClick={() => {
                  setSelectedDiscoverSection("allcartreview");
                }}
              >
                Next
              </button>
            </div>
          </>
        ) : selectedDiscoverSection === "allcart" ? (
          <>
            <div className={classNames.titleBig}>Confirm Items</div>
            <div className={classNames.flexContainer}>
              {userHasCart === "loading" ? (
                <div className={classNames.allOfferings}>
                  <div
                    className={classNames.eachOffering}
                    key={"eachcartitemloading"}
                  >
                    <div style={{ padding: "0px" }}>
                      {/* <CheckboxIcon /> */}
                      <Skeleton width={60} height={60} />
                    </div>
                    <div>
                      <div>
                        <div className={classNames.title}>
                          <Skeleton width={75} height={15} />
                        </div>
                        <div className={classNames.desc}>
                          <Skeleton width={130} height={15} />
                          <Skeleton width={130} height={15} />
                        </div>
                        <div
                          className={classNames.desc}
                          style={{ fontWeight: "520" }}
                        >
                          <Skeleton width={130} height={15} />
                        </div>
                      </div>
                      <div className={classNames.priceDiv}>
                        <div>
                          <Skeleton width={70} height={15} />
                        </div>
                        <div>
                          <Skeleton width={50} height={15} />
                        </div>
                      </div>
                      <div className={classNames.addButton}>
                        <span className={classNames.btns}>
                          <Skeleton width={20} height={20} />
                        </span>
                        <span>
                          <Skeleton width={80} height={20} />
                        </span>
                        <span className={classNames.btns}>
                          <Skeleton width={20} height={20} />
                        </span>
                      </div>
                    </div>
                    <img src={removeItem} alt="removeItem" />
                  </div>
                </div>
              ) : userHasCart === "success" ? (
                <div className={classNames.allOfferings}>
                  <div className={classNames.noCartItems}>
                    <div className={classNames.message}>
                      You have successfully checked out
                    </div>
                  </div>
                </div>
              ) : userHasCart ? (
                <div className={classNames.allOfferings}>
                  {allCartItemsLocal?.cart_items?.length > 0 ? (
                    allCartItemsLocal?.cart_items?.map((eachProduct, index) => {
                      return (
                        <div
                          className={classNames.eachOffering}
                          key={"eachcartitem" + index}
                        >
                          <div style={{ padding: "0px" }}>
                            {/* <CheckboxIcon /> */}
                            <img
                              src={
                                eachProduct?.wellness_service_logo_url
                                  ? eachProduct?.wellness_service_logo_url
                                  : eachProduct?.wellness_partner_logo_url
                                  ? eachProduct?.wellness_partner_logo_url
                                  : ""
                              }
                              alt={
                                eachProduct?.wellness_service_name
                                  ? eachProduct?.wellness_service_name
                                  : "eachproductlogo"
                              }
                            />
                          </div>
                          <div>
                            <div>
                              <div className={classNames.title}>
                                {eachProduct?.wellness_service_name}
                                <span>
                                  <FaChevronDown />
                                </span>
                              </div>
                              <div className={classNames.desc}>
                                {eachProduct?.wellness_service_description}
                              </div>
                              <div className={classNames.desc}>
                                Provided By:{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {eachProduct?.wellness_partner_name}
                                </span>
                              </div>
                            </div>
                            <div className={classNames.priceDiv}>
                              <div>
                                ${" "}
                                {eachProduct?.wellness_service_sub_total
                                  ? (
                                      Number(
                                        eachProduct?.wellness_service_sub_total
                                      ) / 100
                                    )?.toFixed(2)
                                  : "0.00"}
                                {/* <span style={{ textDecoration: "none" }}>Per year</span> */}
                                <span>
                                  {" "}
                                  {eachProduct?.wellness_service_sub_total
                                    ? (
                                        (Number(
                                          eachProduct?.wellness_service_sub_total
                                        ) /
                                          100) *
                                        1.2
                                      )?.toFixed(2)
                                    : "0.00"}
                                </span>
                              </div>
                            </div>
                            <div className={classNames.addButton}>
                              <span
                                className={classNames.btns}
                                // style={{
                                //   pointerEvents:
                                //     eachItem?.quantity == 1 ? "none" : "",
                                //   opacity:
                                //     eachItem?.quantity == 1 ? "0.5" : "",
                                // }}
                                onClick={() => {
                                  if (
                                    allCartItemsLocal?.cart_id &&
                                    eachProduct?.wellness_service_id
                                  ) {
                                    addRemoveItemCart(
                                      allCartItemsLocal?.cart_id,
                                      eachProduct?.wellness_service_id,
                                      "Remove",
                                      setRefreshLocal
                                    );
                                  } else {
                                    predefinedToast(
                                      "Cart/service id not found!"
                                    );
                                  }
                                }}
                              >
                                -
                              </span>
                              <span>{eachProduct?.quantity}</span>
                              <span
                                className={classNames.btns}
                                onClick={() => {
                                  if (
                                    allCartItemsLocal?.cart_id &&
                                    eachProduct?.wellness_service_id
                                  ) {
                                    addRemoveItemCart(
                                      allCartItemsLocal?.cart_id,
                                      eachProduct?.wellness_service_id,
                                      "Add",
                                      setRefreshLocal
                                    );
                                  } else {
                                    predefinedToast(
                                      "Cart/service id not found!"
                                    );
                                  }
                                }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <img
                            src={removeItem}
                            alt="removeItem"
                            onClick={() => {
                              if (
                                allCartItemsLocal?.cart_id &&
                                eachProduct?.wellness_service_id
                              ) {
                                addRemoveItemCart(
                                  allCartItemsLocal?.cart_id,
                                  eachProduct?.wellness_service_id,
                                  "Remove",
                                  setRefreshLocal,
                                  Number(eachProduct?.quantity)
                                );
                              } else {
                                predefinedToast("Cart/service id not found!");
                              }
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className={classNames.noCartItems}>
                      <div className={classNames.message}>
                        Looks like you don’t have any item in your cart yet.
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={classNames.noCartItems}>
                  <div className={classNames.message}>
                    Looks like you don’t have a cart yet.
                  </div>
                  <div
                    className={`rahaGreenBtn ${classNames.submitBtn}`}
                    onClick={() => {
                      if (localUserIdCart) {
                        createCart();
                      }
                    }}
                  >
                    Create cart
                  </div>
                </div>
              )}
              <div className={classNames.detailedPrice}>
                <div className={classNames.subTotal}>
                  <div className={classNames.flexContainer}>
                    <div className={classNames.boldTitle}>Subtotal</div>
                    <div className={classNames.normalWords}>
                      ${" "}
                      {allCartItemsLocal?.service_price
                        ? (
                            Number(allCartItemsLocal?.service_price) / 100
                          )?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className={classNames.flexContainer}>
                    <div className={classNames.greenBtn}>Apply Coupon</div>
                  </div>
                </div>
                <div className={classNames.totallingContainer}>
                  <div
                    className={classNames.flexContainer}
                    // style={{ justifyContent: "flex-end" }}
                  >
                    <div className={classNames.normalWords}>After coupon</div>
                    <div className={classNames.boldTitle}>
                      ${" "}
                      {allCartItemsLocal?.service_price
                        ? (
                            Number(allCartItemsLocal?.service_price) / 100
                          )?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className={classNames.flexContainer}>
                    <div className={classNames.normalWords}>SSGT @9%</div>
                    <div className={classNames.boldTitle}>
                      ${" "}
                      {allCartItemsLocal?.gst
                        ? (Number(allCartItemsLocal?.gst) / 100 / 2)?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className={classNames.flexContainer}>
                    <div className={classNames.normalWords}>CSGT @9%</div>
                    <div className={classNames.boldTitle}>
                      ${" "}
                      {allCartItemsLocal?.gst
                        ? (Number(allCartItemsLocal?.gst) / 100 / 2)?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.grandDiv}
                  style={{ borderBottom: "1px solid #e5e5e5" }}
                >
                  <div className={classNames.flexContainer}>
                    <div className={classNames.boldTitle}>Grand Total</div>
                    <div className={classNames.boldTitle}>
                      ${" "}
                      {allCartItemsLocal?.grand_total
                        ? (
                            Number(allCartItemsLocal?.grand_total) / 100
                          )?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className={classNames.agreeTerms}>
                    <div>
                      <CustomCheckbox
                        actionBtns={checkboxStatus}
                        setActionBtns={setCheckboxStatus}
                        id={"checkboxStatus"}
                        name={"checkboxStatus name"}
                      />
                    </div>
                    <div className={classNames.term}>
                      I agree and understand FMA Terms of Use, Cancellation and
                      refund policy.
                    </div>
                  </div>
                </div>
                <div className={classNames.grandDiv}>
                  <div className={classNames.title}>Checkout Options</div>
                  <div
                    className={classNames.checkoutMethod}
                    style={{
                      opacity: checkboxStatus?.includes("checkboxStatus")
                        ? ""
                        : "0.5",
                      pointerEvents: checkboxStatus?.includes("checkboxStatus")
                        ? ""
                        : "none",
                    }}
                    onClick={() => {
                      if (localUserHRId && allCartItemsLocal?.cart_id) {
                        initiateExpressCheckout();
                      }
                    }}
                  >
                    <div>Express</div>
                    <div>
                      Purchase the items in your cart instantly and configure
                      the time, serviceability and recipients later.
                    </div>
                  </div>
                  <div
                    className={classNames.checkoutMethod}
                    style={{ opacity: "0.5", pointerEvents: "none" }}
                  >
                    <div>Regular</div>
                    <div>
                      Configure the recipients at this point and check time and
                      eligibility of each item before purchase.
                    </div>
                  </div>
                  {/* <div className={classNames.flexContainer}>
                    <div className={classNames.boldTitle}>Grand Total</div>
                    <div className={classNames.boldTitle}>
                      ${" "}
                      {allCartItemsLocal?.grand_total
                        ? (
                            Number(allCartItemsLocal?.grand_total) / 100
                          )?.toFixed(2)
                        : "0.00"}
                    </div>
                  </div>
                  <div className={classNames.agreeTerms}>
                    <div>
                      <CustomCheckbox
                        actionBtns={checkboxStatus}
                        setActionBtns={setCheckboxStatus}
                        id={"checkboxStatus"}
                        name={"checkboxStatus name"}
                      />
                    </div>
                    <div className={classNames.term}>
                      I agree and understand FMA Terms of Use, Cancellation and
                      refund policy.
                    </div>
                  </div> */}
                  {/* <button
                    className={classNames.successBtn}
                    onClick={() => {
                      setSelectedDiscoverSection("allcartmembers");
                    }}
                  >
                    Next
                  </button>
                  <button className={classNames.failedBtn}>Go Back</button> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const TableEmployeesSimplified = ({
  allLeads,
  setAllLeads,
  allBrokeragesSearch,
  allLeadsLoading,
  editTable,
}) => {
  return (
    <div
      className={classNames.tableContainer}
      style={{ border: allLeads ? "" : "none" }}
    >
      <table style={{ minWidth: "100%" }}>
        <thead>
          <tr
            style={{ gridTemplateColumns: "min-content repeat(5, 1fr) 50px" }}
          >
            <th></th>
            <th>Member Name</th>
            <th>Assigned Units</th>
            <th>Date & Time</th>
            <th>Location</th>
            <th>Service Address</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allLeadsLoading ? (
            <tr
              key={"loading"}
              style={{ gridTemplateColumns: "min-content repeat(5, 1fr) 50px" }}
            >
              <td></td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
              <td>
                <Skeleton width={100} />
              </td>
            </tr>
          ) : allLeads?.length > 0 ? (
            allLeads
              ?.filter((row) => {
                // Convert the row object values to an array and check if any value matches the search query
                const values = Object?.values(row);
                const searchQuery = allBrokeragesSearch?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                if (!editTable) {
                  delete row?.newItem;
                }

                return values?.some((value) => {
                  if (typeof value === "string") {
                    return value?.toLowerCase()?.includes(searchQuery);
                  }
                  return false;
                });
              })
              ?.map((row, index) => (
                <tr
                  key={row?.brokerage_name + index}
                  style={{
                    gridTemplateColumns: "min-content repeat(5, 1fr) 50px",
                  }}
                >
                  <td>
                    {/* <CustomCheckbox
                      actionBtns={actionBtns}
                      setActionBtns={setActionBtns}
                      id={row?.tpA_CLAIM_NO}
                      name={row?.first_name + " " + row?.last_name}
                    /> */}
                    {editTable && (
                      <EditIcon
                        onClick={() => {
                          setAllLeads((prev) => {
                            return prev?.map((item, i) => {
                              if (i === index) {
                                return { ...item, newItem: true };
                              }
                              return item;
                            });
                          });
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row?.newItem ? (
                      <EachCustomDropdown
                        buttonText={allLeads ? allLeads : "Industry Type"}
                        dropdown={["checking 1", "checking 2", "checking 3"]}
                        name="name"
                        placeholder="Member name"
                        stateValue={allLeads}
                        setState={setAllLeads}
                        typee="arrayObj"
                        indexValue={index}
                      />
                    ) : row?.name ? (
                      row?.name
                    ) : (
                      ""
                    )}
                    {/* {row?.name ? (
                      <CopyBtn copyText={row?.name ? row?.name : ""} />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.newItem ? (
                      <EachCustomDropdown
                        buttonText={allLeads ? allLeads : "Assigned Units"}
                        dropdown={["HR", "Developer"]}
                        name="assignedUnit"
                        placeholder="Assigned Units"
                        stateValue={allLeads}
                        setState={setAllLeads}
                        typee="arrayObj"
                        indexValue={index}
                      />
                    ) : row?.assignedUnit ? (
                      row?.assignedUnit
                    ) : (
                      ""
                    )}
                    {/* {row?.assignedUnit ? (
                      <CopyBtn
                        copyText={row?.assignedUnit ? row?.assignedUnit : ""}
                      />
                    ) : (
                      ""
                    )} */}
                  </td>

                  <td>
                    {row?.newItem ? (
                      <EachCustomDatePicker
                        //   title="Policy valid until"
                        placeholder="Select date"
                        // dropdown={usersDropdown}
                        name="date"
                        stateValue={allLeads}
                        setState={setAllLeads}
                        typee="arrayObj"
                        indexValue={index}
                        //   mandatory={clientOnboardingForm ? true : false}
                      />
                    ) : row?.date ? (
                      row?.date
                    ) : (
                      ""
                    )}
                    {/* {row?.date ? (
                      <CopyBtn copyText={row?.date ? row?.date : ""} />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.newItem ? (
                      <EachCustomDropdown
                        buttonText={allLeads ? allLeads : "Location"}
                        dropdown={["Hyderabad", "Bangalore", "Chennai"]}
                        name="location"
                        placeholder="Location"
                        stateValue={allLeads}
                        setState={setAllLeads}
                        typee="arrayObj"
                        indexValue={index}
                      />
                    ) : row?.location ? (
                      row?.location
                    ) : (
                      ""
                    )}
                    {/* {row?.location ? (
                      <CopyBtn copyText={row?.location ? row?.location : ""} />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {row?.newItem ? (
                      <EachCustomDropdown
                        buttonText={allLeads ? allLeads : "Service"}
                        dropdown={["Service 1", "Service 2", "Service 3"]}
                        name="service"
                        placeholder="Service"
                        stateValue={allLeads}
                        setState={setAllLeads}
                        typee="arrayObj"
                        indexValue={index}
                      />
                    ) : row?.service ? (
                      row?.service
                    ) : (
                      ""
                    )}
                    {/* {row?.service ? (
                      <CopyBtn copyText={row?.service ? row?.service : ""} />
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td>
                    {editTable && (
                      <TrashIcon
                        className={classNames.deleteRow}
                        onClick={() => {
                          setAllLeads((prev) => {
                            return prev?.filter((item, i) => i !== index);
                          });
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))
          ) : (
            ""
          )}
          {editTable && (
            <tr
              style={{
                gridTemplateColumns: "min-content repeat(5, 1fr) 50px",
              }}
            >
              <td></td>
              <td>
                <button
                  className={classNames.addNew}
                  onClick={() => {
                    setAllLeads((prev) => {
                      let prevv = prev.map((eachPrev, index) => {
                        delete eachPrev?.newItem;
                        return eachPrev;
                      });
                      return [
                        ...prevv,
                        {
                          name: "",
                          assignedUnit: "",
                          date: "",
                          location: "",
                          service: "",
                          newItem: true,
                        },
                      ];
                    });
                  }}
                >
                  New Nember
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
