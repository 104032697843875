import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./quote.module.scss";

import Navbar from "../../components/navbar";
import Axios from "axios";
import { predefinedToastWhite } from "../../utils/toast";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import yesIcon from "../../assest/images/icons/yes.svg";
import noIcon from "../../assest/images/icons/no.svg";

import rahabot from "../../assest/images/draft/rahabot.svg";
import whatsappIcon from "../../assest/images/icons/whatsapp.svg";

import uploadFileIcon from "../../assest/images/icons/uploadfile.svg";
import { uploadFileFunc } from "../../utils/imageUpload";

//assets icon
import { ReactComponent as PropertyIcon } from "../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../assest/images/profile/world.svg";
import { BankContext } from "../../context/Context";
import { LoadingAnimationRaha } from "../../components/Custom";

import rahaFullLogo from "../../assest/images/fullLogo.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeFirstLetters } from "../../utils/FunctionTools";
import MobileNavbar from "../../components/navbar/mobile";

const GetQuoteTest = () => {
  const history = useHistory();
  const { isScrolled, setIsScrolled } = useContext(BankContext);
  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [selectedSection, setSelectedSection] = useState(1);
  const [emailValidation, setEmailValidation] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [currentStep, setCurrentStep] = useState([]);

  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [uploadFileRFQLoading, setUploadFileRFQLoading] = useState("");

  const [getQuoteDetails, setGetQuoteDetails] = useState({
    external_lead: true,
    first_name: "",
    last_name: "",
    company_name: "",
    company_size: "",
    lead_email: "",
    phone_number: "",
    pin_code: "",
    external_policy: false,
  });

  function createQuote() {
    setSelectedSection(99);
    // const uniqueArray = [...new Set(getQuoteDetails?.what_are_you_looking_for)];
    // const {
    //   external_policy_health,
    //   external_policy_life,
    //   external_policy_accidental,
    // } = getQuoteDetails;

    // let finalObj = {
    //   ...getQuoteDetails,
    //   what_are_you_looking_for: uniqueArray,
    // };
    // if (
    //   external_policy_health ||
    //   external_policy_life ||
    //   external_policy_accidental
    // ) {
    //   finalObj = { ...finalObj, external_policy: true };
    // }
    console.log("getQuoteDetails", getQuoteDetails);
    getQuoteDetails.phone_number = `+91${getQuoteDetails?.phone_number}`;
    Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/lead/create`,
      getQuoteDetails
    )
      .then((response) => {
        // console.log(response?.data?.status, "create quote");
        if (response?.data?.status) {
          predefinedToastWhite(response?.data?.message);
        } else {
          predefinedToastWhite(response?.data?.message);
        }
        setSelectedSection(100);
        // setTimeout(() => {
        //   window?.location?.reload();
        // }, 3000);
      })
      .catch((error) => {
        console.log(error?.message, "create quote API error");
        setSelectedSection(0);
      });
  }

  function isEmailAlreadyExist(email) {
    Axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
    )
      .then((response) => {
        console.log(response.data?.count, "is EmailAlreadyExist response");
        if (response?.data?.count) {
          if (!emailValidation) {
            console.log("positive response");
            setEmailValidation((prev) => !prev);
          }
        } else {
          if (emailValidation) {
            console.log("negative response");
            setEmailValidation((prev) => !prev);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, "is email exist API error");
      });
  }

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/general/getstates`
    )
      .then((response) => {
        // console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const selectedSectionElement = container.children[selectedSection];

    // Scroll the container to bring the selected section into view
    if (selectedSectionElement) {
      const containerWidth = container.offsetWidth;
      const sectionOffset = selectedSectionElement.offsetLeft;
      const sectionWidth = selectedSectionElement.offsetWidth;

      if (sectionOffset < container.scrollLeft) {
        // Scroll left to bring the selected section into view with some space
        container.scrollLeft = sectionOffset - 1.5 * 16; // 1.5rem converted to pixels
      } else if (
        sectionOffset + sectionWidth >
        container.scrollLeft + containerWidth
      ) {
        // Scroll right to bring the selected section into view with some space
        container.scrollLeft =
          sectionOffset + sectionWidth - containerWidth + 1.5 * 16; // 1.5rem converted to pixels
      }
    }
  }, [selectedSection]);

  const handleTabKeyPress = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault(); // Prevent the default tab behavior
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleTabKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleTabKeyPress);
    };
  }, []);

  useEffect(() => {
    const homeElement = document.getElementById("quote");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (getQuoteDetails?.lead_email) {
      isEmailAlreadyExist(getQuoteDetails?.lead_email);
    }
  }, [getQuoteDetails?.lead_email]);

  return (
    <div className={classNames.quote} id="quote">
      {window.innerWidth > 768 ? (
        <Navbar isScrolled={isScrolled} />
      ) : (
        <MobileNavbar />
      )}
      <div className={classNames.topSection}>
        <div className={classNames.quoteFormContainer}>
          <div className={classNames.leftDiv}>
            <div className={classNames.topDiv} ref={containerRef}>
              {[
                "Current Coverage",
                ...capitalizeFirstLetters(currentStep),
                "About You",
                "Point Of Contact",
                "Company Details",
                "Location",
                "Coverage Type",
                "Partner",
              ]?.map((eachItem, index) => {
                return (
                  <div
                    className={
                      selectedSection == index
                        ? classNames.selectedFormOption
                        : ""
                    }
                    style={{
                      opacity: selectedSection < index ? "0.2" : "",
                      pointerEvents: selectedSection < index ? "none" : "",
                      display: index === 0 ? "none" : "",
                    }}
                    onClick={() => setSelectedSection(index)}
                  >
                    {eachItem}
                  </div>
                );
              })}
            </div>
            <div className={classNames.bottomDiv}>
              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 1
                      ? ""
                      : selectedSection > currentStep?.length + 1
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Who will be our point of contact?
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="First name.."
                    value={getQuoteDetails?.first_name}
                    onChange={(event) => {
                      const onlyLetters = /^[A-Za-z\s\.]+$/;

                      if (
                        (onlyLetters?.test(event?.target?.value) ||
                          event?.target?.value === "") &&
                        event?.target?.value.length <= 30
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          first_name: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") {
                        event.preventDefault();
                        document.getElementById("lastNameQuoteTest").focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Last name..."
                    value={getQuoteDetails?.last_name}
                    id="lastNameQuoteTest"
                    onChange={(event) => {
                      const onlyLetters = /^[A-Za-z\s\.]+$/;

                      if (
                        (onlyLetters?.test(event?.target?.value) ||
                          event?.target?.value === "") &&
                        event?.target?.value.length <= 30
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          last_name: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        getQuoteDetails?.first_name &&
                        getQuoteDetails?.last_name
                      ) {
                        event.preventDefault();
                        setSelectedSection(currentStep?.length + 2);
                      }
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => {
                      // setSelectedSection(currentStep?.length);
                      history.push("/home");
                    }}
                  >
                    Back
                  </div>
                  <div
                    style={{
                      pointerEvents:
                        getQuoteDetails?.first_name &&
                        getQuoteDetails?.last_name
                          ? ""
                          : "none",
                      opacity:
                        getQuoteDetails?.first_name &&
                        getQuoteDetails?.last_name
                          ? ""
                          : "0.5",
                    }}
                    onClick={() => {
                      setSelectedSection(currentStep?.length + 2);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>

              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 2
                      ? ""
                      : selectedSection > currentStep?.length + 2
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                  overflowY: "scroll",
                }}
              >
                <div className={classNames.title}>
                  Enter Your Email & Phone Number
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    id="emailValidationId"
                    placeholder="Email.."
                    // style={inputStyle}
                    style={{
                      border: emailValidation
                        ? "1px solid red"
                        : !getQuoteDetails?.lead_email?.length <= 50 &&
                          !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                            getQuoteDetails?.lead_email
                          ) &&
                          isFocused
                        ? "1px solid red"
                        : "",
                    }}
                    value={getQuoteDetails?.lead_email}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(event) => {
                      if (event?.target?.value.length <= 50) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          lead_email: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") {
                        event.preventDefault();
                        document.getElementById("phoneNumberQuoteTest").focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    className={classNames.inputField}
                    id="phoneNumberQuoteTest"
                    placeholder="Phone number..."
                    value={getQuoteDetails?.phone_number}
                    onChange={(event) => {
                      if (
                        event?.target?.value.length <= 10 &&
                        /^-?\d+$/.test(event?.target?.value)
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          phone_number: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        getQuoteDetails?.phone_number?.length === 10 &&
                        getQuoteDetails?.lead_email?.length <= 50 &&
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/.test(
                          getQuoteDetails?.lead_email
                        )
                      ) {
                        event.preventDefault();
                        setSelectedSection(currentStep?.length + 3);
                      } else if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          phone_number: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => {
                      setSelectedSection(1);
                    }}
                  >
                    Back
                  </div>
                  <div
                    style={{
                      pointerEvents:
                        getQuoteDetails?.phone_number?.length === 10 &&
                        getQuoteDetails?.lead_email?.length <= 50 &&
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          getQuoteDetails?.lead_email
                        )
                          ? ""
                          : "none",
                      opacity:
                        getQuoteDetails?.phone_number?.length === 10 &&
                        getQuoteDetails?.lead_email?.length <= 50 &&
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                          getQuoteDetails?.lead_email
                        )
                          ? ""
                          : "0.5",
                    }}
                    onClick={() => {
                      setSelectedSection(currentStep?.length + 3);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 3
                      ? ""
                      : selectedSection > currentStep?.length + 3
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Tell Us A Little About Your Company
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Company name.."
                    value={getQuoteDetails?.company_name}
                    onChange={(event) => {
                      if (event?.target?.value?.length <= 50) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          company_name: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") {
                        event.preventDefault();
                        document
                          .getElementById("noOfEmployeesQuoteTest")
                          .focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    id="noOfEmployeesQuoteTest"
                    className={classNames.inputField}
                    placeholder="Number of employees..."
                    value={getQuoteDetails?.company_size}
                    onChange={(event) => {
                      console.log(event.nativeEvent.data, "event.key ");

                      if (
                        event?.target?.value.length <= 5 &&
                        /^-?\d+$/.test(event?.target?.value)
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          company_size: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        getQuoteDetails?.company_size >= 10 &&
                        getQuoteDetails?.company_size <= 99999 &&
                        getQuoteDetails?.company_name &&
                        getQuoteDetails?.company_name?.length <= 50
                      ) {
                        event.preventDefault();
                        setSelectedSection(currentStep?.length + 4);
                      } else if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          company_size: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div onClick={() => setSelectedSection(2)}>Back</div>
                  <div
                    style={{
                      pointerEvents:
                        getQuoteDetails?.company_size >= 10 &&
                        getQuoteDetails?.company_size <= 99999 &&
                        getQuoteDetails?.company_name &&
                        getQuoteDetails?.company_name?.length <= 50
                          ? ""
                          : "none",
                      opacity:
                        getQuoteDetails?.company_size >= 10 &&
                        getQuoteDetails?.company_size <= 99999 &&
                        getQuoteDetails?.company_name &&
                        getQuoteDetails?.company_name?.length <= 50
                          ? ""
                          : "0.5",
                    }}
                    onClick={() => setSelectedSection(currentStep?.length + 4)}
                  >
                    Next
                  </div>
                </div>
              </div>

              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 4
                      ? ""
                      : selectedSection > currentStep?.length + 4
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Enter your company’s pincode
                </div>
                {/* <div className={classNames.inputContainer}>
                  <EachCustomDropdown
                    title="State"
                    dropdown={indianStatesDropdown}
                    name="state"
                    stateValue={getQuoteDetails}
                    setState={setGetQuoteDetails}
                  />
                </div> */}
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Enter Pincode"
                    value={getQuoteDetails?.pin_code}
                    onChange={(event) => {
                      if (
                        event?.target?.value.length <= 6 &&
                        /^-?\d+$/.test(event?.target?.value)
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          pin_code: event?.target?.value,
                        });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        getQuoteDetails?.pin_code?.length == 6 &&
                        /^-?\d+$/.test(getQuoteDetails?.pin_code)
                      ) {
                        event.preventDefault();
                        createQuote();
                      } else if (
                        event?.target?.value.length == 1 &&
                        event.key === "Backspace"
                      ) {
                        setGetQuoteDetails({
                          ...getQuoteDetails,
                          pin_code: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div onClick={() => setSelectedSection(3)}>Back</div>
                  <div
                    style={{
                      pointerEvents:
                        getQuoteDetails?.pin_code?.length == 6 &&
                        /^-?\d+$/.test(getQuoteDetails?.pin_code)
                          ? ""
                          : "none",
                      opacity:
                        getQuoteDetails?.pin_code?.length == 6 &&
                        /^-?\d+$/.test(getQuoteDetails?.pin_code)
                          ? ""
                          : "0.5",
                    }}
                    onClick={createQuote}
                  >
                    Next
                  </div>
                </div>
              </div>

              {/* <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 5
                      ? ""
                      : selectedSection > currentStep?.length + 5
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Were you referred by a FMA partner?
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Enter partners email"
                    value={getQuoteDetails?.reference_email}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        reference_email: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length - 5)}
                  >
                    Back
                  </div>
                  <div onClick={createQuote}>Next</div>
                </div>
              </div> */}
            </div>

            <div
              className={classNames.successMessage}
              style={{ display: selectedSection == 100 ? "" : "none" }}
            >
              <img src={yesIcon} alt="yesIcon" />
              <div className={classNames.title}>
                Thank You! Your Request Has <br />
                Been Submitted
              </div>
              <div className={classNames.desc}>Here Are Your Next Steps</div>
              <ol className={classNames.stepsList}>
                <li>
                  You will have received temporary login credentials to{" "}
                  {getQuoteDetails?.lead_email}
                </li>
                <li>
                  <span>
                    Please use those to login to our{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://talltree.coverfi.app/employer/login",
                          "_blank"
                        )
                      }
                    >
                      Employers App
                    </span>{" "}
                    and add the exact{" "}
                  </span>
                  <br />
                  <span>
                    coverage requirements you wish to obtain a quote for.{" "}
                  </span>
                </li>
              </ol>
            </div>
            <div
              className={classNames.loadingMessage}
              style={{ display: selectedSection == 99 ? "" : "none" }}
            >
              <img src={rahaFullLogo} alt="rahaFullLogo" />
              <div>Please wait while we process your request</div>
            </div>
          </div>
          <div
            className={classNames.rightDiv}
            onClick={() => {
              console.log(getQuoteDetails, "object API value");
            }}
          >
            <div className={classNames.topDivider}>
              <img src={rahabot} alt="rahabot" />
            </div>
            <div className={classNames.bottomContent}>
              <div className={classNames.name}>Hi, I'm The RahaBot</div>
              <div className={classNames.desc}>
                Feel free to reach out if you have any questions about your Tall
                Tree journey.
              </div>
              <div className={classNames.btnsContainer}>
                <div>Live Chat</div>
                <div>
                  <img src={whatsappIcon} alt="whatsappIcon" />
                  <span>WhatsApp Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.bottomSection}></div>
    </div>
  );
};

export default GetQuoteTest;

const EachInput = ({ title, placeholder, name, stateValue, setState }) => {
  return (
    <div className={classNames.eachInput}>
      <div className={classNames.title}>{title}</div>
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        onChange={(event) => {
          setState({ ...stateValue, [name]: event?.target?.value });
        }}
      />
    </div>
  );
};

const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionImg, setSelectedOptionimg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option, img) => {
    setSelectedOption(option);
    setSelectedOptionimg(img);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      // style={{ pointerEvents: "none" }}
    >
      {/* <div className={classNames.title}>{title}</div> */}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: isOpen
            ? "0"
            : // : title == "Select Brokerage" || title == "Select Associate"
              // ? "0 !important"
              "",
          bottom: isOpen ? "auto" : "",
          zIndex: isOpen ? "5" : "",
          background: isOpen ? "#e5e5e5" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {selectedOptionImg && <img src={selectedOptionImg} alt="" />}
            {selectedOption
              ? selectedOption
              : title == "Select Brokerage"
              ? "Search one of your brokerage.."
              : title == "Job Type" || title == "Select User"
              ? "Click To Select"
              : "Click To Select"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {title == "State"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.state);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.state,
                          });
                        }}
                        key={eachitem?.state + index}
                        style={{
                          display: eachitem?.state === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem?.state}
                      </li>
                    );
                  })
              : title == "Number Of Living In-Laws" ||
                title == "Number Of Living Parents" ||
                title == "Number Of Children" ||
                title == "Martial Status"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : title == "Select Brokerage"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.brokerage_name,
                            eachitem?.brokerage_icon
                          );
                          setState(eachitem?.brokerage_username);
                        }}
                        key={eachitem?.brokerage_name + index}
                        style={{
                          display:
                            eachitem?.brokerage_username === stateValue
                              ? "none"
                              : "",
                        }}
                      >
                        <img
                          src={eachitem?.brokerage_icon}
                          alt={eachitem?.brokerage_name}
                        />
                        {eachitem?.brokerage_name}
                      </li>
                    );
                  })
              : title == "Select User"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.first_name + eachitem?.last_name,
                            eachitem?.profile_picture
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_email,
                          });
                        }}
                        key={eachitem?.first_name + index}
                      >
                        <img
                          src={eachitem?.profile_picture}
                          alt={eachitem?.first_name}
                        />
                        <div>
                          <div>
                            {eachitem?.first_name} &nbsp; {eachitem?.last_name}
                          </div>
                          <div>{eachitem?.user_email}</div>
                        </div>
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name, eachitem?.app_icon);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"Select Brokerage"}
              >
                {title == "Select Brokerage"
                  ? "Search one of your brokerage.."
                  : "Select an option"}
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
