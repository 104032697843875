import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./input.module.scss";

//assets
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { AppContext } from "../../context/AppContext";
import { uploadFileFunc } from "../../utils/imageUpload";
import uploading from "../../assest/images/icons/uploading.svg";
import cloudWhite from "../../assest/images/icons/cloudWhite.svg";
import { MdOutlineFileUpload } from "react-icons/md";
import flatpickr from "flatpickr";
import asteriskIcon from "../../assest/images/icons/asterisk.svg";
import calendarIcon from "../../assest/images/icons/calendar.svg";
import Axios from "axios";
import { SinglePolicyCards } from "../../pages/Agency/CDBalance/PolicyDetails";
import { BulkAssignPoliciesEmployeeList } from "../../pages/Coverfi/HR/forms/MembersForms";
import { predefinedToast } from "../../utils/toast";

export const EachCustomInput = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  image,
  indexx,
  asteriskIconn,
  validation,
  maxAmount,
}) => {
  const [emailValidation, setEmailValidation] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  function isEmailAlreadyExist(email) {
    Axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
    )
      .then((response) => {
        console.log(response.data?.count, "is EmailAlreadyExist response");
        if (response?.data?.count) {
          if (!emailValidation) {
            console.log("positive response");
            setEmailValidation((prev) => !prev);
          }
        } else {
          if (emailValidation) {
            console.log("negative response");
            setEmailValidation((prev) => !prev);
          }
        }
      })
      .catch((error) => {
        console.log(error?.message, "is email exist API error");
      });
  }

  return (
    <div className={classNames.eachInput}>
      {title && <div className={classNames.title}>{title}</div>}
      <div
        style={{
          border: image ? "1px solid #e5e5e566" : "",
          borderRadius: image ? "10px" : "",
          paddingLeft: image ? "1rem" : "",
          width: image ? "100%" : "",
        }}
      >
        {image && <img src={image} alt={image} />}{" "}
        <input
          className={classNames.inputContainer}
          placeholder={placeholder}
          name={name}
          type={maxAmount ? "number" : "text"}
          max={maxAmount ? maxAmount : undefined}
          value={
            indexx
              ? stateValue[name][indexx]
              : name
              ? stateValue?.hasOwnProperty(name)
                ? stateValue[name]
                : ""
              : stateValue
          }
          style={{
            border:
              validation?.type?.includes("email") && emailValidation
                ? "1px solid red"
                : validation?.type?.includes("email") &&
                  !stateValue[name]?.length <= 50 &&
                  !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{1,3}$/?.test(
                    stateValue[name]
                  ) &&
                  isFocused
                ? "1px solid red"
                : image
                ? "none"
                : "",
          }}
          onChange={(event) => {
            if (maxAmount) {
              if (
                /^-?\d+$/.test(event?.target?.value) &&
                event?.target?.value <= maxAmount
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              }
            } else if (
              validation?.type?.includes("employee_size") ||
              validation?.type?.includes("email") ||
              validation?.type?.includes("name_validation") ||
              validation?.type?.includes("phone_validation") ||
              validation?.type?.includes("company_name_validation") ||
              validation?.type?.includes("pincode_validation")
            ) {
              if (
                event?.target?.value.length <= 5 &&
                /^-?\d+$/.test(event?.target?.value) &&
                validation?.type == "employee_size"
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              } else if (
                validation?.type?.includes("email") &&
                event?.target?.value.length <= 50
              ) {
                isEmailAlreadyExist(event?.target?.value);
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              } else if (
                validation?.type?.includes("name_validation") &&
                event?.target?.value.length <= 30 &&
                /^[A-Za-z\s\.]+$/.test(event?.target?.value)
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              } else if (
                validation?.type?.includes("phone_validation") &&
                event?.target?.value.length <= 10 &&
                /^-?\d+$/.test(event?.target?.value)
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              } else if (
                validation?.type?.includes("company_name_validation") &&
                event?.target?.value.length <= 100
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              } else if (
                validation?.type?.includes("pincode_validation") &&
                event?.target?.value.length <= 6 &&
                /^-?\d+$/.test(event?.target?.value)
              ) {
                setState({
                  ...stateValue,
                  [name]: event?.target?.value,
                });
              }
            } else if (!validation) {
              if (indexx) {
                let array = stateValue[name];
                array[Number(indexx)] = event?.target?.value;
                console.log(array, "array valuee");
                setState({ ...stateValue, [name]: array });
              } else {
                setState({ ...stateValue, [name]: event?.target?.value });
              }
            } else if (validation) {
              if (
                validation?.type === "length" &&
                event?.target?.value?.length <= validation?.length
              ) {
                if (indexx) {
                  let array = stateValue[name];
                  array[Number(indexx)] = event?.target?.value;
                  console.log(array, "array valuee");
                  setState({ ...stateValue, [name]: array });
                } else {
                  setState({ ...stateValue, [name]: event?.target?.value });
                }
              }
            }
          }}
          onKeyDown={(event) => {
            if (
              validation?.type?.includes("employee_size") ||
              validation?.type?.includes("name_validation") ||
              validation?.type?.includes("phone_validation") ||
              validation?.type?.includes("pincode_validation") ||
              maxAmount
            ) {
              if (
                event?.target?.value.length == 1 &&
                event.key === "Backspace"
              ) {
                setState({
                  ...stateValue,
                  [name]: "",
                });
              }
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

export const EachCustomTextArea = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  name,
  typee,
}) => {
  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <textarea
          type="text"
          name=""
          placeholder={placeholder}
          value={name ? stateValue[name] : stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
          onChange={(event) => {
            event.persist();
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event.target.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
      </div>
    </div>
  );
};

export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  typee,
  placeholder,
  indexValue,
  objName,
  mapName,
  option,
  currentValue,
  indexx,
  asteriskIconn,
  typeee,
  noopen,
  selectedEmployee,
  allEmployees,
  refreshPage,
  apiVal,
  setLocalRefresh,
  mapNameType,
  isAlreadyThere,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [otherSubCards, setOtherSubCards] = useState({});
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  //function

  async function updateRoleCosting(obj) {
    try {
      let response = await Axios.put(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/update/${apiVal}`,
        obj
      );
      setLocalRefresh((prev) => !prev);
      if (response?.data?.status) {
        predefinedToast("Updated role...");
      }
    } catch (error) {
      console.log(error?.message, "Update role costing error");
    }
  }

  //renderings

  useEffect(() => {
    if (isAlreadyThere) {
      setSelectedOption(stateValue[name]);
    }
  }, [isAlreadyThere]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth:
          otherSubCards?.type === "Bulk Assign Employees List" &&
          typee === "addPolicyToAll"
            ? "600px"
            : typee === "addPolicyToAll" && isOpen
            ? "500px"
            : noopen
            ? ""
            : isOpen
            ? "250px"
            : "",
        zIndex:
          otherSubCards?.type === "Bulk Assign Employees List" &&
          typee === "addPolicyToAll"
            ? "7"
            : "",
      }}
    >
      {otherSubCards?.type === "Bulk Assign Employees List" ? (
        <div
          className={classNames.inputContainer}
          style={{
            pointerEvents: dropdown?.length < 1 ? "none" : "",
            zIndex: "3",
            maxHeight: typee === "addPolicyToAll" ? "40vh" : "",
          }}
          ref={dropdownRef}
        >
          <span
            onClick={() => {
              setIsOpen(false);
              setOtherSubCards({});
            }}
          >
            <span>
              {mapName?.length > 0 && Array.isArray(mapName) && stateValue[name]
                ? ` ${
                    objName == "grade"
                      ? "Grade " + stateValue[name][objName]
                      : stateValue[name][mapName[objName]]
                  }`
                : option === "alreadySet"
                ? name && indexx
                  ? stateValue[name][indexx]
                  : stateValue[name] === true
                  ? "Applicable"
                  : stateValue[name] === false
                  ? "Not Applicable"
                  : stateValue[name]
                : option === "checkValue" && stateValue[name]
                ? stateValue[name]
                : selectedOption
                ? selectedOption
                : title
                ? title
                : placeholder
                ? placeholder
                : "Select an option"}
            </span>
            <span
              style={{ visibility: typeee === "removeArrow" ? "hidden" : "" }}
            >
              {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </span>
          </span>
          <ul
            className="dropdown-list"
            style={{ height: typee === "addPolicyToAll" ? "50vh" : "" }}
          >
            <li>
              <BulkAssignPoliciesEmployeeList
                policyNum={otherSubCards?.data?.policyNum}
                companyId={otherSubCards?.data?.companyId}
                hrId={otherSubCards?.data?.hrId}
                allPolicies={dropdown}
                stateValue={isOpen}
                setState={setIsOpen}
              />
            </li>
          </ul>
        </div>
      ) : (
        <div
          className={classNames.inputContainer}
          onClick={toggleDropdown}
          style={{
            pointerEvents: dropdown?.length < 1 ? "none" : "",
            zIndex: isOpen ? "3" : "",
            maxHeight: typee === "addPolicyToAll" ? "50vh" : "",
          }}
          ref={dropdownRef}
        >
          <span>
            <span>
              {objName === "_id" && selectedOption
                ? selectedOption
                : objName === "_id" && name === "user_reporting_manager_id"
                ? placeholder
                : mapName?.length > 0 &&
                  Array.isArray(mapName) &&
                  stateValue[name]
                ? ` ${
                    objName == "grade"
                      ? "Grade " + stateValue[name][objName]
                      : stateValue[name][mapName[objName]]
                  }`
                : option === "alreadySet"
                ? name && indexx
                  ? stateValue[name][indexx]
                  : stateValue[name] === true
                  ? "Applicable"
                  : stateValue[name] === false
                  ? "Not Applicable"
                  : stateValue[name]
                : option === "checkValue" && stateValue[name]
                ? stateValue[name]
                : selectedOption
                ? selectedOption
                : title
                ? title
                : placeholder
                ? placeholder
                : "Select an option"}
            </span>
            <span
              style={{ visibility: typeee === "removeArrow" ? "hidden" : "" }}
            >
              {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </span>
          </span>
          {isOpen && (
            <ul
              className="dropdown-list"
              style={{ height: typee === "addPolicyToAll" ? "50vh" : "" }}
            >
              <li
                style={{
                  display:
                    dropdown?.length <= 4 || placeholder == "Relation"
                      ? "none"
                      : "",
                }}
              >
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search.."
                  value={searchQuery}
                  onChange={handleInputChange}
                  onClick={(event) => event.stopPropagation()}
                />
              </li>
              {typee === "arrayObj" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      return row
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase());
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            setState((prev) => {
                              return prev?.map((item, i) => {
                                if (i === indexValue) {
                                  return { ...item, [name]: eachitem };
                                }
                                return item;
                              });
                            });
                          }}
                          key={eachitem + index}
                        >
                          {eachitem}
                        </li>
                      );
                    })
                : typee === "single" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                      return row?.toLowerCase()?.includes(searchQueryy);
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            console.log(eachitem, "eachitem", name);

                            if (name) {
                              setState({
                                ...stateValue,
                                [name]: eachitem,
                              });
                              // setState((prev) => {
                              //   return { ...prev, [name]: eachitem };
                              // });
                            } else {
                              setState(eachitem);
                            }
                          }}
                          key={eachitem + index}
                          style={{
                            display: eachitem === stateValue ? "none" : "",
                          }}
                        >
                          {eachitem}
                        </li>
                      );
                    })
                : typee === "objVal" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            console.log(eachitem[mapName[0]]);
                            selectOption(
                              objName === "_id" &&
                                name === "user_reporting_manager_id"
                                ? eachitem[mapName[0]]
                                : mapName?.length > 0 && Array.isArray(mapName)
                                ? mapName[0]
                                : mapName
                                ? eachitem[mapName]
                                : eachitem[objName]
                            );
                            if (
                              mapName?.length > 0 &&
                              Array.isArray(mapName) &&
                              objName === "_id" &&
                              name === "user_reporting_manager_id"
                            ) {
                              setState((prev) => {
                                return {
                                  ...prev,
                                  [name]: objName
                                    ? eachitem[objName]
                                    : eachitem,
                                };
                              });
                            } else if (
                              mapName?.length > 0 &&
                              Array.isArray(mapName)
                            ) {
                              setState((prev) => {
                                return { ...prev, [name]: eachitem };
                              });
                            } else {
                              setState({
                                ...stateValue,
                                [name]: eachitem[objName],
                              });
                            }
                          }}
                          key={eachitem[objName] + index}
                          style={{
                            flexDirection:
                              mapNameType === "flex"
                                ? ""
                                : mapName?.length > 0 && Array.isArray(mapName)
                                ? "column"
                                : "",
                            alignItems:
                              mapName?.length > 0 && Array.isArray(mapName)
                                ? "flex-start"
                                : "",
                            gap: mapNameType === "flex" ? "4px" : "",
                            marginBottom: mapNameType === "flex" ? "0" : "",
                          }}
                        >
                          {/* <img src={eachitem} alt={eachitem} /> */}
                          {mapName?.length > 0 && Array.isArray(mapName)
                            ? mapName?.map((eachItem) => {
                                return (
                                  <div key={eachitem[eachItem] + index}>
                                    {objName === "grade" && eachItem == "grade"
                                      ? `Grade ${eachitem[eachItem]}`
                                      : eachitem[eachItem]}
                                  </div>
                                );
                              })
                            : mapName
                            ? eachitem[mapName]
                            : eachitem[objName]}
                        </li>
                      );
                    })
                : typee === "obj" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            setState({
                              ...stateValue,
                              [name]: eachitem,
                            });
                          }}
                          key={eachitem + index}
                        >
                          <img src={eachitem} alt={eachitem} />
                          {eachitem}
                        </li>
                      );
                    })
                : typee === "costCenter" && dropdown?.length > 0
                ? dropdown?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.user_id?.user_email_id);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.user_id?.user_email_id,
                          });
                          console.log(eachitem, "Select role");
                          updateRoleCosting({
                            user_role_mapping_id: eachitem?._id,
                            user_id: eachitem?.user_id?._id,
                            role_id: eachitem?.role_id?._id,
                          });
                        }}
                        key={eachitem?.user_id?.user_email_id + index}
                      >
                        {eachitem?.user_id?.user_email_id}
                      </li>
                    );
                  })
                : title === "All countries"
                ? dropdown?.length > 0 &&
                  dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                      return row?.toLowerCase()?.includes(searchQueryy);
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            setState(eachitem);
                          }}
                          key={eachitem + index}
                          style={{
                            display: eachitem === stateValue ? "none" : "",
                          }}
                        >
                          {eachitem}
                        </li>
                      );
                    })
                : title == "All States"
                ? dropdown?.length > 0 &&
                  dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem?.branchAddressState);
                            setState(eachitem?.branchAddressState);
                          }}
                          key={eachitem?.branchAddressState + index}
                        >
                          {eachitem?.branchAddressState}
                        </li>
                      );
                    })
                : name == "allCompanies"
                ? dropdown?.length > 0 &&
                  dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem?.displayName);
                            setState(eachitem?.displayName);
                          }}
                          key={eachitem?.displayName + index}
                        >
                          {eachitem?.displayName}
                        </li>
                      );
                    })
                : typee === "addPolicyToAll" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        // <li
                        //   onClick={(event) => {
                        //     event.stopPropagation();
                        //     selectOption(eachitem);
                        //     setState({
                        //       ...stateValue,
                        //       [name]: eachitem,
                        //     });
                        //   }}
                        //   key={eachitem + index}
                        // >
                        //   <img src={eachitem} alt={eachitem} />
                        //   {eachitem}
                        // </li>
                        <SinglePolicyCards
                          type="addPolicyToAll"
                          item={eachitem}
                          selectedEmployee={selectedEmployee}
                          allEmployees={allEmployees}
                          refreshPage={refreshPage}
                          name={name}
                          setOtherSubCards={setOtherSubCards}
                        />
                      );
                    })
                : dropdown?.length > 0 &&
                  dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem?.app_name);
                            setState({
                              ...stateValue,
                              [name]: eachitem?.app_code,
                            });
                          }}
                          key={eachitem?.app_name + index}
                        >
                          <img
                            src={eachitem?.app_icon}
                            alt={eachitem?.app_name}
                          />
                          {eachitem?.app_name}
                        </li>
                      );
                    })}
              {/* {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"allCompanies"}
              >
                All Companies
              </li>
            ) : (
              ""
            )} */}
            </ul>
          )}
        </div>
      )}

      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

export const EachCustomDocumentUpload = ({
  title,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const [fileName, setFileName] = useState("");
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={`${classNames.imageUploadDiv} ${classNames.eachDocumentUpload}`}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{title}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
        }}
        htmlFor={"profileUpdateImgPlanB" + idd}
      >
        <img src={cloudWhite} alt="cloudWhite" />
        <span>{fileName?.name ? fileName?.name : "Upload Document"}</span>
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanB" + idd}
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadFileFunc(
              e,
              setFileName,
              setplanBAccountPicUploading,
              "policy_document"
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="*/*"
          id={"profileUpdateImgPlanB" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const EachCustomDocumentUploadPlain = ({
  title,
  setState,
  stateValue,
  uploadIcon,
  name,
  idd,
}) => {
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const [fileName, setFileName] = useState("");
  const {
    // planBAccountPicUploading,
    // setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(AppContext);
  return (
    <div
      className={`${classNames.imageUploadDiv} ${classNames.eachDocumentUpload}`}
      onClick={() => setSelectedDropDown("")}
    >
      <div className={classNames.heading}>{title}</div>
      <label
        className={classNames.imageDiv}
        style={{
          display: uploadIcon ? "flex" : "",
          alignItems: uploadIcon ? "center" : "",
          justifyContent: uploadIcon ? "center" : "",
          border: uploadIcon ? "1px solid #E7E7E7" : "",
          borderRadius: uploadIcon ? "50%" : "",
          width: "max-content",
          background: "unset",
          padding: "1rem 0",
          gap: "15px",
          color: "var(--font-color)",
        }}
        htmlFor={"profileUpdateImgPlanB" + idd}
      >
        <MdOutlineFileUpload style={{ fontSize: "1.5rem" }} />
        <span style={{ color: "var(--font-color)", fontSize: "0.9rem" }}>
          {fileName?.name ? fileName?.name : "Upload Document"}
        </span>
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ""
        )}
      </label>
      <label
        htmlFor={"profileUpdateImgPlanB" + idd}
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={async (e) => {
            let resImage = uploadFileFunc(
              e,
              setFileName,
              setplanBAccountPicUploading,
              "policy_document"
            );
            setState({
              ...stateValue,
              [name]: await resImage,
            });
            console.log(await resImage, "resImage await");
          }}
          accept="*/*"
          id={"profileUpdateImgPlanB" + idd}
        />
        <div>{planBAccountPicUploading ? "Uploading..." : ""}</div>
      </label>
    </div>
  );
};

export const EachCustomDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  typee,
  indexValue,
  asteriskIconn,
  values,
  validation,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (
      (title?.toLowerCase()?.includes("birth") ||
        placeholder?.toLowerCase()?.includes("birth")) &&
      datepickerRef.current
    ) {
      const currentDate = new Date();
      const minDate = new Date(currentDate);
      const maxDate = new Date(currentDate);

      if (validation?.type == "relationship") {
        if (
          values?.state?.relationship == "Spouse" ||
          values?.state?.relation == "Spouse"
        ) {
          minDate.setFullYear(currentDate.getFullYear() - 64);
          maxDate.setFullYear(currentDate.getFullYear() - 18);
        } else if (
          values?.state?.relationship == "Son" ||
          values?.state?.relationship == "Daughter" ||
          values?.state?.relationship == "Children" ||
          values?.state?.relation == "Son" ||
          values?.state?.relation == "Daughter" ||
          values?.state?.relation == "Children"
        ) {
          minDate.setFullYear(currentDate.getFullYear() - 25);
          maxDate.setFullYear(currentDate.getFullYear() - 0);
        } else if (
          values?.state?.relationship == "Father" ||
          values?.state?.relationship == "Mother" ||
          values?.state?.relationship == "Father-in-Law" ||
          values?.state?.relationship == "Mother-in-Law" ||
          values?.state?.relation == "Father" ||
          values?.state?.relation == "Mother" ||
          values?.state?.relation == "Father-in-Law" ||
          values?.state?.relation == "Mother-in-Law"
        ) {
          minDate.setFullYear(currentDate.getFullYear() - 100);
          maxDate.setFullYear(currentDate.getFullYear() - 0);
        } else {
          // Calculate minDate for a 80-year-old person
          minDate.setFullYear(currentDate.getFullYear() - 80);

          // Calculate maxDate for a 25-year-old person
          maxDate.setFullYear(currentDate.getFullYear() - 18);
        }
      } else {
        // Calculate minDate for a 80-year-old person
        minDate.setFullYear(currentDate.getFullYear() - 80);

        // Calculate maxDate for a 25-year-old person
        maxDate.setFullYear(currentDate.getFullYear() - 25);
      }
      console.log("Coming inside first data ref");
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        minDate: minDate,
        maxDate: maxDate,
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef.current);
          if (typee == "spreadObj") {
            setState((prev) => {
              return {
                ...prev,
                [name]: dateStr,
              };
            });
          } else if (typee === "arrayObj") {
            setState((prev) => {
              return prev?.map((item, i) => {
                if (i === indexValue) {
                  return { ...item, [name]: dateStr };
                }
                return item;
              });
            });
          } else if (name) {
            setState({
              ...stateValue,
              [name]: dateStr,
            });
            // setState((prev) => {
            //   return { ...prev, [name]: dateStr };
            // });
          } else {
            setState(dateStr);
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    } else if (datepickerRef.current) {
      console.log("Coming inside second data ref");
      const options = {
        dateFormat: "d-m-Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:");
          if (typee == "spreadObj") {
            setState((prev) => {
              return {
                ...prev,
                [name]: dateStr,
              };
            });
          } else if (typee === "arrayObj") {
            setState((prev) => {
              return prev?.map((item, i) => {
                if (i === indexValue) {
                  return { ...item, [name]: dateStr };
                }
                return item;
              });
            });
          } else if (name) {
            setState({
              ...stateValue,
              [name]: dateStr,
            });
            // setState((prev) => {
            //   return { ...prev, [name]: dateStr };
            // });
          } else {
            setState(dateStr);
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, [values]);

  return (
    <div
      className={classNames.eachInputDatePicker}
      style={{
        pointerEvents:
          validation?.type == "relationship" &&
          validation?.relationship == "self"
            ? ""
            : validation?.type == "relationship"
            ? values?.state?.relationship || values?.state?.relation
              ? ""
              : "none"
            : "",
      }}
    >
      {title && (
        <div className={classNames.title}>
          {title}
          {mandatory && <img src={asteriskIcon} alt="asteriskIcon" />}
        </div>
      )}
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        name={name}
        value={name ? stateValue[name] : stateValue ? stateValue : ""}
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
      <img
        src={calendarIcon}
        alt="calendarIcon"
        className={classNames.calendarIcon}
      />
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};

export const EachCustomSelectOne = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  dropdown,
}) => {
  const [selectedValue, setSelectedvalue] = useState("");
  return (
    <div className={classNames.eachInputSelectOne}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.optionParent}>
        {dropdown?.length > 0 &&
          dropdown?.map((eachItem, index) => {
            return (
              <div
                className={`${classNames.eachOption} ${
                  selectedValue === eachItem ? classNames.selectedOption : ""
                }`}
                key={eachItem + index}
                onClick={() => {
                  if (name) {
                    setSelectedvalue(eachItem);
                    setState((prev) => {
                      return { ...prev, [name]: eachItem };
                    });
                  } else {
                    setState(eachItem);
                    setSelectedvalue(eachItem);
                  }
                }}
              >
                {eachItem}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EachCustomTextAreaGeneral = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
  name,
  typee,
}) => {
  return (
    <div className={classNames.eachProfileInputGeneral}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <textarea
          type="text"
          name=""
          placeholder={placeholder}
          value={name ? stateValue[name] : stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
          onChange={(event) => {
            event.persist();
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event.target.value };
              });
            } else {
              setState(event.target.value);
            }
          }}
        />
      </div>
    </div>
  );
};

export const EachCustomDropdownInitiateMultipleHealth = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  typee,
  placeholder,
  indexValue,
  objName,
  mapName,
  option,
  currentValue,
  indexx,
  asteriskIconn,
  typeee,
  noopen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{ minWidth: noopen ? "" : isOpen ? "250px" : "" }}
    >
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown?.length < 1 ? "none" : "",
          zIndex: isOpen ? "3" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {option === "alreadySet"
              ? name && indexx
                ? stateValue[name][indexx]
                : stateValue[name] === true
                ? "Applicable"
                : stateValue[name] === false
                ? "Not Applicable"
                : stateValue[name]
              : option === "checkValue" && stateValue[name]
              ? stateValue[name]
              : selectedOption
              ? selectedOption
              : title
              ? title
              : placeholder
              ? placeholder
              : "Select an option"}
          </span>
          <span
            style={{ visibility: typeee === "removeArrow" ? "hidden" : "" }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <li
              style={{
                display:
                  dropdown?.length <= 4 || placeholder == "Relation"
                    ? "none"
                    : "",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {typee === "objVal" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem?.policy_id?.insurance_company_id
                              ?.insurance_company_display_name +
                              " (" +
                              eachitem?.policy_type +
                              ") " +
                              eachitem?.policy_number
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem["policy_id"][objName],
                          });
                          console.log(
                            eachitem["policy_id"][objName],
                            "selected policy id",
                            eachitem
                          );
                        }}
                        key={eachitem[objName] + index}
                      >
                        {eachitem?.policy_id?.insurance_company_id
                          ?.insurance_company_display_name +
                          " (" +
                          eachitem?.policy_type +
                          ") " +
                          eachitem?.policy_number}
                      </li>
                    );
                  })
              : ""}
          </ul>
        )}
      </div>
      {asteriskIconn && (
        <img
          src={asteriskIcon}
          alt="asteriskIcon"
          className={classNames.asteriskIcon}
        />
      )}
    </div>
  );
};
