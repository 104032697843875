import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./quoteemployer.module.scss";

import Navbar from "../../components/navbar";
import Axios from "axios";
import { predefinedToastWhite } from "../../utils/toast";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import yesIcon from "../../assest/images/icons/yes.svg";
import noIcon from "../../assest/images/icons/no.svg";

import rahabot from "../../assest/images/draft/rahabot.svg";
import whatsappIcon from "../../assest/images/icons/whatsapp.svg";

import uploadFileIcon from "../../assest/images/icons/uploadfile.svg";
import { uploadFileFunc } from "../../utils/imageUpload";

//assets icon
import { ReactComponent as PropertyIcon } from "../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../assest/images/profile/world.svg";
import { BankContext } from "../../context/Context";
import { LoadingAnimationRaha } from "../../components/Custom";

import rahaFullLogo from "../../assest/images/fullLogo.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeFirstLetters } from "../../utils/FunctionTools";
import MobileNavbar from "../../components/navbar/mobile";

const GetQuoteEmployer = ({
  setClaimsApi,
  setSelectedProfileNav,
  setRefreshLocal,
  setSelectedSwitch,
}) => {
  const history = useHistory();
  const { email, isScrolled, setIsScrolled, usersDataGlobal } =
    useContext(BankContext);
  const [indianStatesDropdown, setIndianStatesDropdown] = useState("");
  const [selectedSection, setSelectedSection] = useState(0);

  const [currentStep, setCurrentStep] = useState([]);

  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [uploadFileRFQLoading, setUploadFileRFQLoading] = useState("");

  const [getQuoteDetails, setGetQuoteDetails] = useState({
    email_id: email,
    what_are_you_looking_for: [],
  });

  function createQuote() {
    setSelectedSection(99);
    const uniqueArray = [...new Set(getQuoteDetails?.what_are_you_looking_for)];
    const {
      external_policy_health,
      external_policy_life,
      external_policy_accidental,
    } = getQuoteDetails;

    const updatedArray = uniqueArray?.map((item) => {
      if (item === "health") {
        return "Health";
      } else if (item === "life") {
        return "Life";
      } else if (item === "accident") {
        return "Accidental";
      } else {
        // Handle other cases if needed
        return item;
      }
    });

    let finalObj = {
      ...getQuoteDetails,
      what_are_you_looking_for: updatedArray,
      company_id: usersDataGlobal?.hrDetails?.hrCompanyDetails?._id,
    };

    // console.log("getQuoteDetails final", finalObj);
    Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/policy/create/quote`,
      finalObj
    )
      .then((response) => {
        // console.log(response?.data?.status, "create quote");
        if (response?.data?.status) {
          setSelectedSection(0);
          predefinedToastWhite(response?.data?.message);
        } else {
          setSelectedSection(0);
          predefinedToastWhite(response?.data?.message);
        }
        setClaimsApi((prev) => {
          if (prev === "emptyPolicyBox") {
            return "emptyPolicyBox";
          }
        });
        setSelectedSwitch("Quote Manager");
        setRefreshLocal((prev) => !prev);
        // setTimeout(() => {
        //   // setSelectedSection("Quotes");
        // }, 1500);
      })
      .catch((error) => {
        console.log(error?.message, "create quote API error");
        setSelectedSection(0);
      });
  }

  useEffect(() => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/general/getstates`
    )
      .then((response) => {
        // console.log(response, "state dropdown response");
        if (response?.data?.status) {
          setIndianStatesDropdown(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error?.message, "state dropdown error");
      });
  }, []);

  const handleTabKeyPress = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault(); // Prevent the default tab behavior
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleTabKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleTabKeyPress);
    };
  }, []);

  useEffect(() => {
    const homeElement = document.getElementById("quote");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${classNames.quote} ${classNames.quotesDashboard}`}
      id="quote"
    >
      <div className={classNames.topSection}>
        <div className={classNames.quoteFormContainer}>
          <div className={classNames.leftDiv}>
            <div className={classNames.bottomDiv}>
              {/* select selected coverages questions */}
              <div
                className={classNames.transitionSection}
                style={{
                  visibility: selectedSection == 1 ? "hidden" : "",
                  transform:
                    selectedSection == 0
                      ? ""
                      : selectedSection > 0
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                  overflowY: "scroll",
                }}
              >
                <div className={classNames.title}>
                  What type of coverage are you looking for?
                </div>
                <div className={classNames.inputContainer}>
                  <div className={classNames.assetsContainer}>
                    <div
                      className={`${classNames.eachAsset} ${
                        getQuoteDetails?.what_are_you_looking_for?.includes(
                          "health"
                        )
                          ? classNames?.selectedAsset
                          : ""
                      }`}
                      onClick={(event) => {
                        if (
                          getQuoteDetails?.what_are_you_looking_for?.includes(
                            "health"
                          )
                        ) {
                          setGetQuoteDetails((prev) => {
                            const updatedArray =
                              prev?.what_are_you_looking_for.includes("health")
                                ? prev?.what_are_you_looking_for.filter(
                                    (item) => item !== "health"
                                  )
                                : [...prev?.what_are_you_looking_for, "health"];

                            const { external_policy_health, ...prevv } = prev;
                            return {
                              ...prevv,
                              what_are_you_looking_for: updatedArray,
                            };
                          });
                        } else {
                          setGetQuoteDetails((prev) => {
                            return {
                              ...prev,
                              external_policy_health: false,
                              what_are_you_looking_for: [
                                ...prev?.what_are_you_looking_for,
                                "health",
                              ],
                            };
                          });
                        }
                      }}
                    >
                      <div>Health</div>
                      <div>
                        <PropertyIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div
                      className={`${classNames.eachAsset} ${
                        getQuoteDetails?.what_are_you_looking_for?.includes(
                          "accident"
                        )
                          ? classNames?.selectedAsset
                          : ""
                      }`}
                      onClick={(event) => {
                        if (
                          getQuoteDetails?.what_are_you_looking_for?.includes(
                            "accident"
                          )
                        ) {
                          setGetQuoteDetails((prev) => {
                            const updatedArray =
                              prev?.what_are_you_looking_for.includes(
                                "accident"
                              )
                                ? prev?.what_are_you_looking_for.filter(
                                    (item) => item !== "accident"
                                  )
                                : [
                                    ...prev?.what_are_you_looking_for,
                                    "accident",
                                  ];

                            const { external_policy_accidental, ...prevv } =
                              prev;

                            return {
                              ...prevv,
                              what_are_you_looking_for: updatedArray,
                            };
                          });
                        } else {
                          setGetQuoteDetails((prev) => {
                            return {
                              ...prev,
                              external_policy_accidental: false,
                              what_are_you_looking_for: [
                                ...prev?.what_are_you_looking_for,
                                "accident",
                              ],
                            };
                          });
                        }
                      }}
                    >
                      <div>Accident</div>
                      <div>
                        <VehicleIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div
                      className={`${classNames.eachAsset} ${
                        getQuoteDetails?.what_are_you_looking_for?.includes(
                          "life"
                        )
                          ? classNames?.selectedAsset
                          : ""
                      }`}
                      onClick={(event) => {
                        if (
                          getQuoteDetails?.what_are_you_looking_for?.includes(
                            "life"
                          )
                        ) {
                          setGetQuoteDetails((prev) => {
                            const updatedArray =
                              prev?.what_are_you_looking_for.includes("life")
                                ? prev?.what_are_you_looking_for.filter(
                                    (item) => item !== "life"
                                  )
                                : [...prev?.what_are_you_looking_for, "life"];

                            const { external_policy_life, ...prevv } = prev;

                            return {
                              ...prevv,
                              what_are_you_looking_for: updatedArray,
                            };
                          });
                        } else {
                          setGetQuoteDetails((prev) => {
                            return {
                              ...prev,
                              external_policy_life: false,
                              what_are_you_looking_for: [
                                ...prev?.what_are_you_looking_for,
                                "life",
                              ],
                            };
                          });
                        }
                      }}
                    >
                      <div>Life</div>
                      <div>
                        <WorldIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => {
                      setClaimsApi((prev) => {
                        if (prev === "emptyPolicyBox") {
                          return "emptyPolicyBox";
                        }
                      });
                      setRefreshLocal((prev) => !prev);
                    }}
                  >
                    Back
                  </div>
                  <div
                    style={{
                      pointerEvents:
                        getQuoteDetails?.what_are_you_looking_for?.length > 0
                          ? ""
                          : "none",
                      opacity:
                        getQuoteDetails?.what_are_you_looking_for?.length > 0
                          ? ""
                          : "0.5",
                    }}
                    onClick={() => {
                      // console.log(
                      //   getQuoteDetails?.what_are_you_looking_for,
                      //   "selected current step"
                      // );
                      setCurrentStep(getQuoteDetails?.what_are_you_looking_for);
                      setSelectedSection(1);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>

              {/* all cover questions */}
              {currentStep?.map((eachItem, index) => {
                return (
                  <div
                    className={classNames.transitionSection}
                    style={{
                      transform:
                        selectedSection == index + 1
                          ? ""
                          : selectedSection > index + 1
                          ? "translateX(-150%)"
                          : `translateX(150%)`,
                      overflowY: "scroll",
                    }}
                  >
                    <div
                      className={classNames.title}
                      style={{
                        display:
                          eachItem == "health" &&
                          getQuoteDetails?.external_policy_health
                            ? "none"
                            : eachItem == "accident" &&
                              getQuoteDetails?.external_policy_accidental
                            ? "none"
                            : eachItem == "life" &&
                              getQuoteDetails?.external_policy_life
                            ? "none"
                            : "",
                      }}
                    >
                      Do you already have {eachItem} insurance?
                    </div>
                    <div
                      className={classNames.inputContainer}
                      style={{
                        flexDirection: "row",
                        display:
                          eachItem == "health" &&
                          getQuoteDetails?.external_policy_health
                            ? "none"
                            : eachItem == "accident" &&
                              getQuoteDetails?.external_policy_accidental
                            ? "none"
                            : eachItem == "life" &&
                              getQuoteDetails?.external_policy_life
                            ? "none"
                            : "",
                      }}
                    >
                      <div
                        className={classNames.selectedOne}
                        onClick={() => {
                          if (eachItem == "health") {
                            setGetQuoteDetails((prev) => {
                              return { ...prev, external_policy_health: true };
                            });
                          } else if (eachItem == "accident") {
                            setGetQuoteDetails((prev) => {
                              return {
                                ...prev,
                                external_policy_accidental: true,
                              };
                            });
                          } else if (eachItem == "life") {
                            setGetQuoteDetails((prev) => {
                              return {
                                ...prev,
                                external_policy_life: true,
                              };
                            });
                          }
                        }}
                      >
                        <img src={yesIcon} alt="yesIcon" />
                        <div>Yes</div>
                      </div>
                      <div
                        className={classNames.selectedOne}
                        onClick={() => {
                          setSelectedSection(index + 2);
                          if (eachItem == "health") {
                            if (getQuoteDetails?.external_policy_health_doc) {
                              let { external_policy_health_doc, ...rest } =
                                getQuoteDetails;
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...rest,
                                  external_policy_health: false,
                                };
                              });
                            } else {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_health: false,
                                };
                              });
                            }
                          } else if (eachItem == "accident") {
                            if (
                              getQuoteDetails?.external_policy_accidental_doc
                            ) {
                              let { external_policy_accidental_doc, ...rest } =
                                getQuoteDetails;
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...rest,
                                  external_policy_accidental: false,
                                };
                              });
                            } else {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_accidental: false,
                                };
                              });
                            }
                          } else if (eachItem == "life") {
                            if (getQuoteDetails?.external_policy_life_doc) {
                              let { external_policy_life_doc, ...rest } =
                                getQuoteDetails;
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...rest,
                                  external_policy_life: false,
                                };
                              });
                            } else {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_life: false,
                                };
                              });
                            }
                          }

                          if (index == currentStep?.length - 1) {
                            createQuote();
                          }
                        }}
                      >
                        <img src={noIcon} alt="noIcon" />
                        <div>No</div>
                      </div>
                    </div>
                    <div
                      className={classNames.title}
                      style={{
                        display:
                          eachItem == "health" &&
                          getQuoteDetails?.external_policy_health
                            ? ""
                            : eachItem == "accident" &&
                              getQuoteDetails?.external_policy_accidental
                            ? ""
                            : eachItem == "life" &&
                              getQuoteDetails?.external_policy_life
                            ? ""
                            : "none",
                      }}
                    >
                      Please upload a copy of your existing policy
                    </div>
                    <div
                      className={`${classNames.changeOption}`}
                      style={{
                        padding: "0",
                        display:
                          eachItem == "health" &&
                          getQuoteDetails?.external_policy_health
                            ? ""
                            : eachItem == "accident" &&
                              getQuoteDetails?.external_policy_accidental
                            ? ""
                            : eachItem == "life" &&
                              getQuoteDetails?.external_policy_life
                            ? ""
                            : "none",
                      }}
                    >
                      <label
                        className={classNames.uploadDocument}
                        style={{ padding: "3rem" }}
                        htmlFor={"fileUploadQuote" + eachItem}
                      >
                        <img src={uploadFileIcon} alt="uploadFileIcon" />
                        <span>
                          {uploadFileRFQName?.name
                            ? uploadFileRFQName?.name
                            : "Upload"}
                        </span>
                        <input
                          className={classNames.uploadNewPicPlanB}
                          type="file"
                          style={{ display: "none" }}
                          onChange={async (e) => {
                            let resFile = uploadFileFunc(
                              e,
                              setUploadFileRFQName,
                              setUploadFileRFQLoading,
                              eachItem
                            );
                            setUploadFileRFQ(await resFile);
                            let policyFile = await resFile;
                            console.log(
                              resFile,
                              "policyFile",
                              uploadFileRFQName,
                              eachItem
                            );
                            if (eachItem == "health") {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_health_doc: policyFile,
                                };
                              });
                            } else if (eachItem == "accident") {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_accidental_doc: policyFile,
                                };
                              });
                            } else if (eachItem == "life") {
                              setGetQuoteDetails((prev) => {
                                return {
                                  ...prev,
                                  external_policy_life_doc: policyFile,
                                };
                              });
                            }
                            // console.log(await resFile, "res file link await");
                          }}
                          id={"fileUploadQuote" + eachItem}
                        />
                      </label>
                    </div>
                    <div className={classNames.btnsContainer}>
                      <div
                        onClick={() => {
                          setSelectedSection(index);
                        }}
                      >
                        Back
                      </div>
                      <div
                        onClick={() => {
                          if (index == currentStep?.length - 1) {
                            createQuote();
                          } else {
                            setSelectedSection(index + 2);
                          }
                        }}
                        // style={{
                        //   pointerEvents:
                        //     getQuoteDetails?.external_policy && uploadFileRFQ
                        //       ? ""
                        //       : !getQuoteDetails?.external_policy
                        //       ? ""
                        //       : "none",
                        // }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* basic questions */}
              {/* <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 1
                      ? ""
                      : selectedSection > currentStep?.length + 1
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Who will be our point of contact?
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="First name.."
                    value={getQuoteDetails?.first_name}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        first_name: event?.target?.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Last name..."
                    value={getQuoteDetails?.last_name}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        last_name: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => {
                      // setSelectedSection(currentStep?.length);
                      history.push("/home");
                    }}
                  >
                    Back
                  </div>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length + 2)}
                  >
                    Next
                  </div>
                </div>
              </div>

              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 2
                      ? ""
                      : selectedSection > currentStep?.length + 2
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Enter Your Email & Phone Number
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Email.."
                    value={getQuoteDetails?.lead_email}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        lead_email: event?.target?.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Phone number..."
                    value={getQuoteDetails?.phone_number}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        phone_number: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length - 2)}
                  >
                    Back
                  </div>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length + 3)}
                  >
                    Next
                  </div>
                </div>
              </div>
              <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 3
                      ? ""
                      : selectedSection > currentStep?.length + 3
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Tell Us A Little About Your Company
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Company name.."
                    value={getQuoteDetails?.company_name}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        company_name: event?.target?.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Number of employees..."
                    value={getQuoteDetails?.company_size}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        company_size: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length - 3)}
                  >
                    Back
                  </div>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length + 4)}
                  >
                    Next
                  </div>
                </div>
              </div> */}

              {/* <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 4
                      ? ""
                      : selectedSection > currentStep?.length + 4
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Enter your company’s pincode
                </div>

                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Enter Pincode"
                    value={getQuoteDetails?.pin_code}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        pin_code: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length - 4)}
                  >
                    Back
                  </div>
                  <div onClick={createQuote}>Next</div>
                </div>
              </div> */}

              {/* old commented code */}

              {/* <div
                className={classNames.transitionSection}
                style={{
                  transform:
                    selectedSection == currentStep?.length + 5
                      ? ""
                      : selectedSection > currentStep?.length + 5
                      ? "translateX(-150%)"
                      : `translateX(150%)`,
                }}
              >
                <div className={classNames.title}>
                  Were you referred by a FMA partner?
                </div>
                <div className={classNames.inputContainer}>
                  <input
                    type="text"
                    className={classNames.inputField}
                    placeholder="Enter partners email"
                    value={getQuoteDetails?.reference_email}
                    onChange={(event) => {
                      setGetQuoteDetails({
                        ...getQuoteDetails,
                        reference_email: event?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() => setSelectedSection(currentStep?.length - 5)}
                  >
                    Back
                  </div>
                  <div onClick={createQuote}>Next</div>
                </div>
              </div> */}
            </div>

            <div
              className={classNames.successMessage}
              style={{ display: selectedSection == 100 ? "" : "none" }}
            >
              <img src={yesIcon} alt="yesIcon" />
              <div className={classNames.title}>
                Thank You! Your Request Has <br />
                Been Submitted
              </div>
              <div className={classNames.desc}>Here Are Your Next Steps</div>
              <ol className={classNames.stepsList}>
                <li>
                  You will have received temporary login credentials to{" "}
                  {getQuoteDetails?.lead_email}
                </li>
                <li>
                  <span>
                    Please use those to login to our{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://talltree.coverfi.app/employer/login",
                          "_blank"
                        )
                      }
                    >
                      Employers App
                    </span>{" "}
                    and add the exact{" "}
                  </span>
                  <br />
                  <span>
                    coverage requirements you wish to obtain a quote for.{" "}
                  </span>
                </li>
              </ol>
            </div>
            <div
              className={classNames.loadingMessage}
              style={{ display: selectedSection == 99 ? "" : "none" }}
            >
              <img src={rahaFullLogo} alt="rahaFullLogo" />
              <div>Please wait while we process your request</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetQuoteEmployer;
